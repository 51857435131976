import { getSubState } from '../../../reducers/subReducerHelpers';

export const INCREMENT_PRODUCT = "INCREMENT_PRODUCT";
export const DECREMENT_PRODUCT = "DECREMENT_PRODUCT";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_NEW_PRODUCT_QUANTITIES = "SET_NEW_PRODUCT_QUANTITIES";
export const CLEAR_SELECTED_PRODUCTS = "CLEAR_SELECTED_PRODUCTS";
export const SET_PRODUCT_CONFIG = "SET_PRODUCT_CONFIG";

export function incrementProduct(id, subReducerKey = null) {
    return (dispatch, getState) => {
        let state = getState();
        let product = getSubState(state.products, subReducerKey)?.products?.[id];
        let quantity = state.productPicker.productsQuantities[id];

        if (product && quantity === +product.transaction_limit) {
            return;
        }

        dispatch({type: INCREMENT_PRODUCT, id, subReducerKey});
    }
}

export function setProductConfig(configuration, subReducerKey = null) {
    return {type: SET_PRODUCT_CONFIG, configuration, subReducerKey};
}

export function decrementProduct(id, subReducerKey = null) {
    return {type: DECREMENT_PRODUCT, id, subReducerKey};
}

export function setProduct(id, value, configId = null, subReducerKey = null) {
    return {type: SET_PRODUCT, id, value, configId, subReducerKey};
}

export function setProductQuantities(quantities, subReducerKey = null) {
    return {type: SET_NEW_PRODUCT_QUANTITIES, quantities, subReducerKey};
}

export function clearSelectedProducts(subReducerKey = null) {
    return {type: CLEAR_SELECTED_PRODUCTS, subReducerKey};
}
