/* @flow */
import type {FetchAction, SetPriceLevelsAction, UpdatePriceLevelsAction} from './event';
import { getReducerState, returnReducerState } from './subReducerHelpers.js';

type State = {+loading: boolean, +levels: Object}

export type Action = FetchAction | SetPriceLevelsAction | UpdatePriceLevelsAction;


const defaultState = { loading: true, levels: {} };
let initialState = defaultState;

export function priceLevel(state: State = initialState, action:Action): State {

    let newState = getReducerState(state, defaultState, action);

    if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {
        let priceLevels = {};

        let hasRecurringEvent = false;
        const {events} = action.data.attributes;
        for (const eventId in events) {
            if (events[eventId].event?.isRecurring){
                hasRecurringEvent = true;
            } else {
                let levels = events[eventId].price_levels;
                if (levels) {
                    for(let i = 0; i < levels.length; i++) {
                        priceLevels[levels[i].price_level_id] = levels[i];
                    }
                }
            }
        }

        newState.loading = false;
        //reload parent event listing page, should not overwrite newState.levels.
        if (!hasRecurringEvent){
            newState.levels = {...priceLevels};
        }
    } else if(action.type === "SET_PRICE_LEVELS"){
        let priceLevels = {};

        for(let i = 0; i < action.data.length; i++) {
            priceLevels[action.data[i].price_level_id] = action.data[i];
        }

        newState.loading = false;
        newState.levels = {...priceLevels};
    } else if(action.type === "UPDATE_PRICE_LEVELS"){
        let priceLevels = {...state.levels};
        for (let i = 0; i < action.data.length; i++) {
            priceLevels[action.data[i].price_level_id] = action.data[i];
        }

        newState.loading = false;
        newState.levels = {...priceLevels};
    } else {
        return state;
    }

    return returnReducerState(state, newState, action);
}
