/* @flow */
import type {FetchAction, SetProductsAction} from './event';
import { getReducerState, returnReducerState } from './subReducerHelpers.js';
type State = {+loading: boolean, products: Object};

type Action = FetchAction | SetProductsAction;

const defaultState = { loading: true, products: {}, configs: {} };
let initialState = defaultState;
export function products(state: State = initialState, action:Action): State {

    let newState = getReducerState(state, defaultState, action);

    if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {
        let products = {};
        let configs = {};
        for (const eventId in action.data.attributes.events) {
            let eventProducts = action.data.attributes.events[eventId].products;
            if (eventProducts && Array.isArray(eventProducts)) {
                for (let i = 0; i < eventProducts.length; i++) {
                    products[eventProducts[i].product_id] = eventProducts[i];

                    for(let j = 0; j < eventProducts[i].configurations.length; j++) {
                        configs[eventProducts[i].configurations[j].product_configuration_id] = eventProducts[i];
                    }
                }
            }
        }

        newState.loading = false;
        newState.products = {...products};
        newState.configs = {...configs};
    } else if(action.type === "SET_PRODUCTS") {
        let products = {};
        let configs = {}

        if (Array.isArray(action.data)) {
            for(let i = 0; i < action.data.length; i++) {
                products[action.data[i].product_id] = action.data[i];

                if (Array.isArray(action.data[i].configurations)) {
                    for(let j = 0; j < action.data[i].configurations.length; j++) {
                        configs[action.data[i].configurations[j].product_configuration_id] = action.data[i];
                    }
                }
            }
        }
        newState.loading = false;
        newState.products = {...products};
        newState.configs = {...configs};
    } else {
        return state;
    }

    return returnReducerState(state, newState, action);
}
