import React, { Component } from 'react';

export class InsetWrapper extends Component {
  getClass() {
    let cssClass = 'input input--inset';
    let setInsetClass = false;

    React.Children.forEach(this.props.children, function (child) {
      if (!child) return;

      if (child.type === 'div') {
        if (child.props.children.type === 'select' && child.props.children.props.className === 'input__control') {
          setInsetClass = !!child.props.children.props.value;
        }
      } else if (
        child.type === 'input' ||
        child.type.name === 'NumberFormat' ||
        child.type.name === 'InputBasicDefaultMetadata'
      ) {
        // InputBasicDefaultMetadata is for Phone
        // Phone uses <PhoneInput> but that renders
        // <InputBasicDefaultMetadata> as the first element
        if (child.props && child.props.className.indexOf('input__control') !== -1) {
          setInsetClass = child.props.value !== '';
        }
      }
    });

    if (setInsetClass) {
      cssClass += ' input--is-inset';
    }

    if (this.props.hasError) {
      cssClass += ' input--error';
    }

    return cssClass;
  }

  render() {
    return <div className={this.getClass()}>{this.props.children}</div>;
  }
}
