import React from 'react';
import { CurrencyContext } from './CurrencyContext';
/**
 * Kind of a hack, because sometimes class components need to know about currency config too
 */
const withCurrencyConfig = WrappedComponent => {
    return passedProps => {
        const currencyConfig = React.useContext(CurrencyContext);
        const props = {...passedProps};
        props.currencyConfig = currencyConfig;
        return <WrappedComponent {...props} />
    }
}
export default withCurrencyConfig;
