import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DayPicker from '../DayPicker';
import classNames from 'react-day-picker/build/classNames';
import { DateTimeFormatters } from '../../../../sc-intl';

const IntlDayPicker = (props) => {
  const { locale, translationLocale, syncTranslation, ...rest } = props;
  const { monthNames, weekdayNames, weekdayShortNames, weekStartIndex } = DateTimeFormatters;

  const dayPickerLocale = useMemo(
    () => (syncTranslation ? locale : translationLocale),
    [locale, translationLocale, syncTranslation],
  );

  return (
    <DayPicker
      locale={dayPickerLocale}
      months={monthNames(dayPickerLocale)}
      weekdaysLong={weekdayNames(dayPickerLocale)}
      weekdaysShort={weekdayShortNames(dayPickerLocale)}
      firstDayOfWeek={weekStartIndex(locale)}
      navbarElement={<Navbar />}
      {...rest}
    />
  );
};

function Navbar({ showPreviousButton, showNextButton, onPreviousClick, onNextClick }) {
  return (
    <div className="DayPicker__navigation">
      <button
        type="button"
        onClick={() => onPreviousClick()}
        className={`DayPicker-NavButton DayPicker-NavButton--prev button--clear ${
          showPreviousButton ? 'show-nav-button' : 'hide-nav-button'
        }`}
      >
        <i className="icon-navigate_before">
          <span className="sr-only">Previous Month</span>
        </i>
      </button>
      <button
        type="button"
        onClick={() => onNextClick()}
        className={`DayPicker-NavButton DayPicker-NavButton--next button--clear ${
          showNextButton ? 'show-nav-button' : 'hide-nav-button'
        }`}
      >
        <i className="icon-navigate_next">
          <span className="sr-only">Next Month</span>
        </i>
      </button>
    </div>
  );
}

IntlDayPicker.propTypes = {
  locale: PropTypes.string.isRequired,
  translationLocale: PropTypes.string,
  syncTranslation: PropTypes.bool,
  selectedDay: PropTypes.string,
  startingMonth: PropTypes.instanceOf(Date),
  endingMonth: PropTypes.instanceOf(Date),
  selectedDays: PropTypes.array.isRequired,
  disabledDays: PropTypes.array,
  onDayClick: PropTypes.func.isRequired,
  month: PropTypes.instanceOf(Date),
  onMonthChange: PropTypes.func.isRequired,
  classNames: PropTypes.object,
  modifiers: PropTypes.object,
  showOutsideDays: PropTypes.bool,
};

IntlDayPicker.defaultProps = {
  locale: 'en-US',
  translationLocale: 'en-US',
  syncTranslation: false,
};

IntlDayPicker.classNames = {
  ...classNames,
};

export default IntlDayPicker;
