import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITimeState {
    currentTime: Date;
}

export const initialState: ITimeState = {
    currentTime: new Date(),
};

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
      setCurrentTime: (state, action) => {
          let newState = {...state};
          newState.currentTime = new Date();

          return newState;
      },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => { return state; })
  },
});
