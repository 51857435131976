export function setListingItem(listing, key, value) {
    let items = getListingItems(listing);
    items[key] = value;
    setStorageItems(listing, items);
}

export function getListingItem(listing, key) {
    let items = getListingItems(listing);

    return items[key] === undefined ? null : items[key];
}

export function removeListingItem(listing, key) {
    let items = getListingItems(listing);

    delete items[key];
    setStorageItems(listing, items);
}

function getListingItems(listing) {
    if(!localStorage) {
        return {};
    }

    try {
        var items = JSON.parse(localStorage.getItem(listing));
        if(!items) {
            items = {};
        }
    } catch(e) {
        items = {};
    }

    return items;
}

function setStorageItems(listing, items) {
    if(!localStorage) {
        return;
    }

    if(listing == "" || listing.length == 0) {
        return;
    }
    try {
        localStorage.setItem(listing, JSON.stringify(items));
    } catch(e) {
        console.error("Unable to save localstore " + e.message);
    }
}
