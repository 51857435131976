import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from "../Icons";

const variant = {
  default: "badge--default",
  error: "badge--error",
  success: "badge--success",
  caution: "badge--caution",
  info: "badge--info",
  red: "badge--red",
  blue: "badge--blue",
  magenta: "badge--magenta",
  turquoise: "badge--turquoise",
}
const size = {
  small: "badge--small",
  large: "badge--large",
}
class Badge extends Component {
    render() {
        return (
          <div className={`badge ${this.props.variant} ${this.props.size} ${this.props.className}`}>
              <div className="badge__title">
                {this.props.children}
              </div>
              {this.props.dismissable ?
                <button className="badge__dismiss" onClick={this.props.onClick}>
                  <CloseIcon />
                  <span className="sr-only">Close</span>
                </button>
              : ""
              }
          </div>
        );
    }
}

Badge.variant = variant;
Badge.size = size;

Badge.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  dismissable: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(variant)),
  size: PropTypes.oneOf(Object.values(size)),
}

Badge.defaultProps = {
  variant: Badge.variant.default,
  size: Badge.size.small,
  dismissable: false,
}

export default Badge;
