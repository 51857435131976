import { createSelector } from 'reselect';
import { products } from "../../../reducers/products";
import { getSubState } from '../../../reducers/subReducerHelpers';
import { UPSELL_TYPE_PRODUCT, genSubReducerKey } from '../../../views/upsells/reducers/upsells';

const getProductSales       = state => state.productSales.products;
const getProducts           = state => state.products.products;
const getQuantities         = state => state.productPicker.configurationsQuantityArrays;
const getProductPickerState = state => state.productPicker;
const getSelectedValues     = state => Object.values(state.productPicker.productsQuantities);
const getSelectedProducts   = state => state.productPicker.productsQuantities;
const getConfigQuantities   = state => state.productPicker.configQuantities;
const getUpsellState        = state => state.upsells;

const formatProductSaleData = (productSale, quantities) => {
    return {
        configuration_id: productSale.configuration_id,
        configurationName: productSale.display_name,
        productName: productSale.product_name,
        price: productSale.cost.amount,
        total_price: productSale.cost.amount,
        fee: productSale.buyer_fee.amount,
        total_fee: productSale.buyer_fee.amount,
        quantity: 1,
        subtotal: productSale.cost.amount + productSale.buyer_fee.amount,
        currency: productSale.cost.currency,
        currency_symbol: productSale.cost.symbol,
        event_id: productSale.event_id,
        quantities: quantities[productSale.configuration_id] ?? [],
        description: productSale.description,
        dependentProduct: productSale.dependentProduct
    };
}

/**
 * Group non upsell product sales by event ID and config ID
 */
export const getProductSalesByEvent = createSelector(
    [getProductSales, getQuantities],
    (productSales, quantities) => {
        let selectedProducts = {};
        for(var i = 0; i < productSales.length; i++) {
            if (!!productSales[i].listing_upsell_id) {
                continue;
            }
            if (!selectedProducts[productSales[i].event_id]) {
                selectedProducts[productSales[i].event_id] = [formatProductSaleData(productSales[i], quantities)];
            }  else {
                let found = false;
                for(var j = 0; j < selectedProducts[productSales[i].event_id].length; j++) {
                    if(selectedProducts[productSales[i].event_id][j].configuration_id === productSales[i].configuration_id) {
                        selectedProducts[productSales[i].event_id][j].total_price += productSales[i].cost.amount;
                        selectedProducts[productSales[i].event_id][j].total_fee += productSales[i].buyer_fee.amount;
                        selectedProducts[productSales[i].event_id][j].subtotal += (productSales[i].cost.amount + productSales[i].buyer_fee.amount);
                        selectedProducts[productSales[i].event_id][j].quantity++;
                        found = true;
                        break;
                    }
                }

                if(!found) {
                    selectedProducts[productSales[i].event_id].push(formatProductSaleData(productSales[i], quantities));
                }
            }
        }

        return selectedProducts;
    }
);

/**
 * Group upsell product sales by listing upsell ID and config ID
 */
export const getUpsoldProductsByListingUpsellId = createSelector(
    [getProductSales, getProductPickerState, getUpsellState],
    (productSales, productPickerState, upsells) => {
        let selectedProducts = {};
        for(var i = 0; i < productSales.length; i++) {
            if (!productSales[i].listing_upsell_id) {
                continue;
            }
            if (!selectedProducts[productSales[i].listing_upsell_id]) {
                const quantityArrays = getQuantityArraysForUpsoldProduct(productPickerState, upsells, productSales[i])
                selectedProducts[productSales[i].listing_upsell_id] = [formatProductSaleData(productSales[i], quantityArrays)];
            }  else {
                let found = false;
                for(var j = 0; j < selectedProducts[productSales[i].listing_upsell_id].length; j++) {
                    if(selectedProducts[productSales[i].listing_upsell_id][j].configuration_id === productSales[i].configuration_id) {
                        selectedProducts[productSales[i].listing_upsell_id][j].total_price += productSales[i].cost.amount;
                        selectedProducts[productSales[i].listing_upsell_id][j].total_fee += productSales[i].buyer_fee.amount;
                        selectedProducts[productSales[i].listing_upsell_id][j].subtotal += (productSales[i].cost.amount + productSales[i].buyer_fee.amount);
                        selectedProducts[productSales[i].listing_upsell_id][j].quantity++;
                        found = true;
                        break;
                    }
                }

                if(!found) {
                    const quantityArrays = getQuantityArraysForUpsoldProduct(productPickerState, upsells, productSales[i])
                    selectedProducts[productSales[i].listing_upsell_id].push(formatProductSaleData(productSales[i], quantityArrays));
                }
            }
        }

        return selectedProducts;
    }
);

/**
 * Find the quantity array corresponding to the provided product sale
 * in the loaded upsell data
 * @param  object productPickerState
 * @param  object upsellState
 * @param  object productSale
 * @return object
 */
const getQuantityArraysForUpsoldProduct = (productPickerState, upsellState, productSale) => {
    let quantityArrays = {};
    if (Array.isArray(upsellState.upsells)) {
        upsellState.upsells.forEach(upsell => {
            if (+upsell?.upsell?.type === UPSELL_TYPE_PRODUCT && +upsell?.product_id === +productSale?.product_id) {
                const itemId = upsell?.upsell?.item_id;
                if (itemId) {
                    const reducerKey = genSubReducerKey(itemId, UPSELL_TYPE_PRODUCT);
                    const state = getSubState(productPickerState, reducerKey);
                    quantityArrays = !!state?.configurationsQuantityArrays ? state.configurationsQuantityArrays : {};
                }
            }
        })
    }
    return quantityArrays;
}

export const getConfigurationQuantities = createSelector(
    [getProductSales],
    (productSales) => {
        return getConfigurationQuantitiesFromProductSales(productSales);
    }
);

export const getConfigurationQuantitiesFromProductSales = (productSales, listingUpsellId = null) => {
    let products = {};
    for(let i = 0; i < productSales.length; i++) {
        if ((!listingUpsellId && !productSales[i]?.listing_upsell_id) || (+productSales[i]?.listing_upsell_id === +listingUpsellId)) {
            if (!products[productSales[i].configuration_id]) {
                products[productSales[i].configuration_id] = 1;
            } else {
                products[productSales[i].configuration_id]++;
            }
        }
    }
    return products;
}

export const getTotalSelectedProducts = createSelector(
    [getSelectedValues],
    (products) => {
        return getTotalSelectedProductsFromState(products);
    }
);

export const getTotalSelectedProductsFromState = providedProducts => {
    return providedProducts.reduce((total, val) => {
        return total + (val === undefined ? 0 : val);
    }, 0);
}

export const cartHasDependentProducts = createSelector(
    [getProductSales],
    (productSales) => {
        let countDependentProducts = 0;
        for(let i = 0; i < productSales.length; i++) {
            if (productSales[i].dependentProduct) {
                countDependentProducts++;
            }
        }

        return (countDependentProducts > 0);
    }
);

export const isDependentProductSelected = createSelector(
    [getProducts, getSelectedProducts],
    (products, selectedProducts) => {
        let dependentIds = [];
        Object.values(products).forEach((product, i) => {
            if (!product.purchasable_without_tickets) {
                dependentIds.push(product.product_id);
            }
        });

        let countDependentProducts = 0;
        Object.keys(selectedProducts).forEach(selectedId => {
            if (selectedProducts[selectedId] !== undefined && dependentIds.indexOf(selectedId) !== -1) {
                countDependentProducts++;
            }
        });

        return countDependentProducts > 0;
    }
);

export const getProductsWithErrors = createSelector(
    [getSelectedProducts, getConfigQuantities, getProducts],
    (selectedProducts, configurationQuantities, products) => {
        let  errors = {};
        for (const productId in selectedProducts) {
            if (selectedProducts[productId] !== undefined &&
                (configurationQuantities[productId] === undefined ||
                    selectedProducts[productId] !== Object.keys(configurationQuantities[productId]).length)) {
                if (!errors[productId]) {
                    errors[productId] = [{
                        productId: products[productId].product_id,
                        productName: products[productId].name,
                        optionName: products[productId].configurations[0].optionName,
                    }];
                }
            }
        }

        return errors;
    }
);
