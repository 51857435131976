import { createSelector } from 'reselect';
import { pick } from "lodash";

const getQuantities = state => state.ticketPicker.quantities || {};
const getLevelsObject = state => state.priceLevel.levels || {};

const getRegularPriceLevels = createSelector(
  [getLevelsObject],
  (levelsObject) => Object.values(levelsObject || {}).filter(level => !level.timeslot_buyout_settings)
);

const getTimeslotBuyoutLevels = createSelector(
  [getLevelsObject],
  (levelsObject) => Object.values(levelsObject || {}).filter(level => !!level.timeslot_buyout_settings)
);

export const regularTicketsSelected = createSelector(
  [getRegularPriceLevels, getQuantities, state => state],
  (regularPriceLevels, quantities, state) => {
    const regularPriceLevelIds = regularPriceLevels.map(pl => pl.price_level_id)
    const regularQuantities = pick(quantities, regularPriceLevelIds)
    const regularQuantitiesSum = Object
      .values(regularQuantities)
      .reduce(
        (sum, ticketsByPriceLevel) => sum + (ticketsByPriceLevel || 0),
        0
      )

    return regularQuantitiesSum > 0;
  }
);

export const getSelectedTimeslotBuyout = createSelector(
  [getTimeslotBuyoutLevels, getQuantities],
  (timeslotBuyoutLevels, quantities) => {
    return timeslotBuyoutLevels.find(pl => quantities[pl.price_level_id]);
  }
);
