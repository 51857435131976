import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translation from "../../../../shared/Translation";
import {getXssFilter} from '../../../helpers/helpers';

class EmailOptOut extends Component {

    render() { 

        const customLabel = this.props.uiConfig.opt_out_text;

        const defaultLabel = "checkout__email_opt_in";

      const labelSpan = customLabel && customLabel !== <span></span> 
      ? <span dangerouslySetInnerHTML={{__html: getXssFilter().process(customLabel)}}></span>
      : <Translation>{defaultLabel}</Translation>;

        return(
            <section className="checkout-form__section">
                <div className="input">
                    <div className={`input__checkbox tos__checkbox ${!this.props.emailOptOut ? 'input__checkbox--selected' : ''}`}>
                        <div className="input__checkbox-wrap" >
                            <div className="input__checkbox__input">
                                <input type="checkbox" name="email_opt_out" id="email_opt_out" onChange={this.props.onChangeOptOut} checked={!this.props.emailOptOut } />
                            </div>
                            <label htmlFor="email_opt_out" className="input__checkbox__label">
                                {labelSpan}
                            </label>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

EmailOptOut.defaultProps = {
}

EmailOptOut.propTypes = {
    uiConfig: PropTypes.object, 
    onChangeOptOut: PropTypes.func,
    emailOptOut: PropTypes.bool,
};

export default EmailOptOut;
