import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import Help from '../components/Help';
import { setHelpOpen } from '../actions/help';
import { sendSlackMessage } from 'listing/actions/general';
import { getKioskOptions } from 'checkout/selectors/ui';

class HelpContainer extends Component {
    render() {
        return(
            <Help
                onHelpChange={this.onHelpChange}
                onTicketPress={this.onTicketPress}
                onOtherPress={this.onOtherPress}
                helpOpen={this.props.helpModal}
                printReceiptsAndTickets={this.props.kioskOptions.printReceiptsAndTickets}
            />
        )
    };

    onHelpChange = (val) => {
        this.props.setHelpOpen(val);
    };

    onTicketPress = () => {
        this.props.sendSlackMessage("@here Tickets didn't print", "error");
    };

    onOtherPress = () => {
        this.props.sendSlackMessage("@here Needs unspecified help", "error");
    };
}

const mapStateToProps = (state, props) => {
    return {
        helpModal: state.ui.helpModal,
        apiUrl: state.ui.apiUrl,
        purchaseUser: state.reservation.purchaseUser,
        kioskOptions: getKioskOptions(state),
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setHelpOpen,
        sendSlackMessage,
    }, dispatch);
};

HelpContainer.propTypes = {
};

HelpContainer.defaultProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpContainer);
