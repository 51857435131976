import { createContext, useContext } from "react";

export const CheckoutProcessContext = createContext({});

export const useCheckoutProcessContext = () => {
  const ctx = useContext(CheckoutProcessContext);

  if (!ctx) {
    throw new Error('Checkout Process context provider not found!')
  }
  
  return ctx;
}
