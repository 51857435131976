import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMainEvent } from '../../../selectors/event';

class KioskHeaderContainer extends Component {
    getHeaderImageUrl = () => {
        return "url(" + this.props.headerImage + ")";
    };

    render() {
        const style = this.props.headerImage ? {backgroundImage: this.getHeaderImageUrl()} : null;

        return(
            <div className="kiosk__header" style={style}></div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    	headerImage: state.ui.uiConfig.images.checkoutHeader,
    	eventName: getMainEvent(state)?.event?.name ?? '',
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

KioskHeaderContainer.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(KioskHeaderContainer)
