import React from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';

class Spinner extends React.Component {

    render() {
        return (
            <SpinnerContainer className="spinner-container">
              <SpinnerCircle className="spinner" />
            </SpinnerContainer>
        );
    }
}

Spinner.propTypes = {
    // onClick: PropTypes.func.isRequired
};

export default Spinner;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinnerContainer = styled.div`
  animation: ${rotate} 2s linear infinite;
  width: 82px;
`
const SpinnerCircle = styled.div`
  height: 82px;
  width 82px;
  background: transparent;
  border-radius: 50%;
  border-top: 5px solid #535253;
  border-left: 5px solid #535253;
  border-bottom: 5px solid #B4B4B4;
  border-right: 5px solid #B4B4B4;
`