import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PinEntry from './PinEntry';
import KioskManagement from '../components/KioskManagement';
import Button from 'shared/Button';
import styled from 'styled-components';
import FocusTrap from '@s/FocusTrap';
import Translation from '@s/Translation';
import { getInitialFocusSelector } from '../../../../checkout/helpers/helpers';


class ManagementPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticated: false,
        }
    }

    render() {
        return(
            <KioskManagementOverlay className="kiosk__management-overlay">
                <FocusTrap
                    fallback = {'.close-button'}
                    focusTrapOptions={{
                        initialFocus: getInitialFocusSelector('.input--pinpad-number, .input--dropdown'),
                    }}
                >
                    <KioskManagementPane className="kiosk__management-pane">
                        <PaneHeader className="kiosk__management-pane--header">
                            <h1><Translation>{'checkout__kiosk_setup'}</Translation></h1>
                            <CloseButton className="close-button" onClick={this.closePane}><i className="icon-close" aria-hidden="true"></i></CloseButton>
                        </PaneHeader>

                        {this.state.authenticated === false &&
                            <PinEntry
                                onAuthenticated={this.setAuthenticated}
                                listingSlug={this.props.listingSlug}
                            />
                        }
                        {this.state.authenticated === true &&
                            <KioskManagement
                                onClose={this.closePane}
                                sellerId={this.props.sellerId}
                                purchaseUser={this.props.purchaseUser}
                                terminalId={this.props.terminalId}
                                apiUrl={this.props.apiUrl}
                                listingSlug={this.props.listingSlug}
                                onUserSelect={this.props.onUserSelect}
                                adminUrl={this.props.adminUrl}
                                onTerminalSelect={this.props.onTerminalSelect}
                                onLoadTerminals={this.props.onLoadTerminals}
                                terminals={this.props.terminals}
                                kioskOptions={this.props.kioskOptions}
                                cmsMode={this.props.cmsMode}
                                onLogout={this.props.onLogout}
                                apiToken={this.props.apiToken}
                            />
                        }
                    </KioskManagementPane>
                </FocusTrap>
            </KioskManagementOverlay>
        )
    }

    closePane = () => {
        this.setState({authenticated: false});
        this.props.onClose();
    }

    setAuthenticated = (val) => {
        if(val === true) {
            this.props.onAuthenticated();
        }

        this.setState({authenticated: val});
    }
}

ManagementPane.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    listingSlug: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onUserSelect: PropTypes.func.isRequired,
    onTerminalSelect: PropTypes.func.isRequired,
    onLoadTerminals: PropTypes.func,
    sellerId: PropTypes.any,
    onAuthenticated: PropTypes.func.isRequired,
    purchaseUser: PropTypes.any,
    terminalId: PropTypes.string,
}

ManagementPane.defaultProps = {
    purchaseUser: 0,
    terminalId: "",
}

export default ManagementPane;

const KioskManagementOverlay = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 700;
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
`
const KioskManagementPane = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 701;
    background-color: white;
    height: 100%;
    width: 500px;
    padding: 20px 0;
    font-family: 'Open Sans', sans-serif;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    overflow: auto;
`

const PaneHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 30px;
    h1{
        font-family: "Open Sans", sans-serif;
    }
`
const CloseButton = styled(Button)`
    background: transparent;
`

const ManagementPaneFooter = styled.div`
    padding: 20px 30px 0;
    border-top: 1px solid #eeeeee;
`
