// @flow
import type { ClearAction } from './reservation';

export type TicketSetAction = { type: 'SET_TICKETS', tickets: Array<Object> };

export type Action = TicketSetAction | ClearAction;

import { formatInventoryItemFromServer } from '../helpers/inventoryItems';

type State = { +tickets: Array<Object> };

let initialState = {
  tickets: [], // tickets not associated with bundle sales
  bundleTickets: [], // tickets on bundle sales
  namesOnTickets: {},
};

/** Reducer for reservation actions */
export function tickets(state: State = initialState, action: Action): State {
  if (action.type === 'SET_TICKETS') {
    let newState = { ...state };
    newState.tickets = [];
    for (let i = 0; i < action.tickets.length; i++) {
      newState.tickets.push(formatInventoryItemFromServer(action.tickets[i]));
    }
    return newState;
  } else if (action.type === 'SET_RAW_TICKETS_SALES_STATE') {
    return action.state;
  } else if (action.type === 'SET_BUNDLE_SALE_TICKETS') {
    let newState = { ...state };
    newState.bundleTickets = [];
    if (Array.isArray(action.tickets)) {
      for (let i = 0; i < action.tickets.length; i++) {
        if (action.tickets[i]?.id) {
          newState.bundleTickets.push(formatInventoryItemFromServer(action.tickets[i]));
        }
      }
    }
    return newState;
  } else if (action.type === 'SET_TICKET_NAME') {
    return {
      ...state,
      namesOnTickets: {
        ...state.namesOnTickets,
        [action.id]: action.value,
      },
    };
  } else if (action.type === 'RESERVATION_CLEARED') {
    return initialState;
  } else {
    return state;
  }
}
