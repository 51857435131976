import React, { Component } from 'react';
import { connect } from "react-redux";
import { getIsNameFieldDisabled } from '../../../selectors/reservation';
import Address from '@showclix-shared/CheckoutProcess/components/Address';

class ConnectedAddress extends Component<Props> {
    render() {
        const props = {...this.props};

        const { region: browserBasedCountry } = new Intl.Locale(navigator.language);

        if (!props.countryCode && !!this.props.defaultCountryCode) {
            props.countryCode = this.props.defaultCountryCode;
        }
        if (!props.country) {
            // In some cases, reservation.country = "", so let's try to take customer's location based on browser locale.
            props.country = this.props.defaultCountry ? this.props.defaultCountry : browserBasedCountry
        }

        return <Address {...props} />
    }
}

const mapStateToProps = state => {
    return {
        defaultCountryCode: state.reservation.country_phone_code,
        defaultCountry: state.reservation.country,
        nameDisabled: getIsNameFieldDisabled(state),
        phoneRequired: state.weather?.quote?.selected ?? false
    }
}

export default connect(mapStateToProps)(ConnectedAddress);
