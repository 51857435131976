import React from 'react';
import { MessagePageBanner, MessagePageBannerVariant } from './MessagePageBanner';
import Translation from '../../shared/Translation';

interface ICustomerCareHeaderProps {
    adminPageUrl?: string;
    eventWiki?: string;
    sellerWiki?: string;
    phoneFee?: string;
    repName?: string;
}

export const CustomerCareHeader = ({
    adminPageUrl,
    eventWiki,
    phoneFee,
    repName,
    sellerWiki,
}: ICustomerCareHeaderProps) => (
    <div id="customer-care-wrapper" style={repName ? {} : {display:"none"}}>
        <MessagePageBanner
            variant={MessagePageBannerVariant.info}
            dismissable={true}
        >
        <span>
            <Translation>{'checkout__order_being_processed_as_showclix_representative'}</Translation> <span id="customer-care-name">({repName})</span>.
        </span>
        </MessagePageBanner>
        {
            (adminPageUrl || eventWiki || sellerWiki) &&
                <MessagePageBanner
                    variant={MessagePageBannerVariant.caution}
                    dismissable={true}
                >
                    {
                        sellerWiki && sellerWiki.length > 0 && <a href={sellerWiki} className="cc__link">
                            <Translation>{'checkout__view_the_seller_wiki'}</Translation>
                        </a>
                    }
                    {
                        eventWiki && eventWiki.length > 0 && <a href={eventWiki} className="cc__link">
                            <Translation>{'checkout__view_the_event_wiki'}</Translation>
                        </a>
                    }
                    {
                        adminPageUrl && adminPageUrl.length > 0 && <a href={adminPageUrl} className="cc__link">
                            <Translation>{'checkout__view_event_in_admin'}</Translation>
                        </a>
                    }
                    {!repName && <a href="" onClick={clearCCInfo} className="cc__link">
                            <Translation>{'checkout__clear_user'}</Translation>
                        </a>
                    }
                </MessagePageBanner>
        }
        {
            phoneFee && phoneFee.length > 0 &&
            <MessagePageBanner
                variant={MessagePageBannerVariant.success}
                dismissable={true}
            >
                <Translation interpolation={{phoneFee}}>{'checkout__a_per_ticket_phone_fee_applies_to_order'}</Translation>
            </MessagePageBanner>
        }
    </div>
);

function clearCCInfo(e) {
    e.preventDefault();
    e.stopPropagation();
    // global function from template
    // @ts-ignore
    if(typeof clearCustomerCareInfo == "function") {
        // @ts-ignore
        clearCustomerCareInfo();
    }
}
