import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import { setEmailOptOut } from '../../member/actions/member';
import EmailOptOut from '../components/EmailOptOut';
import { inLastStep } from '../../../selectors/ui';

class EmailOptOutContainer extends Component {
    render() {

        if (!this.props.isInLastStep || !this.props.uiConfig.show_opt_out) {
            return null;
        }

        return <EmailOptOut
            uiConfig={this.props.uiConfig}
            onChangeOptOut={this.changeOptOut}
            emailOptOut={this.props.emailOptOut}
        />
    }

    changeOptOut = () => {
        this.props.setEmailOptOut(!this.props.emailOptOut);
    }
}

const mapStateToProps = state => {
    return {
        uiConfig: state.ui.uiConfig,
        emailOptOut: state.member.emailOptOut,
        isInLastStep: inLastStep(state),
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setEmailOptOut
    }, dispatch);
}

EmailOptOutContainer.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailOptOutContainer);
