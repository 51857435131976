import { sendSlackMessage } from "listing/actions/general";

export function setHelpOpen(val) {
    return (dispatch, getState) => {
        let state = getState();
        if(val === false && state.ui.helpModal === true) {
            dispatch(sendSlackMessage("Help panel closed", "ok"));
        }
        dispatch({type: "SET_HELP_OPEN", value: val});
    }
}
