import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import { triggerTransition } from '@uirouter/redux';

import StepAction from '../components/StepAction';
import { goToPrevStep, setCheckoutOverlay } from '../../../actions/ui';
import { getOrderTotal } from '../../../selectors/cart';

class StepActionContainer extends Component {
    render() {
        return(
            <StepAction
                firstStep={this.props.currentStep === 0}
                lastStep={this.props.currentStep === this.props.steps.length - 1}
                onBack={this.goBack}
                orderTotal={this.props.orderTotal}
                terminalId={this.props.terminalId}
                disabled={!!this.props.disabled}
            />
        )
    }

    goBack = () => {
        if (this.props.currentStep === 0) {
          this.props.triggerTransition("ticketPicker");
        } else {
          this.props.goToPrevStep();
        }
    }
}

const mapStateToProps = state => {
    return {
        terminalId: state.payment.terminalId,
        orderTotal: getOrderTotal(state).amount || 0,
        currentStep: state.ui.currentStep,
        steps: state.ui.listingConfig.checkoutSteps,
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setCheckoutOverlay,
        triggerTransition,
        goToPrevStep
    }, dispatch);
}

StepActionContainer.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(StepActionContainer)
