/**
 * Get the top window object.
 * Useful if the process is running in an iframe like for embed.
 */
export const getTopWindow = () => {
    let result = window;
    try {
        if (window.self !== window.top) {
            result = window.top;
        }
    } catch (err) {
        console.log(err);
    }
    return result;
}
