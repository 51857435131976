import { createSelector } from 'reselect';

const getQuantities = state => state.bundlePicker.bundleQuantities || {};
const getBundlesObject = state => state.bundles.bundles || {};

const getBundles = createSelector(
  [getBundlesObject],
  (bundlesObject) => Object.values(bundlesObject || {})
);

export const bundlesSelected = createSelector(
  [getQuantities],
  (bundleQuantities) => {
    const bundleQuantitiesSum = Object
      .values(bundleQuantities)
      .reduce(
        (sum, bundlesCount) => sum + (bundlesCount || 0),
        0
      )

    return bundleQuantitiesSum > 0;
  }
);
