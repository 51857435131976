import {getCheckoutClient} from "../../../config/CheckoutClient";
import { addServerErrors } from "../../../actions/ui";
import { getDeliveryPayload } from "checkout/selectors/request";
import { isReservationFree } from "checkout/selectors/reservation";
import { getDefaultAddress } from '../../../selectors/member';
import { getSelectedDeliveryOption } from "../../../selectors/delivery";

export function setDeliveryOptions(options) {
    return {type: "DELIVERY_OPTIONS_SET", options};
}

export function setDeliveryPhone(number) {
    return {type:"DELIVERY_PHONE_SET", number};
}

export function setDeliveryCountryCode(countryCode) {
    return {type:"DELIVERY_COUNTRY_CODE_SET", countryCode};
}

export function setDeliveryEmail(email) {
    return {type:"DELIVERY_EMAIL_SET", email};
}

export function setDeliveryAddress(address) {
    return {type:"DELIVERY_ADDRESS_SET", address};
}

export function fetchDeliveryOptions(token, secret) {
    return (dispatch, getState) => {
        let state = getState();
        return getCheckoutClient(state.ui.apiUrl).reservationGetRequest(token, 'delivery_options', secret).then((result) => {
            const state = getState();
            // if the reservation is free and we're not charging the delivery fee then 0 out all costs
            if(isReservationFree(state) && !state.settings.chargeDeliveryFeeOnFree) {
                for(let i = 0; i < result.body.data.length; i++) {
                    result.body.data[i].attributes.price.amount = 0;
                }
            }
            dispatch(setDeliveryOptions(result.body.data));
            if (state.delivery.id === null) {
              dispatch(setDeliveryCountryCode(state.reservation.country_phone_code))
            }
            return Promise.resolve();
        });
    }
}

export function submitDelivery(id = null) {
    return (dispatch, getState) => {
        let state = getState();

        let payload = getDeliveryPayload(state);

        if(id) {
            payload.id = id;
        }

        return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(state.reservation.token, 'delivery_options', payload, state.reservation.secret).then(
            (result) => {
            },
            (error) => {
                dispatch(addServerErrors(error.response.body.errors));
                return Promise.reject("");
            }
        );
    }
}

export const validateAndSaveDeliveryAddress = (t, onInvalid = null, onError = null) => {
    return async (dispatch, getState) => {
        const state = getState();
        const selectedDeliveryOption = getSelectedDeliveryOption(state);
        const deliveryAddress = state.delivery.address !== false ? state.delivery.address : getDefaultAddress(state);

        if (selectedDeliveryOption.method_name === 'Mail') {
            const validation = ['name', 'address', 'city', 'state', 'country', 'zip']
                .some(field => !deliveryAddress[field] || deliveryAddress[field] === '');

            if (validation) {
                if (typeof onInvalid === 'function') {
                    onInvalid();
                }
                return false;
            }

            try {
                await dispatch(submitDelivery());
                return true;
            } catch (e) {
                if (typeof onError === 'function') {
                    onError();
                }
                return false;
            }
        }
        return true;
    }
}
