import React from 'react';
import PropTypes from 'prop-types';
import FormattedCurrency from 'shared/FormattedCurrency';
import Button from 'shared/Button';
import styled from 'styled-components';
import { printToSidekick } from '../../../../checkout/actions/sidekick';
import { useDispatch } from 'react-redux';
import { TranslationContext } from '../../../../checkout/config/Translations';

const RecentOrder = props => {
    const dispatch = useDispatch();
    const translate  = React.useContext(TranslationContext).t;

    const renderTickets = () => {
        return Object.values(props.tickets).map((ticket, i) => {
            return <div key={i}>
                <p>{ticket.quantity} x {ticket.title} - <FormattedCurrency>{ticket.amount}</FormattedCurrency></p>
            </div>
        });
    }

    const reprintTickets = () => {
        dispatch(printToSidekick(props.id, props.sidekick));
    }

    //If not configured to print receipts or tickets, do not show reprint button.
    //If configured only to print receipts, change button text from "Reprint Tickets" to "Reprint Receipt"
    let hideReprintButton = false;
    let reprintButtonText = translate('checkout__reprint_tickets');
    if (props.kioskOptions.printReceipts !== undefined && props.kioskOptions.printReceiptsAndTickets !== undefined) {
        if (!props.kioskOptions.printReceipts && !props.kioskOptions.printReceiptsAndTickets) {
            hideReprintButton = true;
        } else {
            if (props.kioskOptions.printReceipts) {
                reprintButtonText = translate('checkout__reprint_receipt');;
            }
        }
    }

    return(
        <RecentOrdersInfo className="recent-order__info">
            <p>{translate('checkout__kiosk_recent_order_completed_time', {timeString: props.reserved})}</p>
            <p>{translate('checkout__kiosk_recent_order_confirmation', {id: props.confirmation})}</p>
            {renderTickets()}
            {!hideReprintButton && <Button className="button button--block button--full" onClick={reprintTickets}>{reprintButtonText}</Button>}
        </RecentOrdersInfo>
    )
}

RecentOrder.propTypes = {
    reserved: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    tickets: PropTypes.object.isRequired,
    sidekick: PropTypes.any,
}

RecentOrder.defaultProps = {
}

export default RecentOrder;


const RecentOrdersInfo = styled.div`
    // margin-bottom: 30px;
    display: block;
    position: relative;
    :after {
    content: "";
    display: table;
    clear: both;
    }
    div{
        margin-bottom: 12px;
    }
`
