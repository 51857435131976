import React, { Component, Fragment } from 'react';
import Page from 'shared/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { getListingSettings } from 'checkout/selectors/ui';
import TicketsButtonContainer from 'checkout/containers/TicketsButtonContainer';
import ListingBanner from '../components/ListingBanner';
import ListingDetails from '../components/ListingDetails';
import Footer from '../components/Footer';
import ContinueCheckoutBannerContainer from './ContinueCheckoutBannerContainer';
import ContactInfo from '../components/ContactInfo';
import ListingDatePicker from '../../checkout/views/series/containers/ListingDatePicker';
import KioskListing from 'listing/components/KioskListing';
import KioskManagement from 'listing/views/kiosk-management/containers/KioskManagement';
import HelpContainer from 'listing/views/help/containers/HelpContainer';
import KioskMonitoring from 'listing/views/kiosk-management/containers/KioskMonitoring';
import KioskTimeContainer from '../../checkout/views/kiosk/containers/KioskTimeContainer';
import TimeClock from '../../checkout/views/time/components/TimeClock';
import NextAvailableTime from '../../checkout/views/series/containers/NextAvailableTime';
import SoldOutDayDisplay from '../../checkout/views/series/containers/SoldOutDayDisplay';
import RefreshListingData from '../../checkout/components/RefreshListingData';
import TicketsGoOnSale from '../components/TicketsGoOnSale';
import TimeoutPromptContainer from '../../checkout/views/timeout-prompt/containers/TimeoutPromptContainer';

/**
 * Extends the Page component, which holds the code for rendering out
 * the components
 */

class Listing extends Page {

    map = {
        ticketsButton:          TicketsButtonContainer,
        continueCheckoutBanner: ContinueCheckoutBannerContainer,
        listingBanner:          ListingBanner,
        listingDetails:         ListingDetails,
        contactInfo:            ContactInfo,
        footer:                 Footer,
        listingDatePicker:      ListingDatePicker,
        KioskListing:           KioskListing,
        KioskManagement:        KioskManagement,
        HelpContainer:          HelpContainer,
        KioskMonitoring:        KioskMonitoring,
        KioskTimeContainer:     KioskTimeContainer,
        TimeClock:              TimeClock,
        NextAvailableTime:      NextAvailableTime,
        SoldOutDayDisplay:      SoldOutDayDisplay,
        RefreshListingData:     RefreshListingData,
        TicketsGoOnSale:        TicketsGoOnSale,
        TimeoutPromptContainer: TimeoutPromptContainer,
    };

    componentDidMount() {
       window.scrollTo(0,0);
    }

    render() {
        
        return(
            <Fragment>
                <div className="page" id="page">
                    {!this.props.loading ?
                        this.getComponents()
                        : 
                        <div className="listing--loading-state">
                            <i className="icon-spinner loading__spinner" aria-hidden="true" />
                        </div>
                    }
                </div>
                <div className="overlay"></div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        settings: getListingSettings(state),
        loading: state.event.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

Listing.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing)
