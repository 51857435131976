import { buildPriceLevelQuantityArray } from '../../ticket-picker/reducers/ticket-picker';
import { buildBundleQuantityArray } from '../../bundle-picker/reducers/bundle-picker';

let initialState = {
    priceLevelQuantities: {},
    priceLevelQuantityArrays: {},
    bundleQuantities: {},
    bundleQuantityArrays: {},
    filterResults: null,
};

export function inventoryFilter(state: State = initialState, action:Action): State {
    if (action.type === "RESERVATION_CLEARED") {
        return {
            ...initialState,
            priceLevelQuantityArrays: state.priceLevelQuantityArrays,
            bundleQuantityArrays: state.bundleQuantityArrays,
        };
    } else if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {
        let newState = {...state};

        for (const eventId in action.data.attributes.events) {
            let levels = action.data.attributes.events[eventId].price_levels;
            let eventBundles = action.data.attributes.events[eventId].bundles;
            if (levels) {
                for(let i = 0; i < levels.length; i++) {
                    let level = levels[i];
                    newState.priceLevelQuantityArrays[level.price_level_id] = buildPriceLevelQuantityArray(level.minimum_purchase_limit, level.transaction_limit, level.increment_by);
                }
            }
            if (eventBundles) {
                for(let i = 0; i < eventBundles.length; i++) {
                    newState.bundleQuantityArrays[eventBundles[i].id] = buildBundleQuantityArray(eventBundles[i]);
                }
            }
        }
        return newState;
    } else if(action.type === "SET_PRICE_LEVEL_FILTER_QUANTITY") {
        let newState = {...state};
        newState.priceLevelQuantities = {...newState.priceLevelQuantities};
        if (newState.priceLevelQuantities[action.id] && action.value === undefined) {
            delete newState.priceLevelQuantities[action.id];
        } else {
            newState.priceLevelQuantities[action.id] = action.value;
        }
        return newState;
    } else if(action.type === "SET_BUNDLE_FILTER_QUANTITY") {
        let newState = {...state};
        newState.bundleQuantities = {...newState.bundleQuantities};
        if (newState.bundleQuantities[action.id] && action.value === undefined) {
            delete newState.bundleQuantities[action.id];
        } else {
            newState.bundleQuantities[action.id] = action.value;
        }
        return newState;
    } else if(action.type === "SET_FILTER_RESULTS") {
        let newState = {...state};
        newState.filterResults = {...newState.filterResults};
        newState.filterResults = action.value;
        return newState;
    } else {
        return state;
    }
}

