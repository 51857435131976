import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { setHelpOpen } from '../../../../listing/views/help/actions/help';
import { setCancelConfirm, setLanguageModal } from '../../../../checkout/actions/ui';
import Button from 'shared/Button';

class KioskSelectLanguageButtonContainer extends Component {
    render() {
        if(this.props.languages.length < 2) {
            return null;
        }
        return(
            <Button className="kiosk-footer__button--secondary select-language__button" onClick={this.onClick}>
                {this.currentLanguage()}
                <span className="button-secondary--icon"><i className="icon-keyboard_arrow_down" aria-hidden="true"></i></span>
            </Button>
        )
    };

    currentLanguage = () => {
        let currentLanguage = this.props.languages.find(el => el.js_code == this.props.locale);
        return currentLanguage.name;
    }

    onClick = () => {
        this.props.setCancelConfirm(false);
        this.props.setHelpOpen(false);
        this.props.setLanguageModal(true);
    };
}

const mapStateToProps = (state, props) => {
    return {
        locale: state.intl.locale,
        languages: state.intl.languages,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setHelpOpen,
        setCancelConfirm,
        setLanguageModal,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskSelectLanguageButtonContainer);
