import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HelpContainer from 'listing/views/help/containers/HelpContainer';

class KioskBodyContainer extends Component {
    render() {
        return(
            <div className="kiosk__body">
                {this.props.children}
            </div>
        )
    }
}

export default KioskBodyContainer
