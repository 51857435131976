import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Numpad extends React.Component {

    render() {
        return (
            <NumPad className="numpad">
                <NumKey onClick={() => this.props.onKeyPress('7')}>{'7'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('8')}>{'8'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('9')}>{'9'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('4')}>{'4'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('5')}>{'5'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('6')}>{'6'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('1')}>{'1'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('2')}>{'2'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('3')}>{'3'}</NumKey>
                {/* <NumKey onClick={() => this.props.onKeyPress('.')}>{'.'}</NumKey> */}
                <NumKey disabled>{''}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('0')}>{'0'}</NumKey>
                <NumKey onClick={() => this.props.onKeyPress('<')}>
                  <svg style={{width:'27px', height:'27px'}} viewBox="0 0 24 24">
                      <path fill="#000000" d="M22,3H7C6.31,3 5.77,3.35 5.41,3.88L0,12L5.41,20.11C5.77,20.64 6.31,21 7,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12" />
                  </svg>
                </NumKey>
            </NumPad>
        );
    }
}

Numpad.propTypes = {
    onKeyPress: PropTypes.func.isRequired
};

export default Numpad;

const NumPad = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
`
const NumKey = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 33.3333%;
  height: 90px;
  font-size: 22px;
  border: 1px solid #C0C0C0;
  border-radius: 0;
  background: #ffffff;
  margin-bottom: -1px;
  font-family: "Open Sans", sans-serif;
  &:focus{
    background: #eaeaea;
    border-color: #C0C0C0;
    outline: 0px;
  }
  &:active{
    background: #ddd;
    box-shadow: none !important;
    border-color: #C0C0C0;
  }
  &:nth-child(2n){
    border-right: 0;
  }
  &:nth-child(odd){
    border-right: 0;
  }
  &:nth-child(3n){
    border-right: 1px solid #C0C0C0;
  }
  &[disabled]{
    background: #fff;
    &:hover, &:focus, &:active{
      background: #fff;
    }
  }
`
