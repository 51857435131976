import {
  setShowSuccess,
  setCouponCode,
  clearCouponCodes,
  setPresaleCode,
  setIsPresaleValid,
  setIsPresaleApplied,
} from 'checkout/views/coupon/actions/coupon';
import {
  setEventTimes,
  setSelectedEvent,
  setEventsToday,
  setFetchedEventsToday,
  setSelectedEventTimeObject,
  setChildEventAdditionalInfo,
} from '../views/series/actions/event-series';
import { clearSelectedPriceLevels } from '../views/ticket-picker/actions/ticket-picker';
import { clearSelectedBundles } from '../views/bundle-picker/actions/bundle-picker';
import { clearSelectedProducts } from '../views/product-picker/actions/product-picker';
import { removeListingItem } from '../../listing/helpers/storage';
import { reservationActive } from '../selectors/reservation';
import { isValidDateObject, ISOStringToDate } from '../../helpers/time';
import { serializeToUrl } from 'checkout/helpers/helpers';
import { wrappedFetch } from '../helpers/fetch';

export function setEventData(data) {
  return { type: 'FETCH_EVENT', data };
}

export function setListingInfoData(data) {
  return { type: 'FETCH_LISTING_INFO', data };
}

export function setFetchedEventId(id) {
  return { type: 'SET_FETCHED_EVENT', id };
}

export function refreshEventData(data) {
  return { type: 'REFRESH_EVENT', data };
}

export function setDataForEventIfNotSet(eventId, eventData, subReducerKey = null) {
  return { type: 'SET_DATA_FOR_EVENT_IF_NOT_SET', eventId, eventData, subReducerKey };
}

export function setPriceLevels(data, subReducerKey = null) {
  return { type: 'SET_PRICE_LEVELS', data, subReducerKey };
}

export function setEventPriceLevels(id, data) {
  return {
    type: 'SET_EVENT_PRICE_LEVELES',
    data,
    id,
  };
}

export function updatePriceLevels(data, subReducerKey = null) {
  return { type: 'UPDATE_PRICE_LEVELS', data, subReducerKey };
}
export function setBundles(data, subReducerKey = null) {
  return { type: 'SET_BUNDLES', data, subReducerKey };
}

export function setProducts(data, subReducerKey = null) {
  return { type: 'SET_PRODUCTS', data, subReducerKey };
}

export function setChildEvent(data, subReducerKey = null) {
  return { type: 'SET_CHILD_EVENT', data, subReducerKey };
}

export function setEventRefreshLoading(value) {
  return { type: 'SET_EVENT_REFRESH_LOADING', value };
}

export function fetchEvent() {
  return (dispatch, getState) => {
    let state = getState();
    let now = new Date();
    let timestamp = Math.floor(now.getTime() / 1000);
    dispatch(setEventRefreshLoading(true));
    let url = `${state.ui.apiUrl}/listing-settings/${state.ui.listingId}/listing-data?timestamp=${timestamp}`;
    return wrappedFetch(url)
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result && result.attributes && result.attributes.listing_data) {
          //refresh state
          let state = getState();
          //do not update if checkout is being actively used
          if (!reservationActive(state) && !state.ui.overlay) {
            dispatch(refreshEventData(result.attributes.listing_data));
            if (result.attributes.series && result.attributes.series.events_today) {
              dispatch(setFetchedEventsToday(true));
              if (Array.isArray(result.attributes.series.events_today)) {
                let now = new Date();
                dispatch(
                  setEventsToday(
                    result.attributes.series.events_today.map((event) => {
                      //may be behind on marking as post_sale due to caching, so check against salesClose time here
                      let salesCloseTime = ISOStringToDate(event.salesClose);
                      if (isValidDateObject(salesCloseTime) && salesCloseTime.getTime() < now.getTime()) {
                        return {
                          ...event,
                          eventStatus: 'post_sale',
                        };
                      }
                      return event;
                    }),
                  ),
                );
              } else {
                dispatch(setEventsToday([]));
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setEventRefreshLoading(false));
      });
  };
}

export function getEventPriceLevels() {
  return (dispatch, getState) => {
    let state = getState();
    let payload = {
      listingId: state.ui.listingId,
      eventId: state.ui.uiConfig.eventId
        ? state.ui.uiConfig.eventId
        : Object.values(state.event.events)[0].event.event_id,
    };

    return wrappedFetch(state.ui.apiUrl + '/price-levels-event?' + serializeToUrl(payload), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        dispatch(setPriceLevels(data.price_levels));
      })
      .catch(() => {});
  };
}

export function clearChildEventDetails(reducerKey = null) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setEventTimes([], null, reducerKey));
    dispatch(setChildEvent({}, reducerKey));
    dispatch(setSelectedEvent({}, reducerKey));
    dispatch(setChildEventAdditionalInfo(''));
    if (!reducerKey) {
      removeListingItem(state.ui.listingSlug, 'selectedEventId');
      dispatch(setShowSuccess(false));
      dispatch(setCouponCode(''));
      dispatch(clearCouponCodes());
    }

    dispatch(clearSelectedPriceLevels(reducerKey));
    dispatch(clearSelectedBundles(reducerKey));
    dispatch(clearSelectedProducts(reducerKey));
    if (!reducerKey) {
      dispatch(setPresaleCode(''));
      dispatch(setIsPresaleValid(false));
      dispatch(setIsPresaleApplied(false));
    }
    dispatch(setSelectedEventTimeObject(null, reducerKey));
  };
}
