import {getCheckoutClient} from '../config/CheckoutClient';
import Polyglot from 'node-polyglot';

function Translations(apiUrl) {
    this.polyglot = null;
    this.mappings = {};
    this.apiUrl = apiUrl;

    this.init = function(locale = 'en_US', translations = {}) {
        this.polyglot = new Polyglot({
            locale,
            interpolation: {prefix: '%', suffix: '%'}
        });

        if (typeof translations === 'object' && translations !== null && Object.keys(translations).length) {
            this.mappings = translations;
        }

        this.polyglot.extend(this.mappings);
    };

    this.fetchTranslations = (locale) => {
        return getCheckoutClient(this.apiUrl).getTranslations(locale).then((res) => res.body);
    };

    /**
     * Function to fetch translations for arbitrary slugs
     * @param {string} locale string for locale, e.g. "en_US"
     * @param {Array<string>} slugs array of slugs to fetch translations, e.g. ["not_a_valid_email_address"]
     */
    this.fetchTranslationsBySlug = (locale, slugs) => {
        return getCheckoutClient(this.apiUrl).getTranslationsBySlug(locale, slugs).then((res) => res.body);
    };

    this.getTranslationsByNamespace = (locale, namespace) => {
        return getCheckoutClient(this.apiUrl).getTranslationsByNamespace(locale, namespace).then((res) => res.body);
    };

    this.resetTranslations = (translations) => {
        this.mappings = {...translations};

        this.polyglot.replace(this.mappings);
    }

    this.t = (stub, interpolations) => {
      if (typeof stub !== 'string') {
          return '';
      }
      let lowercaseStub = stub.toLowerCase();
          if(!this.polyglot.has(lowercaseStub)) {
            if(!this.mappings[lowercaseStub]) {
              return stub;
            }
            return this.mappings[lowercaseStub];
          }

        return this.polyglot.t(lowercaseStub, interpolations);
    };
}

export default Translations;
