import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationBanner from './NotificationBanner';
import Translation from '../../shared/Translation';

class Notification extends React.Component {
    render() {
        return <NotificationOverlay className="notification__overlay" id="status">
            {this.renderMessages()}
        </NotificationOverlay>
    }

    renderMessages = () => {
        return this.props.messages.map((message, i) => {
            return <NotificationBanner key={i}>
                <Translation>{message}</Translation>
            </NotificationBanner>
        });
    }
}

Notification.propTypes = {
    messages: PropTypes.array
};

Notification.defaultProps = {
    messages: []
};

export default Notification;

const NotificationOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 400;
`
