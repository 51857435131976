import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TicketsButton from '../components/TicketsButton';
import { setShowCalendarInModal, setShowBestAvailablePicker, setShowPyosPicker } from '../actions/ui';
import { ticketPickerOpened, setCheckoutOverlay } from 'checkout/actions/ui';
import { triggerTransition } from '@uirouter/redux';
import { reservationActive } from '@/selectors/reservation';
import {
  setEventTimes,
  setSelectedDate,
  setSelectedChildEvent,
  setSelectedEventTimeObject,
  selectChildEventAndContinue,
  getEventTimes,
} from '../views/series/actions/event-series';
import { isValidDateObject } from '../../helpers/time';
import { removeListingItem } from '../../listing/helpers/storage';
import { getFormattedEventDates, getSeriesOption} from '../views/series/selectors/eventSeries';
import { isKioskMode, isMultiEmbed } from '../selectors/ui';
import { getMainEvent } from '../selectors/event';
import { fetchEvent } from '../actions/event';
import Translation from '@s/Translation';
import { getTimestampAndRemoveFromUrl } from '../helpers/url';
import { isSameDay } from '../../../../shared/util/dates';
import moment from 'moment-timezone';

class TicketsButtonContainer extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const timestamp = getTimestampAndRemoveFromUrl('date');

    if (timestamp) {
      if (this.props.isRecurring) {
        const date = new Date(timestamp);
        const isDateIsAvailable = this.props.eventDates.some((availableDate) => isSameDay(availableDate, date));

        if (isDateIsAvailable) {
          this.props.setSelectedDate(date).then(() => this.props.getEventTimes(this.props.eventId, date).then(() => this._startCheckout()));
        }
      } else {
        this._startCheckout();
      }
    }
  }

  render() {
    if (this.props.maintenanceMode) {
      return null;
    }

    if(this.props.listingSoldOut === true) {
      if (this.props.uiConfig.listings.useCustomSoldOutText && typeof this.props.uiConfig.listings.soldOutText !== "undefined" && this.props.uiConfig.listings.soldOutText !== "") {
        return <>{this.props.uiConfig.listings.soldOutText}</>;
      }

      const isLiveCondition = !this.props.isLive ? '' : <Translation>{"checkout__sold_out"}</Translation>;
      const isKioskModeCondition = this.props.isKioskMode ? <Translation>{"checkout__kiosk_sold_out"}</Translation> : isLiveCondition;

      return typeof this.props.eventEnded === 'boolean' && this.props.eventEnded ? <Translation>{"checkout__event_ended"}</Translation> : isKioskModeCondition;
    }

    return (
      <TicketsButton
        uiConfig={this.props.uiConfig}
        priceLevels={this.props.priceLevels}
        onClick={this.startCheckout}
        coupon={this.props.coupon}
        hasPresale={this.props.hasPresale}
        isOnsale={this.props.isOnsale}
        isCanceled={this.props.isCanceled}
        title={this.props.title}
        type={this.props.type}
        loading={this.state.loading}
        isRecurring={this.props.isRecurring}
        maintenanceModeLoaded={this.props.maintenanceModeLoaded}
        disabled={this.props.taggableCouponLoading}
        invitationCouponApplied={this.props.inviteCouponApplied}
      >
        {this.props.children}
      </TicketsButton>
    );
  }

  startCheckout = () => {
    //in kiosk mode, refresh local data before transitioning to the next step
    if (this.props.isKioskMode) {
      this.setState({ loading: true });
      this.props.fetchEvent().finally(() => {
        this.setState({ loading: false });
        //if option to open to nearest available event time enabled for kiosk,
        //  1. open to nearest time if the next time is not sold out
        //  2. if the next time is sold out open to the kiosk time picker
        //  3. if the day is sold out, open to series date picker
        //
        //Note: this code should likely be revisted when inventory service is up and running.
        if (this.props.isRecurring && this.props.openToNextAvailable) {
          let hasOnsaleEventToday = false;
          if (this.props.eventsToday && this.props.eventsToday.length) {
            this.props.eventsToday.map((eventTime) => {
              if (eventTime.eventStatus === 'on_sale') {
                hasOnsaleEventToday = true;
              }
            });

            if (!hasOnsaleEventToday) {
              this._startCheckout();
            } else {
              const now = new Date();

              //sort by time ascending
              const todaysEvents = this.props.eventsToday.sort((a, b) => {
                const aDate = new Date(a.time);
                const bDate = new Date(b.time);
                if (!isValidDateObject(aDate) || !isValidDateObject(bDate)) {
                  return 1;
                }
                return aDate.getTime() - bDate.getTime();
              });

              for (let i = 0; i < todaysEvents.length; i++) {
                let event = todaysEvents[i];
                let eventTime = new Date(event.time);

                if (isValidDateObject(eventTime) && eventTime.getTime() > now.getTime()) {
                  //if closest event is not on sale, open time picker
                  if (event.eventStatus !== 'on_sale') {
                    this.props.setSelectedDate(now).then(() => {
                      this.props.triggerTransition('kioskTimePicker');
                    });
                  } else {
                    //clear previously populated data
                    this.props.setEventTimes([]);
                    removeListingItem(this.props.listingSlug, 'selectedEventId');

                    this.props.setSelectedDate(eventTime).then(() => {
                      this.selectEvent(event);
                    });
                  }

                  break;
                }
              }
            }
          } else {
            this._startCheckout();
          }
        } else {
          this._startCheckout();
        }
      });
    } else {
      this._startCheckout();
    }
  };

  _startCheckout = () => {
    if (!this.props.reservationActive && this.props.isRecurring) {
      //setting checkout overlay handled in transition for seriesDatePicker
      this.props.triggerTransition('seriesDatePicker');
    } else if (
      (!this.props.isOnsale &&
        this.props.hasPresale &&
        Object.keys(this.props.priceLevels.levels).length === 0 &&
        this.props.coupon.isPresaleCodeApplied === false) ||
      (this.props.hasPlAccess === true && Object.keys(this.props.priceLevels.levels).length === 0)
    ) {
      // presale or no PLs available w/o access code
      this.props.setCheckoutOverlay(true);
      this.props.triggerTransition('coupon');
    } else {
      // clear out previous selection
      this.props.setShowBestAvailablePicker(false);
      this.props.setShowPyosPicker(false);

      this.props.setCheckoutOverlay(true);
      this.props.ticketPickerOpened();

      if (this.props.isMultiEmbed) {
        this.props.triggerTransition('picker-with-details');
      }

      if (typeof this.props.showCheckoutCallback === 'function') {
        this.props.showCheckoutCallback();
      }
    }
  }

  selectEvent = (event) => {
    this.props.setSelectedEventTimeObject(event);
    this.props.setSelectedChildEvent(event.id);
    this.props.selectChildEventAndContinue(event.id);
  }
}

const IsEventPast = (date) => {
  let currentDate = new Date();
  if (date) {
    let eventEndDate = moment.unix(date);
    if (currentDate > eventEndDate.toDate()) {
      return true;
    }
  }
  return false;
}

const mapStateToProps = (state) => {
  const theEvent = getMainEvent(state)?.event ?? {};

  return {
    eventEnded: IsEventPast(theEvent.salesCloseDate),
    isMultiEmbed: isMultiEmbed(state),
    listingSlug: state.ui.listingSlug,
    uiConfig: state.ui.uiConfig,
    priceLevels: state.priceLevel,
    coupon: state.coupon,
    hasPresale: theEvent.hasPresale,
    isOnsale: theEvent.isOnsale,
    isCanceled: theEvent.isCanceled,
    hasPlAccess: theEvent.hasPlAccess,
    isRecurring: theEvent.isRecurring,
    eventDates: getFormattedEventDates(state),
    reservationActive: reservationActive(state),
    listingSoldOut: state.ui.listingSoldOut,
    eventId: theEvent.event_id,
    apiUrl: state.ui.apiUrl,
    openToNextAvailable: getSeriesOption("openToNextAvailable", false)(state) ? true : false,
    isKioskMode: isKioskMode(state),
    eventsToday: state.eventSeries.eventsToday,
    maintenanceMode: state.ui.maintenanceMode,
    maintenanceModeLoaded: state.ui.maintenanceModeLoaded,
    taggableCouponLoading: state.couponTag.taggableCouponLoading,
    inviteCouponApplied: state.couponTag.appliedTaggableInvitationCode || state.reservation.hasInviteCoupon,
    isLive: theEvent.isLive
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ticketPickerOpened,
      triggerTransition,
      setEventTimes,
      setSelectedDate,
      setSelectedChildEvent,
      setShowCalendarInModal,
      selectChildEventAndContinue,
      setCheckoutOverlay,
      fetchEvent,
      setSelectedEventTimeObject,
      setShowBestAvailablePicker,
      setShowPyosPicker,
      getEventTimes,
    },
    dispatch,
  );
};

TicketsButtonContainer.propTypes = {
  showCheckoutCallback: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
};

TicketsButtonContainer.defaultProps = {
  title: '',
  type: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsButtonContainer);
