// @flow
import type { ClearAction } from './reservation';

export type ProductsConfigSetAction = { type: 'SET_PRODUCT_SALES', products: Array<Object> };

export type Action = ProductsConfigSetAction | ClearAction;

import { formatInventoryItemFromServer } from '../helpers/inventoryItems';

type State = { +products: Array<Object> };

let initialState = { products: [] };

export function productSales(state: State = initialState, action: Action): State {
  if (action.type === 'SET_PRODUCT_SALES') {
    let newState = { ...state };
    newState.products = [];
    for (var i = 0; i < action.products.length; i++) {
      newState.products.push(formatInventoryItemFromServer(action.products[i]));
    }

    return newState;
  } else if (action.type === 'SET_RAW_PRODUCT_SALES_STATE') {
    return action.state;
  } else if (action.type === 'RESERVATION_CLEARED') {
    return initialState;
  } else {
    return state;
  }
}
