import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import Button from '@s/Button';
import { setCancelConfirm, setLanguageModal } from '../../../actions/ui';
import { setHelpOpen } from '../../../../listing/views/help/actions/help';
import Translation from '@s/Translation';

class CancelButton extends Component {
    render() {
        return(
            <Button
                onClick={ this.onShowConfirm }
                className="kiosk-footer__button--secondary"
                disabled={this.props.interactionBlock}
            >
                <i className="button-secondary--icon icon-close" aria-hidden="true"></i>
              <Translation>{'checkout__cancel'}</Translation>
            </Button>
        )
    }

    onShowConfirm = () => {
        this.props.setHelpOpen(false);
        this.props.setLanguageModal(false);
        this.props.setCancelConfirm(true);
    }
}

const mapStateToProps = state => {
    return {
        interactionBlock: state.ui.interactionBlock,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setHelpOpen,
        setCancelConfirm,
        setLanguageModal,
    }, dispatch);
}

CancelButton.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton);
