import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import TermsOfService from '../components/TermsOfService';
import { setTos, clearPaymentError } from '../../../actions/payment';
import { inLastStep } from '../../../selectors/ui';
import { getPaymentErrors } from '../../../selectors/payment';
import { getMainEvent } from '../../../selectors/event';

class TermsOfServiceContainer extends Component {
    render() {

        if (!this.props.isInLastStep) {
            return null;
        }

      return (
        <TermsOfService
          tosChanged={this.tosChanged}
          tos={this.props.tos}
          tosUrl={this.props.tosUrl}
          ppUrl={this.props.ppUrl}
          embedTos={this.props.embedTos}
          useLeapTos={this.props.useLeapTos}
          error={this.props.error}
        />
      );
    }

    tosChanged = (e) => {
        this.props.clearPaymentError('tosAccepted');
        this.props.setTos(e.target.checked);
    }
}

const mapStateToProps = state => {
    const mainEvent = getMainEvent(state).event;

    return {
        tos: state.reservation.tos,
        isInLastStep: inLastStep(state),
        tosUrl: state.reservation.tosUrl,
        ppUrl: mainEvent.privacy_url,
        embedTos: state.reservation.embedTos,
        useLeapTos: state.ui.uiConfig.use_leap_tos,
        error: getPaymentErrors('tosAccepted')(state)
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setTos,
        clearPaymentError
    }, dispatch);
}

TermsOfServiceContainer.propTypes = {
    steps: PropTypes.arrayOf(Object)
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfServiceContainer)
