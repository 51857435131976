import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDependentProductSelected } from '../../product-picker/selectors/products';
import { getTotalSelectedItems, getProductErrors, getBundleErrors } from '../selectors/selected-items';
import { isKioskMode } from '../../../selectors/ui';
import InventoryConfigurationErrors from '../components/InventoryConfigurationErrors';
import { showBestAvailablePicker } from '../../../selectors/reserved-seating';
import Translation from '@s/Translation';

class InventoryErrors extends Component {

    render() {
        let productError = null;
        let configErrors = null;

        if (this.dependentProductError() && !this.props.showBestAvailablePicker) {
            productError = this.props.isKioskMode
                ? (
                    <div className="alert alert--error">
                        <p><Translation>{'checkout__please_select_at_least_one_ticket'}</Translation></p>
                    </div>
                )
                :(
                    <div className="message__container message__container_error error__content">
                        <p><Translation>{'checkout__please_select_at_least_one_ticket'}</Translation></p>
                    </div>
                );
        }

        return(
        	<Fragment>
                {productError}
                {this.props.displayConfigurationError && this.renderConfigurationErrors()}
            </Fragment>
        )
    }

    renderError = (error) => {
        if (this.props.isKioskMode) {
            return <div className="alert alert--error">{error}</div>;
        }
        return <div className="message__container message__container_error error__content">{error}</div>;
    }

    dependentProductError = () => {
        return (((this.props.ticketCount < 1 || this.props.ticketCount === undefined) && (this.props.bundleCount < 1 || this.props.bundleCount === undefined)) &&
            (!this.props.selectedItems && this.props.selectedDependentProducts));
    };

    renderConfigurationErrors = () => {
        return (
            <InventoryConfigurationErrors
                productsWithErrors={this.props.productsWithErrors}
                bundleProductsWithErrors={this.props.bundleProductsWithErrors}
                isKioskMode={this.props.isKioskMode}
            />
        );
    };

}

const mapStateToProps = (state, props) => {
    return {
    	ticketCount: state.reservation.ticket_count,
    	bundleCount: state.reservation.bundle_count,
    	selectedItems: getTotalSelectedItems(state),
    	selectedDependentProducts: isDependentProductSelected(state),
        displayConfigurationError: state.ticketPicker.displayConfigurationError,
        productsWithErrors: getProductErrors(state),
        bundleProductsWithErrors: getBundleErrors(state),
        isKioskMode: isKioskMode(state),
        showBestAvailablePicker: showBestAvailablePicker(state),
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    },
    dispatch);
}

InventoryErrors.propTypes = {

}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryErrors)
