import { toDecimals, toMinorUnit } from "@h/currency";
import { serializeToUrl } from "@h/helpers";
import { setLoading } from "checkout/actions/loading";
import { getCheckoutClient } from "checkout/config/CheckoutClient";
import { addAlert } from "checkout/actions/ui";
import { getInsurancePayload } from "checkout/selectors/request";
import { wrappedFetch } from '../../../helpers/fetch';

export function getInsuranceQuote() {
    return (dispatch, getState) => {
        let state = getState();

        if(state.insurance.provider) {
            if(state.insurance.provider === "ticketguardian") {
                return getTicketGuardianQuote(dispatch, state);
            }
        }
    }
}

function getTicketGuardianQuote(dispatch, state) {
    dispatch(setLoading("TICKETGUARDIAN_QUOTE_REQUEST"));
    let key = state.insurance.key;
    let currency = state.reservation.total_cost.currency;

    let data = {
        'items[]': state.insurance.insuranceItems,
        currency
    };

    let params = serializeToUrl(data);
    return wrappedFetch(`${state.ui.apiUrl}/insurance/ticket-guardian/${key}/quote?${params}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'same-origin',
    }).then((response) => {
        return response.json();
    }).then((response) => {
        dispatch(setInsurancePrice(+toMinorUnit(response.quote, currency)));
        dispatch(setLoading("TICKETGUARDIAN_QUOTE_SUCCESS"));
    }).catch((e) => {
        dispatch(setLoading("TICKETGUARDIAN_QUOTE_FAILURE"));
    });
}

export function setInsurancePrice(price) {
    return {type: "SET_INSURANCE_PRICE", price};
}

export function setInsurancePolicy(selected, options = {}) {
    return {type: "SET_INSURANCE_POLICY", selected, options};
}

export function setInsuranceOption(event) {
  return { type: 'SET_INSURANCE_OPTION', event };
}

export function submitInsurance() {
    return (dispatch, getState) => {
        dispatch(setLoading("INSURANCE_SUBMIT_REQUEST"));
        let state = getState();
        let token = state.reservation.token;
        let secret = state.reservation.secret;
        let payload = getInsurancePayload(state);
        return getCheckoutClient(state.ui.apiUrl).reservationUpdateRequest(token, 'insurance', payload, secret).then((response) => {
            if(response.body.quoteDiffer) {
                dispatch(addAlert(response.body.quoteDiffer, 'warning'));
            }

            dispatch(setLoading("INSURANCE_SUBMIT_SUCCESS"));
        }).catch((e) => {
            dispatch(setLoading("INSURANCE_SUBMIT_FAILURE"));
        });
    }
}

export function processInsuranceResponse(dispatch, state, res) {
    if(res.quoteDiffer) {
        dispatch(addAlert(res.quoteDiffer, 'warning'));
    }

    dispatch(setLoading("INSURANCE_SUBMIT_SUCCESS"));
}
