import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class NotificationBanner extends React.Component {
    render() {
        return (
            <NotificationBar className="notification__bar">
                {this.props.children}
            </NotificationBar>
        );
    }
}

NotificationBanner.propTypes = {
};

export default NotificationBanner;

const NotificationBar = styled.div`
  position: relative;
  width: 100%;
  padding: 5px 10px;
  background-color: #FFC600;
  text-align: center;
  font-size: 14px;
  line-height: 1.35714286;
  color: #543C12;
  // border-bottom: 1px solid #543C12;
`
