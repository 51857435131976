import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholderSelected: '',
        }
    }
    componentDidMount() {
        //check the select input has placeholder selected on mount for styling purposes
        if(this.props.value === '-1'){
            this.setState({placeholderSelected: true});
        }else{
            this.setState({placeholderSelected: false});
        }
    }
    render() {
        return(
            <div className={`input__select input--dropdown ${this.props.options.length ? '' : 'disabled'} ${this.state.placeholderSelected ? 'input__select--unselected' : ''}`}>
                <select
                    id={this.props.id}
                    className={this.props.className}
                    onChange={this.onChange}
                    value={this.props.value}
                    disabled={ this.props.options.length ? false : true  }
                >
                    {this.props.placeholder &&
                        <option value="-1">{this.props.placeholder}</option>
                    }
                    {this.renderOptions()}
                </select>
            </div>
        )
    }

    renderOptions = () => {
        return this.props.options.map((opt, i) => {
            return <option value={opt.value} key={i}>{opt.title}</option>
        });
    }

    onChange = (e) => {
        //Is the placeholder option selected?
        //"-1" is the placeholder option that gets special class for styling
        if(e.target.value === '-1'){
            this.setState({placeholderSelected: true});
        }else{
            //all non placeholder options
            this.setState({placeholderSelected: false});
        }
        e.preventDefault();
        e.stopPropagation();
        this.props.onChange(e.target.value);
    }
}

Dropdown.propTypes = {
    value: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
}

Dropdown.defaultProps = {
    id: "",
    className: "",
}

export default Dropdown;
