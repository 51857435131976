import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PriceLevelDefaultOrder from '../../ticket-picker/components/PriceLevelDefaultOrder';
import PriceLevelFilterContainer from './PriceLevelFilterContainer';
// import BundleFilterContainer from './BundleFilterContainer';
import { getPriceLevels, getBundles } from '../../../selectors/event';

class InventoryFilterContainer extends Component {
    render() {
        return this.props.itemOrder ? this.renderCustomOrder() : this.renderDefaultOrder();
    }

    renderDefaultOrder = () => {
        return(<Fragment>
            <PriceLevelDefaultOrder priceLevels={this.props.priceLevels} levelFilter={this.props.levelFilter} componentName="PriceLevelFilterContainer" />
            {/*{Object.values(this.props.bundles).map(bn => <BundleFilterContainer key={bn.id} bundleId={bn.id}/>)}*/}
        </Fragment>);
    }

    renderCustomOrder = () => {
        const hasValidFilterItems = group => {
            let isValid = false;
            if (group && Array.isArray(group.contents)) {
                group.contents.map(item => {
                    if (item.type === 'ticket') {
                        isValid = true;
                    }
                });
            }
            return isValid;
        }

        return this.props.itemOrder.map((group, groupKey) => {
            if (hasValidFilterItems(group)) {
                return (
                    <div key={groupKey}>
                        <h4 className="ticket__header tickets__group-title">{group.title}</h4>
                        {group.contents.map((item, i) => {
                            if (item.type === 'ticket') {
                                if(!this.props.priceLevels[item.id]) {
                                    return null;
                                }
                                return <PriceLevelFilterContainer key={i} levelId={item.id}/>
                            // } else if(item.type === 'bundle') {
                            //     if(!this.props.bundles[item.id]) {
                            //         return null;
                            //     }
                            //     return <BundleFilterContainer key={i} bundleId={item.id}/>
                            } else {
                                return null;
                            }
                        })}
                    </div>
                );
            }
        });

    }
}

const mapStateToProps = (state, props) => {
    return {
        itemOrder: state.ui.checkoutConfig.inventory,
        levelFilter: state.ui.listingConfig.levels,
        priceLevels: getPriceLevels(state),
        // bundles: getBundles(state),
    };
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFilterContainer)
