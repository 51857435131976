import { wrappedFetch } from '../../../../checkout/helpers/fetch';
import { setListingItem, removeListingItem } from '../../../helpers/storage';
import { getDefaultTwoFactorConfiguration, onlyHasU2F } from '../selectors/kiosk-management';

export const KIOSK_AUTH_TOKEN = "KIOSK_AUTH_TOKEN";

export function setManagementOpen(val) {
    return {type: "SET_MANAGEMENT_OPEN", value: val};
}

export function setKioskToken(val) {
    return {type: "SET_KIOSK_TOKEN", val};
}

export function setLoginLoading(val) {
    return {type: "SET_LOGIN_LOADING", val};
}

export function setTwoFactorChallengeLoading(val) {
    return {type: "SET_TWO_FACTOR_CHALLENGE_LOADING", val};
}

export function setTwoFactorConfigs(val) {
    return {type: "SET_TWO_FACTOR_CONFIGS", val};
}

export function setPendingTwoFactorChallengeId(val) {
    return {type: "SET_PENDING_TWO_FACTOR_CHALLENGE_ID", val};
}

export function setPendingTwoFactorChallengeType(val) {
    return {type: "SET_PENDING_TWO_FACTOR_CHALLENGE_TYPE", val};
}

export function setPendingTwoFactorChallengeDescription(val) {
    return {type: "SET_PENDING_TWO_FACTOR_CHALLENGE_DESCRIPTION", val};
}

export function clear2FA() {
    return (dispatch, getState) => {
        dispatch(setPendingTwoFactorChallengeId(null));
        dispatch(setPendingTwoFactorChallengeType(null));
        dispatch(setPendingTwoFactorChallengeDescription(null));
        dispatch(setTwoFactorConfigs([]));
    }
}

export function login(email, password, twoFactorChallengeResponse) {
    return (dispatch, getState) => {
        dispatch(setLoginLoading(true));
        let state = getState();
        let payload = {
            email: email,
            password: password,
            twoFactorChallengeId: state.kioskManagement.pendingTwoFactorChallengeId,
            twoFactorChallengeResponse: twoFactorChallengeResponse,
            slug: state.ui.listingSlug,
        };
        return wrappedFetch(state.ui.apiUrl + '/kiosks/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(payload)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.token) {
                setListingItem(state.ui.listingSlug, KIOSK_AUTH_TOKEN, data.token);
                dispatch(setKioskToken(data.token));
                dispatch(clear2FA());
                return {success: true}
            } else if(data.requiresTwoFactor && !twoFactorChallengeResponse) {
                return dispatch(getTwoFactorConfigs(email, password)).then(data => {
                    let defaultConfig = getDefaultTwoFactorConfiguration(getState());
                    if (defaultConfig) {
                        return dispatch(issue2FAChallenge(email, password, defaultConfig.id)).then((challengeResult) => {
                            return challengeResult;
                        }).catch(err => {
                            return err;
                        });
                    } else {
                        if (onlyHasU2F(getState())) {
                            return {error: "Yubikey 2FA is not currently supported for kiosk. Please configure a new 2FA method to login with this account."};
                        } else {
                            return {error: "This account requires two factor authentication, but no two factor configuration was found."};
                        }
                    }
                }).catch(err => {
                    return err;
                });
            } else {
                if (data.errors && data.errors[0] && data.errors[0].detail) {
                    return {error: data.errors[0].detail}
                } else {
                    throw new Error();
                }
            }
        }).catch(function(err) {
            return {error: "Authentication error"};
        });
    }
}

export function getTwoFactorConfigs(email, password) {
    return (dispatch, getState) => {
        let state = getState();
        let payload = {
            email: email,
            password: password,
        };
        return wrappedFetch(state.ui.apiUrl + '/auth/getTwoFactorConfigs', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(payload)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            dispatch(setTwoFactorConfigs(data));
            return {success: true};
        }).catch(function(err) {
            return {error: "Error retrieving 2FA configurations"};
        });
    }
}

export function issue2FAChallenge(email, password, configId) {
    return (dispatch, getState) => {
        let state = getState();
        let payload = {
            email: email,
            password: password,
            configId: configId,
        };
        dispatch(setTwoFactorChallengeLoading(true));
        return wrappedFetch(state.ui.apiUrl + '/auth/issue2FAChallenge', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'same-origin',
            body: JSON.stringify(payload)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data && data.id && data.type && data.description) {
                dispatch(setPendingTwoFactorChallengeId(data.id));
                dispatch(setPendingTwoFactorChallengeType(data.type));
                dispatch(setPendingTwoFactorChallengeDescription(data.description));
                return {success: 1};
            } else {
                throw new Error();
            }
        }).catch(function(err) {
            return {error: "Error issuing 2FA Challenge"};
        }).finally(() => {
            dispatch(setTwoFactorChallengeLoading(false));
        });
    }
}

export function logout() {
    return (dispatch, getState) => {
        removeListingItem(getState().ui.listingSlug, KIOSK_AUTH_TOKEN);
        dispatch(setKioskToken(null));
    }
}

export function sendMonitoring() {
    return (dispatch, getState) => {
        let state = getState();
        if(+state.reservation.purchaseUser === 0) {
            return;
        }

        let apiUrl = state.ui.apiUrl;
        let req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                purchaseUser: state.reservation.purchaseUser,
                listingSlug: state.ui.listingSlug,
                payload: {
                    terminalConnected: state.payment.isReaderConnected,
                    status: state.ui.helpModal ? 'help' : 'ok',
                }
            })
        };

        return wrappedFetch(apiUrl + '/kiosks/monitoring', req).then((res) => {});
    }
}

export function getTerminalsFetch() {
    return (dispatch, getState) => {
        let state = getState();

        let req = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-API-Token': state.kioskManagement.token,
            },
        };

        let url = `${state.ui.apiUrl}/kiosks/seller/${state.event.seller_id}/terminals`;
        return wrappedFetch(url, req);
    }
}

export function getRecentOrdersFetch(purchaseUser) {
    return (dispatch, getState) => {
        let state = getState();

        let req = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-API-Token': state.kioskManagement.token,
            },
        };

        let url = `${state.ui.apiUrl}/kiosks/recentOrders/${purchaseUser}`;
        return wrappedFetch(url, req);
    }
}

export function getPinAuthenticateFetch(listingSlug, pinNumber) {
    return (dispatch, getState) => {
        let state = getState();

        let req = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({listing: listingSlug, pin: pinNumber})
        }

        let url = `${state.ui.apiUrl}/kiosks/pin-authenticate`;
        return wrappedFetch(url, req);
    }
}

export function getUsersFetch(listingSlug) {
    return (dispatch, getState) => {
        let state = getState();

        let req = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-API-Token': state.kioskManagement.token,
            },
        };

        let url = `${state.ui.apiUrl}/kiosks/${listingSlug}/users`;
        return wrappedFetch(url, req);
    }
}
