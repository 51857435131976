import { wrappedFetch } from '../helpers/fetch';

export function printToSidekick(reservationId, sidekick) {
    return (dispatch, getState) => {
        let state = getState();

        let req = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'text/plain; charset=ISO-8859-1',
            },
        };

        return wrappedFetch(`${state.ui.apiUrl}/kiosks/ticketLayout/${reservationId}`, req).then((res) => {
            if(res.status === 200) {
                return res.text();
            }
        }).then((response) => {
            sidekick.printFromSettings(
                {
                    auto_forward: true,
                    double_receipt: false,
                },
                {
                    kind: 'receipt',
                    content: response,
                    success: function() {
                    },
                    error: function(xhr) {
                    },
                    submit: function() {
                    }
                }
            );
        }).catch((e) => {
            console.warn(e);
        });
    }
}
