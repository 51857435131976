import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Props from './FooterComponent';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import Translation from "../../shared/Translation";
import { getMainEvent } from '../../checkout/selectors/event';

export class ContactInfo extends Component<Props> {
    getFormattedPhoneNumber = () => {
        return "tel:" + this.props.contactInfo.phone;
    }

    getContactUsUrl = () => {
        return "/event/" + this.props.event_id + "/contact?referral_url=" + window.location.href;
    }

    getWebsiteUrl = () => {
        if(this.props.contactInfo.website.startsWith("http://") || this.props.contactInfo.website.startsWith("https://")){
            return this.props.contactInfo.website;
        } else {
            return "//" + this.props.contactInfo.website;
        }
    }

    areAllContactInfoDisabled() {
        return !this.props.contactInfo.enableContactForm ||
            (!this.props.contactInfo.enableMailingAddress &&
            !this.props.contactInfo.enableTelephone &&
            !this.props.contactInfo.enableWebsite &&
            !this.props.contactInfo.validatedEmail);
    }

    render() {
        if (!this.props.showContactOnListing || this.areAllContactInfoDisabled()) {
            <div className="page__contact" />
        }

        return(
            <div className="page__contact">
                {this.props.contactInfo.enableContactForm &&
                <div className="page-contact--outer">
                    <div className="page-contact--inner">
                        <div className="page__contact__top">
                            <h3 className="page__contact__org-title">{this.props.contactInfo.name}</h3>
                            {this.props.contactInfo.orglisting && (
                                <div className="page__contact__url">
                                    <a target="_blank" href={this.props.contactInfo.orglisting} className="org-listing-url">
                                    <Translation>{"checkout__more_events_by"}</Translation> {this.props.contactInfo.name}
                                    </a>
                                </div>
                            )}
                            {this.props.contactInfo.enableWebsite &&
                            <div className="page__contact__url">
                                <a href={this.getWebsiteUrl()}>{this.props.contactInfo.website}</a>
                            </div>}
                        </div>
                        <div className="page__contact__bottom">
                        {this.props.contactInfo.validatedEmail &&
                            <div className="page__contact__bottom-section">
                                <div className="page__contact__email">
                                    <div className="page__contact__email-link-container">
                                        {!!this.props.event_id && <a target="_blank" href={this.getContactUsUrl()} className="page__contact__email-link">
                                            <span className="page__contact__email-icon">
                                                <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"/></svg>
                                            </span>
                                          <Translation>{"checkout__contact_us"}</Translation>
                                        </a>}
                                    </div>
                                </div>
                            </div>}
                            {this.props.contactInfo.enableMailingAddress &&
                            <div className="page__contact__bottom-section">
                                <div className="page__contact__address">
                                    {this.props.contactInfo.address}
                                </div>
                            </div>}
                            {this.props.contactInfo.enableTelephone &&
                            <div className="page__contact__bottom-section">
                                <div className="page__contact__phone">
                                    <a href={this.getFormattedPhoneNumber()} rel={"nofollow"}>{this.props.contactInfo.phone}</a>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

ContactInfo.propTypes = {
    contactInfo: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
        website: PropTypes.string,
        enableContactForm: PropTypes.bool,
        enableContactFormInEmail: PropTypes.bool,
        enableWebsite: PropTypes.bool,
        enableMailingAddress: PropTypes.bool,
        enableTelephone: PropTypes.bool
    }).isRequired,
    event_id: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
}

const mapStateToProps = (state, props) => {
    return {
        contactInfo: state.contactInfo,
        event_id: getMainEvent(state)?.event?.event_id ?? '',
        showContactOnListing: (state.ui.uiConfig && state.ui.uiConfig.listings) ?
            (state.ui.uiConfig.listings.showContactOnListing != null ? state.ui.uiConfig.listings.showContactOnListing : false)
            : false,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
