import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Props from './AlertComponent';

import Translation from '@s/Translation';

class Alert extends Component<Props> {
    constructor(props) {
        super(props);
        this.alertDismiss = React.createRef();
    }
    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        if (this.alertDismiss) {
            this.alertDismiss.current.focus();
        }
    }
    render() {

        const interpolation = typeof this.props.alert.interpolation === 'object' && this.props.alert.interpolation !== null
            ? this.props.alert.interpolation
            : {};

        return(
            <div>
                <div className={this.getAlertCssClass()} role="alert">
                    <div className="alert__message">
                        <Translation interpolation={interpolation}>{this.props.alert.message}</Translation>
                    </div>
                    {this.getDismissButton()}
                </div>
                <div className="alert__space"></div>
            </div>
        )
    }

    getDismissButton = () => {
        if(!this.props.alert.dismissable) {
            return null;
        }

        return <div className="alert__dismiss">
            <button onClick={this.props.onDismiss(this.props.index)} className="button button--clear" ref={this.alertDismiss}><i className="icon-close" aria-hidden="true"></i><span className="sr-only">Dismiss Alert</span></button>
        </div>
    }

    getAlertCssClass = () => {
        if(this.props.alert.type === "error") {
            return "alert alert--error";
        } else if(this.props.alert.type === "warning") {
            return "alert alert--warning";
        } else if(this.props.alert.type === "success") {
            return "alert alert--success";
        } else if(this.props.alert.type === "info") {
            return "alert alert--info";
        } else {
            return "alert alert--error";
        }
    }
}

Alert.propTypes = {
    alert: PropTypes.object.isRequired,
    onDismiss: PropTypes.func.isRequired
}

export default Alert
