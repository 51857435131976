import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { setHelpOpen } from '../actions/help';
import HelpButton from '../components/HelpButton';
import { sendSlackMessage } from '../../../actions/general';
import { getKioskOptions } from '../../../../checkout/selectors/ui';
import { setCancelConfirm, setLanguageModal } from '../../../../checkout/actions/ui';
import Translation from '../../../../shared/Translation';

class HelpButtonContainer extends Component {
    render() {
        return(
            <HelpButton onClick={this.onClick}><Translation>{'checkout__need_help'}</Translation></HelpButton>
        )
    };

    onClick = () => {
        this.props.setCancelConfirm(false);
        this.props.setLanguageModal(false);
        this.props.setHelpOpen(true);
        if (!this.props.kioskOptions.printReceiptsAndTickets) {
            this.props.sendSlackMessage("@here Needs unspecified help", "error");
        }
    };
}

const mapStateToProps = (state, props) => {
    return {
        kioskOptions: getKioskOptions(state),
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setHelpOpen,
        setCancelConfirm,
        setLanguageModal,
        sendSlackMessage,
    }, dispatch);
};

HelpButtonContainer.propTypes = {
};

HelpButtonContainer.defaultProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpButtonContainer);
