import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILivestreamState {}
const initialState: ILivestreamState = {'streamData': {}};

export const livestreamSlice = createSlice({
    name: 'livestream',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase('RESERVATION_DATA_SET', (state, action) => {
                let newState = {...state};
                // @ts-ignore
                if (!action.reservation.attributes.livestream) {
                    return newState;
                }
                return {
                    ...newState,
                    // @ts-ignore
                    streamData: {...action.reservation.attributes.livestream},
                };
            })
            .addCase("RESERVATION_CLEARED", (state, action) => {
                return initialState;
            })
            .addDefaultCase((state, action) => {
                return state;
            });
    }
});
