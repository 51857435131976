import { createSelector } from 'reselect';
import { getMainEvent, getSelectedEvent } from '../../../../checkout/selectors/event';

const getIsPresaleCodeApplied = state => state.coupon.isPresaleCodeApplied;
const getInvitationCodeApplied = state => !!state.couponTag.appliedTaggableInvitationCode;

export const showPresaleCouponForm = createSelector([
        getSelectedEvent,
        getIsPresaleCodeApplied,
        getInvitationCodeApplied,
    ], (
        event,
        applied,
        invitationApplied
    ) =>  {
    return !invitationApplied && event?.event?.hasPresale && !event?.event?.isOnsale && applied === false;
});
