import request from 'superagent';
import BluebirdPromise from 'bluebird';
import { getEmbedToken } from '../helpers/embedToken';

function CheckoutClient(apiUrl) {
    this.baseUrl = apiUrl;
}

// class method
CheckoutClient.execute = executeRequest;
CheckoutClient.formatJsonApi = formatJsonApi;

// instance method
CheckoutClient.prototype.getResource = getResource;
CheckoutClient.prototype.createResource = createResource;
CheckoutClient.prototype.executeRequest = executeRequest;
CheckoutClient.prototype.deleteResource = deleteResource;
CheckoutClient.prototype.reservationGetRequest = reservationGetRequest
CheckoutClient.prototype.reservationCreateRequest = reservationCreateRequest
CheckoutClient.prototype.reservationUpdateRequest = reservationUpdateRequest
CheckoutClient.prototype.getTranslations = getTranslations
CheckoutClient.prototype.getTranslationsBySlug = getTranslationsBySlug
CheckoutClient.prototype.getTranslationsByNamespace = getTranslationsByNamespace
CheckoutClient.prototype.saveOptInQuestionAnser = saveOptInQuestionAnser;

function getResource(type, id, relationship, reservation_secret) {

    let url = this.baseUrl + '/' + type + '/' + id
    if (relationship) {
        url += ('/' + relationship)
    }

    let req = request.get(url)
    req.withCredentials()
    if (reservation_secret) {
        req.set('reservation-secret', reservation_secret)
    }
    return executeRequest(req, 200)
}

function getTranslations(locale) {
    let req = request.get(this.baseUrl + '/translations/' + locale + '/checkout')
    return executeRequest(req, 200)
}

function getTranslationsBySlug(locale, slugs) {
    let req = request.post(this.baseUrl + '/translations/' + locale).send(slugs);
    return executeRequest(req, 200)
}

function getTranslationsByNamespace(locale, namespace) {
    //5 mins cache
    let req = request.get(this.baseUrl + '/translations/v2/version/' + locale + "/" + namespace);

    return executeRequest(req, 200).then((result) => {
        let version = result.body.version;

        //1 day cache
        let req = request.get(this.baseUrl + '/translations/v2/' + locale + "/" + namespace + "/" + version);
        return executeRequest(req, 200)
    });
}

function createResource(type, payload, reservation_secret) {
    let url = this.baseUrl + '/' + type
    let req = request.post(url)
    if (payload) {
        req.send(formatJsonApi(type, payload))
    }
    if (reservation_secret) {
        req.set('reservation-secret', reservation_secret)
    }
    req.set('Content-Type', 'application/json')
    return executeRequest(req, 200)
}

function deleteResource(type, id, reservation_secret) {
    let url = this.baseUrl + '/' + type + '/' + id
    let req = request.delete(url)
    if (reservation_secret) {
        req.set('reservation-secret', reservation_secret)
    }
    req.set('Content-Type', 'application/json')
    return executeRequest(req, 200)

}

function reservationUpdateRequest(reservation_id, type, payload, reservation_secret) {
    let url = this.baseUrl + '/reservations/' + reservation_id + '/' + type
    let req = request.patch(url)
    if (payload) {
        req.send(formatJsonApi(type, payload))
    }
    if (reservation_secret) {
        req.set('reservation-secret', reservation_secret)
    }
    req.set('Content-Type', 'application/json')
    return executeRequest(req, 200)
}

function reservationGetRequest(reservation_id, type, reservation_secret) {
    let url = this.baseUrl + '/reservations/' + reservation_id + '/' + type
    let req = request.get(url)
    if (reservation_secret) {
        req.set('reservation-secret', reservation_secret)
    }
    req.set('Content-Type', 'application/json')
    return executeRequest(req, 200)
}

function reservationCreateRequest(reservation_id, type, payload, reservation_secret) {
    let url = this.baseUrl + '/reservations/' + reservation_id + '/' + type
    let req = request.post(url)
    if (payload) {
        req.send(formatJsonApi(type, payload))
    }
    if (reservation_secret) {
        req.set('reservation-secret', reservation_secret)
    }
    req.set('Content-Type', 'application/json')
    return executeRequest(req, 200)
}


function executeRequest(request, expectStatus) {
    const embedToken = getEmbedToken();
    if (embedToken != null) {
        request.set('x-embed-token', embedToken);
    }

    request.withCredentials()
    return new BluebirdPromise(function (resolve, reject) {
        request.end(function (error, response) {
            if (error) {
                reject(error);
            } else if (!expectStatus || response.status === expectStatus) {
                resolve(response);
            } else {
                let statusError = new Error('Server responded with status ' + response.status +
                    ', expected ' + expectStatus);
                statusError.status = response.status;
                statusError.expectedStatus = expectStatus;
                reject(statusError);
            }
        });
    });
}

function formatJsonApi(type, attributes) {
    let payload = {
        'data': {
            'type': type,
            'attributes': attributes
        }
    }
    return JSON.stringify(payload)
}

let client = null;

export function getCheckoutClient(apiUrl = "") {
    if(client !== null) {
        return client;
    } else {
        return new CheckoutClient(apiUrl);
    }
}

function saveOptInQuestionAnser(token, reservation_secret, opt_in_question_id, payload) {
  let url = this.baseUrl + '/reservations/' + token + '/opt_in_question/' + opt_in_question_id + '/answer';
  let req = request.post(url);
  if (reservation_secret) {
      req.set('reservation-secret', reservation_secret);
  }
  req.send(JSON.stringify(payload));

  req.set('Content-Type', 'application/json');
  return executeRequest(req, 200);
}
