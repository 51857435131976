import { getCheckoutClient } from '../config/CheckoutClient';
import { fetchReservation, deleteReservation } from './reservation';
import { setProductQuantities } from '../views/product-picker/actions/product-picker';
import { setLoading } from 'checkout/actions/loading';
import { addServerErrors } from './ui';
import { isReservationEmpty } from 'checkout/selectors/reservation';
import { setInventoryItemsFromServer } from '../helpers/inventoryItems';

export const setProductSales = (products, fireHooks = true) => {
  return (dispatch, getState) => {
    dispatch(
      setInventoryItemsFromServer(
        products,
        getState().productSales?.products ?? [],
        (products) => {
          return { type: 'SET_PRODUCT_SALES', products };
        },
        (products) => {
          return { type: 'PRODUCTS_ADDED_TO_CART', products };
        },
        (products) => {
          return { type: 'PRODUCTS_REMOVED_FROM_CART', products };
        },
        fireHooks,
      ),
    );
  };
};

export const setRawProductSalesState = (state) => ({
  type: 'SET_RAW_PRODUCT_SALES_STATE',
  state,
});

export function fetchProducts(token, secret, fireHooks = true) {
  return (dispatch, getState) => {
    let state = getState();
    getCheckoutClient(state.ui.apiUrl)
      .reservationGetRequest(token, 'products', secret)
      .then((result) => {
        let filteredProducts = result.body.data.filter((product) => product.attributes.hasBundleSaleId === false);
        dispatch(setProductSales(filteredProducts, fireHooks));
        let soldProducts = getSoldOrHeldProducts(filteredProducts);
        // updated quantities
        let productsQuantities = buildProductsQuantities(state.products.products, soldProducts);
        dispatch(setProductQuantities(productsQuantities));
      });
  };
}

export function updateProductQuantities(quantities, token, secret, reFetch = true, listingUpsellId = null) {
  return (dispatch, getState) => {
    let state = getState();
    let configId = quantities.products[0].product_configuration_id;
    const loadingKey = listingUpsellId ? `UPSELL_${listingUpsellId}_${configId}` : configId;
    dispatch(setLoading(`PRODUCT_QUANTITY_${loadingKey}_REQUEST`));

    const productEndpoint = listingUpsellId ? 'upsell/products' : 'products';

    if (listingUpsellId && typeof quantities === 'object') {
      quantities.listing_upsell_id = listingUpsellId;
    }

    getCheckoutClient(state.ui.apiUrl)
      .reservationUpdateRequest(token, productEndpoint, quantities, secret)
      .then((result) => {
        if (reFetch) {
          return Promise.all([
            fetchReservation(token, secret)(dispatch, getState),
            fetchProducts(token, secret)(dispatch, getState),
          ])
            .then(() => {
              dispatch(setLoading(`PRODUCT_QUANTITY_${loadingKey}_SUCCESS`));

              if (isReservationEmpty(getState())) {
                dispatch(deleteReservation());
              }
            })
            .catch((e) => {
              dispatch(setLoading(`PRODUCT_QUANTITY_${loadingKey}_FAILURE`));
            });
        } else {
          dispatch(setLoading(`PRODUCT_QUANTITY_${loadingKey}_SUCCESS`));
          return fetchProducts(token, secret)(dispatch, getState);
        }
      })
      .catch((error) => {
        dispatch(addServerErrors(error.response.body.errors));
        dispatch(setLoading(`PRODUCT_QUANTITY_${loadingKey}_FAILURE`));
      });
  };
}

function buildProductsQuantities(products, soldProducts) {
  let quantityArrays = {};
  Object.keys(products).forEach((productId) => {
    let product = products[productId];
    quantityArrays[productId] = buildProductQuantityArray(product);
  });
  Object.keys(quantityArrays).forEach((productId) => {
    if (soldProducts[productId]) {
      quantityArrays[productId].length = Math.abs(quantityArrays[productId].length - soldProducts[productId]);
    }
  });
  return quantityArrays;
}

function buildProductQuantityArray(product) {
  let min = 1;
  let max = product.transaction_limit;
  let incrementBy = 1;
  let quantities = [];

  for (var i = min; i <= max; i += incrementBy) {
    quantities.push(i);
  }
  return quantities;
}

function getSoldOrHeldProducts(products) {
  let counts = {};
  for (let i = 0; i < products.length; i++) {
    if (counts[products[i].attributes.product_id] === undefined) {
      counts[products[i].attributes.product_id] = 1;
    } else {
      counts[products[i].attributes.product_id] += 1;
    }
  }
  return counts;
}
