import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import {fetchReservationIfNeeded} from '@/actions/reservation';
import {setCheckoutOverlay} from '@/actions/ui';
import ContinueCheckoutBanner from '../components/ContinueCheckoutBanner';
import { triggerTransition } from '@uirouter/redux';
import ContainerProps from './ContinueCheckoutBannerContainerComponent';
import { secondsTohhmmss } from '../../helpers/time';
import { getNumItemsInCart } from 'checkout/selectors/cart';
import { hasAppliedReserveCoupon } from 'checkout/selectors/reserved-seating';
import { showContinueCheckoutBanner, getEmbedCartBehavior } from 'checkout/selectors/ui';
import { EMBED_CART_BEHAVIOR_PIN_TO_BOTTOM } from '../../checkout/reducers/ui';

class ContinueCheckoutBannerContainer extends Component<ContainerProps> {
    getTime = () => {
        return(secondsTohhmmss(this.props.timeRemaining));
    }

    componentDidMount = () => {
        try {
            if(localStorage && JSON.parse(localStorage.getItem("confirmation")) === true) {
                return;
            }
        } catch(e) {}

        this.props.fetchReservationIfNeeded().catch(() => {});
    }

    render() {
        if(!this.props.showBanner) {
            return null;
        }

        return(
            <ContinueCheckoutBanner
                showCart={this.onShowCart}
                amount={this.getTotalCost()}
                timeRemaining={this.getTime()}
                itemCount={this.props.itemCount}
                forceShow={this.props.hasAppliedReserveCoupon}
                pinToBottom={this.props.pinToBottom}
            />
        )
    }

    getTotalCost = () => {
        return this.props.totalAmount.amount + this.props.totalFees;
    };

    onShowCart = () => {
        this.props.triggerTransition("cart", {token: this.props.token})
        window.scrollTo(0,0);
    }
}

const mapStateToProps = state => {
    return {
        token: state.reservation.token,
        totalAmount: state.reservation.total_cost,
        totalFees: state.fees.totalFees,
        itemCount: getNumItemsInCart(state),
        timeRemaining: state.reservation.time_remaining,
        hasAppliedReserveCoupon: hasAppliedReserveCoupon(state),
        showBanner: showContinueCheckoutBanner(state),
        pinToBottom: getEmbedCartBehavior(state) == EMBED_CART_BEHAVIOR_PIN_TO_BOTTOM,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setCheckoutOverlay,
        fetchReservationIfNeeded,
        triggerTransition
    }, dispatch);
}

ContinueCheckoutBannerContainer.propTypes = {
    token: PropTypes.string.isRequired,
    totalAmount: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinueCheckoutBannerContainer)
