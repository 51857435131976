import Translations from '@h/translation';
import { setLocaleCookie } from "../helpers/cookie";

export const setJsLocale = value => {
  return {type: "SET_JS_LOCALE", value};
}

export const setDefaultJsLocale = value => {
  return {type: "SET_DEFAULT_JS_LOCALE", value};
}

export const setServerLocale = (locale, translations) => {
    return {type: "SET_SERVER_LOCALE", locale, translations};
}

export const setDefaultServerLocale = (locale) => {
    return {type: "SET_DEFAULT_SERVER_LOCALE", locale};
}

export const setLanguages = value => {
    return {type: "SET_LANGUAGES", value};
}

export const changedLanguage = () => {
    return {type: "CHANGED_LANGUAGE"};
}

export const backToDefaultLocale = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(changeLocale(state.intl.defaultServerLocale, state.intl.defaultLocale));
    }
}

export function changeLocale(serverLocale, locale) {
    return async (dispatch, getState) => {
        const state = getState();
        let translationsToSet = state.intl.loadedTranslationsByServerLocale[serverLocale];
        if (!translationsToSet) {
            translationsToSet = {};
            const translation = new Translations(state.ui.apiUrl);
            try {
                const response = await translation.getTranslationsByNamespace(serverLocale, 'checkout');
                if (typeof response?.locale_strings === "object" && response?.locale_strings !== null) {
                    translationsToSet = response.locale_strings;
                }
            } catch(e) {
                // do nothing
            }
        }

        setLocaleCookie(serverLocale);
        dispatch(setServerLocale(serverLocale, translationsToSet));
        dispatch(setJsLocale(locale));
    }
}
