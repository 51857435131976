import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import Message from '../../../../shared/Message.js';
import VirtualKeyboard from '../../../../checkout/components/VirtualKeyboard';
import { initKeyboard } from '../../../../checkout/actions/keyboard';
import { login, setLoginLoading, clear2FA, issue2FAChallenge } from '../actions/kiosk-management';
import { getFilteredTwoFactorConfigs } from '../selectors/kiosk-management';
import { TranslationContext } from '../../../../checkout/config/Translations';

class KioskLogin extends Component {

    state = {
        email: "",
        password: "",
        twoFactorChallengeResponse: "",
        choose2FA: false,
        alternate2FA: null,
        error: "",
    }

    initialState = this.state;

    setEmail = val => {
        this.setState({email: val});
        return () => {type: ""}; //keep virtual keyboard from complaining
    }

    setPassword = val => {
        this.setState({password: val});
        return () => {type: ""}; //keep virtual keyboard from complaining
    }

    setTwoFactorChallengeResponse = val => {
        this.setState({twoFactorChallengeResponse: val});
        return () => {type: ""}; //keep virtual keyboard from complaining
    }

    onChangeEmail = e => {
        this.setEmail(e.target.value);
    }

    onChangePassword = e => {
        this.setPassword(e.target.value);
    }

    onChangeTwoFactorChallengeResponse = e => {
        this.setTwoFactorChallengeResponse(e.target.value);
    }

    onEmailFocus = (e) => {
        this.props.initKeyboard(e.target, 'Email', this.setEmail, 'email');
    }

    onPasswordFocus = (e) => {
        this.props.initKeyboard(e.target, 'Password', this.setPassword, 'password');
    }

    onTwoFactorChallengeResponseFocus = (e) => {
        this.props.initKeyboard(e.target, 'PIN', this.setTwoFactorChallengeResponse);
    }

    clearLogin = () => {
        this.setState({...this.state, ...this.initialState});
        this.props.clear2FA();
    }

    goToChoose2FA = () => {
        this.setState({twoFactorChallengeResponse: "", choose2FA: true});
    }

    chooseAlternate2FA = e => {
        this.setState({alternate2FA: e.target.value});
    }

    onSubmitAlternate2FA = e => {
        e.preventDefault();
        this.setState({error: ""});
        this.props.issue2FAChallenge(this.state.email, this.state.password, this.state.alternate2FA).then(result => {
            if (result.error) {
                this.setState({error: result.error});
            } else {
                this.setState({
                    alternate2FA: null,
                    choose2FA: false,
                });
            }
        });
    }

    onSubmit = e => {
        e.preventDefault();
        this.doLogin();
    }

    doLogin = () => {
        this.setState({error: ""});
        this.props.login(this.state.email, this.state.password, this.state.twoFactorChallengeResponse).then(result => {
            this.props.setLoginLoading(false);
            if (result && result.error) {
                this.setState({error: result.error});
            }
        });
    }

    render() {

        //SELECT ALTERNATE 2FA MENU
        if (this.state.choose2FA) {
            return (
                <TranslationContext.Consumer>
                {t =>
                <div className="kiosk-login login-body">
                    <div className="kiosk-login__form-container">
                        <Message inlineText title={t.t('checkout__choose_another_two_factor')} />
                        {this.state.error &&
                            <div className="kiosk-login__error">
                                <Message error inlineText title={this.state.error} />
                            </div>
                        }
                        <form className="kiosk-login__form" onSubmit={this.onSubmitAlternate2FA}>
                            {this.props.twoFactorConfigs.map(config => (
                                <div className="input__radio" key={config.id}>
                                    <label>
                                        <input
                                            type="radio"
                                            value={config.id}
                                            checked={config.id === this.state.alternate2FA}
                                            onChange={this.chooseAlternate2FA}/>
                                        {config.name} {config.default ? `(${t.t('checkout__default')})` : ""}
                                    </label>
                                </div>
                            ))}
                            <button type="submit" className="kiosk-login__button" disabled={!this.state.alternate2FA || this.props.twoFactorChallengeLoading}>
                                {t.t('checkout__choose_method')}
                            </button>
                        </form>
                    </div>
                    <div className="kiosk__background-image"></div>
                </div>
                }
                </TranslationContext.Consumer>
            );
        }

        //SUBMIT 2FA RESPONSE FORM
        if (this.props.pendingTwoFactorChallengeId && this.props.pendingTwoFactorChallengeType && this.props.pendingTwoFactorChallengeDescription) {
            return (
                <TranslationContext.Consumer>
                {t =>
                <div className="kiosk-login login-body">
                    <div className="kiosk-login__form-container">
                        <Message inlineText title={this.props.pendingTwoFactorChallengeDescription} />
                        {this.state.error &&
                            <div className="kiosk-login__error">
                                <Message error inlineText title={this.state.error} />
                            </div>
                        }
                        <form className="kiosk-login__form" onSubmit={this.onSubmit}>
                            <div className="input">
                                <label className="input__label" htmlFor="twoFactorChallengeResponse">
                                    {t.t('checkout__pin')}
                                </label>
                                <input
                                    className="input__control"
                                    type="text" name="twoFactorChallengeResponse"
                                    value={this.state.twoFactorChallengeResponse}
                                    onChange={this.onChangeTwoFactorChallengeResponse}
                                    onFocus={this.onTwoFactorChallengeResponseFocus}
                                    placeholder="0000 0000"
                                    required/>
                            </div>
                            <button type="submit" className="kiosk-login__button" disabled={this.state.twoFactorChallengeResponse === "" || this.props.loading}>
                                {t.t('checkout__authenticate')}
                            </button>
                        </form>
                        <div onClick={this.clearLogin}>
                            <Message inlineText info title={t.t('checkout__clear_two_factor')}/>
                        </div>
                        {this.props.twoFactorConfigs.length > 1 &&
                            <div onClick={this.goToChoose2FA}>
                                <Message inlineText info title={t.t('checkout__choose_another_two_factor')}/>
                            </div>
                        }
                    </div>
                    <VirtualKeyboard />
                    <div className="kiosk__background-image"></div>
                </div>
                }
                </TranslationContext.Consumer>
            )
        }

        //LOGIN FORM
        return(
            <TranslationContext.Consumer>
            {t =>
            <div className="kiosk-login login-body">
                <div className="kiosk-login__form-container">
                    {this.state.error &&
                        <div className="kiosk-login__error">
                            <Message error inlineText title={this.state.error} />
                        </div>
                    }
                    <form className="kiosk-login__form" onSubmit={this.onSubmit}>
                        <div className="input">
                            <label className="input__label" htmlFor="email">{t.t('checkout__email')}</label>
                            <input className="input__control" type="text" name="email" value={this.state.email} onChange={this.onChangeEmail} onFocus={this.onEmailFocus} required/>
                        </div>
                        <div className="input">
                            <label className="input__label" htmlFor="password">{t.t('checkout__password')}</label>
                            <input className="input__control" type="password" name="password" value={this.state.password} onChange={this.onChangePassword} onFocus={this.onPasswordFocus} required/>
                        </div>
                        <button type="submit" className="kiosk-login__button" disabled={this.props.loading}>{t.t('checkout__log_in')}</button>
                    </form>
                </div>
                <VirtualKeyboard />
                <div className="kiosk__background-image"></div>
            </div>
            }
            </TranslationContext.Consumer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.kioskManagement.loginLoading ? true : false,
        pendingTwoFactorChallengeId: state.kioskManagement.pendingTwoFactorChallengeId,
        pendingTwoFactorChallengeType: state.kioskManagement.pendingTwoFactorChallengeType,
        pendingTwoFactorChallengeDescription: state.kioskManagement.pendingTwoFactorChallengeDescription,
        twoFactorConfigs: getFilteredTwoFactorConfigs(state),
        twoFactorChallengeLoading: state.kioskManagement.twoFactorChallengeLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        initKeyboard,
        login,
        setLoginLoading,
        clear2FA,
        issue2FAChallenge
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KioskLogin)
