
export function getTimestampFromUrl(param) {
  const timestamp = (new URLSearchParams(window.location.search)).get(param);

  return !isNaN(timestamp) ? +timestamp * 1000 : null;
}

export function removeParamFromUrl(param) {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState(null, null, url.href);
}

export function getTimestampAndRemoveFromUrl(param) {
  const timestamp = getTimestampFromUrl(param);

  removeParamFromUrl(param);

  return timestamp
}
