const devMessage = type => {
	return `This ${type} has been sanitized in order to prevent redux devtools from crashing. To view the unsanitized action in dev tools, edit the redux devtools configuration`;
}

//stringify handling circ references
const safeStringify = obj => {
	let cache = [];
	return JSON.stringify(obj, function(key, value) {
	    if (typeof value === 'object' && value !== null) {
	        if (cache.indexOf(value) !== -1) {
	            // Duplicate reference found, discard key
	            return;
	        }
	        // Store value in our collection
	        cache.push(value);
	    }
	    return value;
	});
	cache = null;
}

const sanitizeTransition = transition => {
	let targetState = null;
	if (typeof transition.targetState === 'function') {
		let targetStateResult = transition.targetState();
		if (targetStateResult && typeof targetStateResult.state === 'function') {
			targetState = targetStateResult.state();
		}
	}
	return {
		_devMessage: devMessage('action'),
    	current: transition.router && transition.router.globals && transition.router.globals.current,
    	targetState: targetState,
	    from: typeof transition.from === 'function' ? transition.from() : null,
	    to: typeof transition.to === 'function' ? transition.to() : null,
	};
}

const logActionSize = (action, limit = 5000) => {
	let length = safeStringify(action).length;
	if (length > limit) {
		console.log(action, length);
	}
}

const logStateSize = state => {
	let res = {};
	Object.keys(state).map(key => {
		res[key] = safeStringify(state[key]).length;
	});
	console.log(res);
}

export default {
    actionsDenylist: [
        'TICK',
        'SET_CURRENT_TIME',
    ],
    //attempt to keep large actions out of here to prevent devtools from crashing due to running out of mem / cpu when serializing
    actionSanitizer: action => {
    	//uncomment to log action sizes out if you are debugging devtools crashing due to large actions
    	// logActionSize(action);

    	if (action.type.indexOf('@ui-router') !== -1 && action.transition) {
    		let transition = action.transition;
    		let sanitized = sanitizeTransition(action.transition);
    		return {
    			type: action.type,
    			transition: sanitized,
    		}
    	}

    	if (action.type === 'SET_KEYBOARD_INPUT') {
    		return {
    			type: action.type,
    			value: devMessage('action'),
    		}
    	}
    	return action;
    },
    //attempt to keep large data out of here to prevent devtools from crashing due to running out of mem / cpu when serializing
    stateSanitizer: state => {
    	let newState = {...state};
    	if (newState.router) {
    		newState.router = {
    			_devMessage: devMessage('state'),
    		}
    	}

    	if (newState.keyboard && newState.keyboard.input) {
    		newState.keyboard.input = devMessage('state');
    	}

    	//uncomment to log state size if you are debugging devtools crashing due to large state
    	// logStateSize(newState);

    	return newState;
    }
};
