import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface IConfirmation {
    events: Array<Object>,
    url: string,
    deliveryMethodName: string,
    confirmationNumber: string,
    roktPayload: object,
}

interface IConfirmationState {
    events: Array<Object>;
    receiptUrl: string;
    deliveryMethodName: string;
    confirmationNumber: string;
    roktPayload: object;
}

const initialState: IConfirmationState = {
    events: [],
    receiptUrl: "",
    deliveryMethodName: "",
    confirmationNumber: "",
    roktPayload: {}
};

export const confirmationSlice = createSlice({
    name: 'confirmation',
    initialState,
    reducers: {
        setConfirmationEvents: (state, {payload}: PayloadAction<IConfirmation>) => {
            let newState = {...state};
            newState.events = payload.events;
            return newState;
        },
        setReceiptUrl: (state, {payload}: PayloadAction<IConfirmation>) => {
            let newState = {...state};
            newState.receiptUrl = payload.url;
            return newState;
        },

        setDeliveryMethodName : (state, {payload}: PayloadAction<IConfirmation>) => {
            let newState = {...state};
            newState.deliveryMethodName = payload.deliveryMethodName;
            return newState;
        },

        setConfirmationNumber: (state, {payload}: PayloadAction<IConfirmation>) => {
            let newState = {...state};
            newState.confirmationNumber = payload.confirmationNumber;
            return newState;
        },
        setRoktPayload: (state, {payload}: PayloadAction<IConfirmation>) => {
            let newState = {...state};
            newState.roktPayload = payload.roktPayload;
            return newState;
        }
    },

    extraReducers: (builder) => {
        builder.addDefaultCase((state, action) => {
            return state;
        })
    }
});
