import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSeriesConfig } from "checkout/selectors/ui";
import { dateOrUnixToDate } from '../../../../helpers/time';

export const CalendarLegends = () => {
  const { showCalendarLegends, calendarLegends, calendarColors } = useSelector(getSeriesConfig);
  const primaryColor = useSelector(state => state.ui.styleVars.GLOBAL__COLOR__PRIMARY)

  const futureDatesColors = useMemo(() => {
    if (!calendarColors) {
      return new Set();
    }

    const today = new Date();
    const colors = Object
      .entries(calendarColors)
      .filter(([date]) => +dateOrUnixToDate(date) > +today)
      .map(([, color]) => color);

    // add primary color since it represents standard admission dates
    return new Set([...colors, primaryColor]);
  }, [calendarColors]);

  const computedShowShowCalendarLegends = useMemo(() => {
    if (!showCalendarLegends) return false;

    // if at least one of legends is active - return true
    return Object
      .entries(calendarLegends)
      .some(([color, { active, title }]) => active && title && futureDatesColors.has(color));
  }, [showCalendarLegends, calendarLegends, futureDatesColors]);

  // Transform into array, filter by active & title and sort by index
  const computedLegends = useMemo(() => {
    // do not make any computations if there is nothing to show
    if (!computedShowShowCalendarLegends) return [];

    return Object
      .entries(calendarLegends)
      .map(([color, data]) => ({ color, ...data }))
      .filter(({ color, active, title }) => active && title && futureDatesColors.has(color))
      .sort(({ index: indexA }, { index: indexB }) => indexA - indexB);
  }, [computedShowShowCalendarLegends, calendarLegends, futureDatesColors]);

  if (!computedShowShowCalendarLegends) return null;

  return (
    <ul className="legends__list">
      {computedLegends.map(({ color, title }) => (
        <li key={color} className="legends__list-item">
          <div className="legend__color" style={{ backgroundColor: color }} />
          <p className="legend__title">{title}</p>
        </li>
      ))}
    </ul>
  )
}
