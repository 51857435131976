import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PriceLevel from '../components/PriceLevel';
import KioskPriceLevel from '../components/KioskPriceLevel';
import {incrementPriceLevel, decrementPriceLevel, setPriceLevel, setPWYWPrice} from '../actions/ticket-picker';
import ContainerProps from './PriceLevelWrapperComponent';
import { isKioskMode, getFeeDisplayOption } from 'checkout/selectors/ui';
import { getSubState } from '../../../reducers/subReducerHelpers';
import { isUpsellSubReducerKey } from '../../upsells/reducers/upsells';
import { getPWYWPriceForPriceLevel, levelIsPWYW, hasTicketsCartedForLevel, getTicketsOfLevel } from '../selectors/tickets';
import { isMultiEmbed } from '../../../selectors/ui';
import { getMainEvent } from '../../../selectors/event';
import { getSelectedTimeslotBuyout } from "../../../selectors/tickets";

class PriceLevelContainer extends Component<ContainerProps> {
    onQuantityInput = (e) => {
        this.props.setPriceLevel(this.props.levelId, +e.target.value, this.props.reducerKey);
    };

    onIncrement = () => {
        this.props.incrementPriceLevel(this.props.levelId, this.props.reducerKey);
    };

    onDecrement = () => {
        this.props.decrementPriceLevel(this.props.levelId, this.props.reducerKey);
    };

    onSetPWYWPrice = price => {
        this.props.setPWYWPrice(this.props.levelId, price, this.props.reducerKey);
    }

    render() {
        if (this.props.isKioskMode){
            return(
                <KioskPriceLevel
                    level={this.props.level}
                    onIncrement={this.onIncrement}
                    onDecrement={this.onDecrement}
                    quantity={this.props.quantity}
                    quantities={this.props.quantities}
                    initialQuantities={this.props.initialQuantities}
                    onQuantityInput={this.onQuantityInput}
                    isUpsell={isUpsellSubReducerKey(this.props.reducerKey)}
                    disabled={!!this.props.disabled}
                />
            )
        }
        return(
            <PriceLevel
                level={this.props.level}
                onIncrement={this.onIncrement}
                onDecrement={this.onDecrement}
                quantity={this.props.quantity}
                quantities={this.props.quantities}
                initialQuantities={this.props.initialQuantities}
                onQuantityInput={this.onQuantityInput}
                feeDisplayOption={this.props.feeDisplayOption}
                isPWYW={this.props.isPWYW}
                pwywPrice={this.props.pwywPrice}
                onSetPWYWPrice={this.onSetPWYWPrice}
                canEnterPWYWPrice={this.props.canEnterPWYWPrice}
                disabled={!!this.props.disabled}
                showPriceLevelInInfo={this.props.isMultiEmbed}
                selectedTimeslotBuyout={this.props.selectedTimeslotBuyout}
                feeVerbiage={this.props.feeVerbiage}
            />
        )
    }
}

const mapStateToProps = (state, props) => {

    const priceLevelState = getSubState(state.priceLevel, props.reducerKey);
    const ticketPickerState = getSubState(state.ticketPicker, props.reducerKey);

    const priceLevel = priceLevelState.levels[props.levelId];
    const isPWYW = levelIsPWYW(priceLevelState, props.levelId) && !props.reducerKey; // not reducerKey because we currently don't support pwyw for upsell
    const hasTicketsOfLevel = hasTicketsCartedForLevel(state.tickets, props.levelId);

    // get the service fee for pwyw if we already have tickets carted
    if (isPWYW && hasTicketsCartedForLevel) {
        const ticketsOfLevel = getTicketsOfLevel(state.tickets, props.levelId);
        if (!!ticketsOfLevel.length) {
            priceLevel.service_fee.price = ticketsOfLevel[0].buyer_fee.amount;
        }
    }

    return {
        level: priceLevel,
        quantity: ticketPickerState.quantities[props.levelId],
        quantities: Object.keys(ticketPickerState.availableQuantities).indexOf(props.levelId) !== -1 ? ticketPickerState.availableQuantities[props.levelId] : ticketPickerState.quantityArrays[props.levelId],
        initialQuantities: ticketPickerState.quantityArrays[props.levelId],
        selectedTimeslotBuyout: getSelectedTimeslotBuyout(state),
        isKioskMode: isKioskMode(state),
        feeDisplayOption: getFeeDisplayOption(state),
        isPWYW,
        pwywPrice: getPWYWPriceForPriceLevel(priceLevelState, ticketPickerState, state.tickets, props.levelId),
        canEnterPWYWPrice: !hasTicketsOfLevel,
        isMultiEmbed: isMultiEmbed(state),
        feeVerbiage: getMainEvent(state)?.event?.fee_verbiage,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({incrementPriceLevel, decrementPriceLevel, setPriceLevel, setPWYWPrice}, dispatch);
}

PriceLevelContainer.propTypes = {
    levelId: PropTypes.any.isRequired,
    quantity: PropTypes.number,
    quantities: PropTypes.array.isRequired,
    initialQuantities: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceLevelContainer)
