import moment from 'moment-timezone';
import { isEmail } from '../../../../helpers/isEmail';
import { TYPE_URL, TYPE_EMAIL, TYPE_NUMBER, TYPE_RANGE, TYPE_DATE } from '../reducers/questions';

// Check for leading zero
// String "01304" converts to "1304" which is not a valid number
// So it has to conver to Number and then back to String and compare
// to original answer
const isValidNumberString = input => {
  return String(Number(input)) !== input;
};

/**
 * Each validation function should return an object which consists of
 * message(required) - translation name,
 * interpolation(optional) - values that should be inserted into translation if needed
 */

const urlRE = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

const validateUrlAnswer = (answer, validationData) => {
  if (!validationData?.use_url_regex) return;

  if (urlRE.test(answer)) return;

  return {
    message: 'checkout__invalid_url_pattern'
  };
};

const validateEmailAnswer = (answer, validationData) => {
  if (!validationData?.use_email_regex) return;

  if (isEmail(answer)) return;

  return {
    message: 'checkout__invalid_email_pattern'
  };
};

const validateNumberAnswer = (answer, validationData) => {
  if (!Object.keys(validationData).length) return;

  const { min_digits, max_digits } = validationData;

  if (answer.length < min_digits) {
    return {
      message: 'checkout__too_less_digits',
      interpolation: {
        min_digits
      }
    };
  }

  if (answer.length > max_digits) {
    return {
      message: 'checkout__too_many_digits',
      interpolation: {
        max_digits
      }
    };
  }
};

const validateRangeAnswer = (answer, validationData) => {
  if (!Object.keys(validationData).length) return;

  const { min, max } = validationData;

  if (isValidNumberString(answer)) {
    return {
      message: 'checkout__invalid_number'
    };
  }

  const numberValue = Number(answer);

  if (numberValue < min) {
    return {
      message: 'checkout__too_small_number',
      interpolation: {
        min
      }
    };
  }

  if (numberValue > max) {
    return {
      message: 'checkout__too_big_number',
      interpolation: {
        max
      }
    };
  }
};

const validateDateAnswer = (answer, validationData, locale) => {
  if (!Object.keys(validationData).length) return;

  const { min, max } = validationData;

  const date = new Date(answer);

  if (+date < +new Date(min)) {
    return {
      message: 'checkout__date_too_early',
      interpolation: {
        min: new Date(min).toLocaleDateString(locale)
      }
    };
  }

  if (+date > +new Date(max)) {
    return {
      message: 'checkout__date_too_late',
      interpolation: {
        max: new Date(max).toLocaleDateString(locale)
      }
    };
  }
};

export const questionValidators = {
  [TYPE_URL]: validateUrlAnswer,
  [TYPE_EMAIL]: validateEmailAnswer,
  [TYPE_NUMBER]: validateNumberAnswer,
  [TYPE_RANGE]: validateRangeAnswer,
  [TYPE_DATE]: validateDateAnswer
};
