import { deleteReservation } from '../../../actions/reservation';
import { backToDefaultLocale } from '../../../actions/intl';
import { triggerTransition } from '@uirouter/redux';

const HARD_RESET_TIME = 15000; // ms
const TIMEOUT_DURATION = 60000; //ms

export function setShowTimeoutOverlay(value) {
    return { type: "SET_SHOW_TIMEOUT_OVERLAY", value };
}

export function setTimeoutTracker() {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_TIMEOUT_TRACKER", value: setTimeout(() => {
                dispatch(setShowTimeoutOverlay(true));
                dispatch(setHardResetInterval());
            }, TIMEOUT_DURATION)
        });
    }
}

export function clearTimeoutTracker() {
    return { type: "CLEAR_TIMEOUT_TRACKER" };
}

export function setHardResetInterval() {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_HARD_RESET_INTERVAL", value: setTimeout(() => {
                dispatch(clearHardResetInterval());
                dispatch(onReset());
            }, HARD_RESET_TIME)
        });
    }
}

export function clearHardResetInterval() {
    return { type: "CLEAR_HARD_RESET_INTERVAL" };
}

export function resetTimeout() {
    return (dispatch, getState) => {
        dispatch(clearTimeoutTracker());
        dispatch(clearHardResetInterval());
        if (getState().ui.overlay) {
            dispatch(setTimeoutTracker());
        }
    }
}

export function onReset() {
    return (dispatch, getState) => {
        dispatch(clearHardResetInterval());
        dispatch(setShowTimeoutOverlay(false));
        dispatch(deleteReservation());
        dispatch(triggerTransition('ticketPicker'));
        dispatch(backToDefaultLocale());
    }
}

export function onMoreTime() {
    return (dispatch, getState) => {
        dispatch(setShowTimeoutOverlay(false));
        dispatch(resetTimeout());
    }
}
