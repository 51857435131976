/* @flow */

export function weather(state, action) {
  let newState = { ...state };

  if (action.type === 'FETCH_EVENT' || action.type === 'FETCH_LISTING_INFO') {
    if (action.data.weather_insurance && !Array.isArray(action.data.weather_insurance)) {
      const weatherInsurance = action.data.weather_insurance;
      newState.partner_id = weatherInsurance.partner_id;
      newState.product_id = weatherInsurance.product_id;
      newState.sandbox = weatherInsurance.sandbox;
      newState.sensible_external_id = weatherInsurance.sensible_external_id;
    }
  }

  if (action.type === 'SET_QUOTE') {
    newState.quote = action.quote;
  }
  return newState;
}
