import { createSelector } from 'reselect';
import { TYPE_ADDRESS, TYPE_MULTIPLE_CHOICE, TYPE_PHONE } from '../views/questions/reducers/questions';

const getQuestions = state => state.questions;

// @todo update to new structure
export const hasQuestions = createSelector(
    [getQuestions],
    (state) => {
        if (Object.keys(state.events).length === 0 && state.orders.length === 0) {
            return false;
        }

        return true;
    }
)

export const areAllQuestionsCompleted = createSelector(
    [getQuestions],
    (state) => {
        const questions = getAllQuestions(state);

        return questions.every((question) => {
            const { answer: answerProp, question_type, error } = question;

            if (error) return false;

            const required = Boolean(Number(question.required));

            if (question_type == TYPE_MULTIPLE_CHOICE) {
                const selectedOptions = answerInSelectMultipleFormat(answerProp) ? [...answerProp.selectedOptions] : [];
                const writeIn = answerProp.writeIn;

                return !required || selectedOptions.length || writeIn;
            }

            let answer = (typeof answerProp === 'string') ? answerProp?.trim() : answerProp;

            if (question_type == TYPE_PHONE) {
                answer = answer?.slice(answer?.indexOf(':'))
            }

            if (question_type == TYPE_ADDRESS) {
                const address = question.address;

                if (!address) {
                    return !required;
                }

                // Do not include country since it's selected by default
                const requiredFields = ['address', 'city', 'zip', 'name'];

                if (address.country === 'US') {
                    requiredFields.push('state');
                }

                const compareFunction = (field) => Boolean(address[field].trim());

                const isPartiallyFilled = requiredFields.some(compareFunction);

                const isFilled = requiredFields.every(compareFunction);

                if (!required && !isPartiallyFilled) {
                    return true;
                }

                return isPartiallyFilled ? isFilled : true;
            }

            return !required || answer;
        })
    }
)

export const getAnswers = createSelector(
    [getQuestions],
    (state) => {
        let answers = [];
        let questions = getAllQuestions(state);

        for(let i = 0; i < questions.length; i++) {
            let answer = questions[i].answer;
            if (questions[i]?.question_type == TYPE_MULTIPLE_CHOICE) {
                const selectedOptions = answerInSelectMultipleFormat(answer) ? [...answer.selectedOptions] : [];
                if (!!answer?.writeIn) {
                    selectedOptions.push(answer.writeIn);
                }
                answer = selectedOptions;
            }

            if (questions[i]?.question_type == TYPE_ADDRESS) {
                answer = questions[i]?.address;
            }

            answers.push({
                answer,
                question_id: questions[i].resource_id,
                resource_id: questions[i].answer_resource_id,
                resource_type: getResourceType(questions[i].answer_resource_type),
                question_type: questions[i].question_type
            })
        }

        return answers;
    }
)

export const isQuestionComplete = createSelector(
    [getQuestions],
    (state) => {
        let questions = getAllQuestions(state);
        for(let i = 0; i < questions.length; i++) {
            if(questions[i].required === "1" && questions[i].answer === "") {
                return false;
            }
        }

        return true;
    }
)

export function getAllQuestions(questionState) {
    let questions = [];

    questions = questions.concat(questionState.orders);

    for (const id in questionState.events) {
        questions = questions.concat(questionState.events[id].questions);
        for (const ticketId in questionState.events[id].ticketQuestions) {
            questions = questions.concat(questionState.events[id].ticketQuestions[ticketId]);
        }
    }

    return questions;
}

function getResourceType(type) {
    if(type === "Sale") {
        return "reservation";
    }

    return type.toLowerCase();
}

export const defaultSelectMultipleAnswer = () => {
    return {
        selectedOptions: [],
        writeIn: '',
    };
}

export const answerInSelectMultipleFormat = answer => {
    return Array.isArray(answer?.selectedOptions);
}
