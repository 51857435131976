/*
 ********************************************************************************************************
 * 
 * These helper functions are to support a reducer pattern in which we can store an object 
 * containing sub-reducers on a reducer such that subreducers share the format of the parent reducer
 * and actions containing the key "subReducerKey" will apply to the sub reducer rather than the parent.
 * 
 ********************************************************************************************************
 */

export const SUB_REDUCER_STATE_KEY = 'SUB_REDUCERS';

/**
 * This is meant to be used at the top of a reducer function in conjunction with the returnReducerState function below
 * Returns a copy of the whole state if subReducerKey property is empty on the provided action
 * If subReducerKey property is provided in action, returns the state corresponding to that key or a copy of the default state if not exists
 * 
 * @param  object state        reducer's whole state
 * @param  object defaultState default reducer state
 * @param  object action       action object
 * @return object
 */
export const getReducerState = (state, defaultState, action) => {
    let newState = {...state};
    if (!!action.subReducerKey) {
        if (newState[SUB_REDUCER_STATE_KEY] === undefined) {
            newState[SUB_REDUCER_STATE_KEY] = {};
        }
        newState = newState[SUB_REDUCER_STATE_KEY][action.subReducerKey] === undefined ? {...defaultState} : newState[SUB_REDUCER_STATE_KEY][action.subReducerKey];
    }
    return newState;
}

/**
 * This is meant to be used at the bottom of a reducer function in conjunction with the getReducerState function above
 * If subReducerKey not in provided action, returns newState
 * If subReducerKey in provided action, returns parent state applying the newState to the subReducers object under the corresponding key
 * 
 * @param  object state    the reducer's state
 * @param  object newState the new state object potentially modified by a reducer case
 * @param  object action   action object
 * @return object
 */
export const returnReducerState = (state, newState, action) => {
    if (!!action.subReducerKey) {
        const newParentState = {...state};
        if (newParentState[SUB_REDUCER_STATE_KEY] === undefined) {
            newParentState[SUB_REDUCER_STATE_KEY] = {};
        }
        newParentState[SUB_REDUCER_STATE_KEY][action.subReducerKey] = newState;
        return newParentState;
    }
    return newState;
}

/**
 * Helper for applying a callback to the parent reducer and all sub reducers
 * Should return the modified state object
 * 
 * @param  object     state    generally a copy of state in a reducer function
 * @param  function   callback callback to apply
 * @return object
 */
export const applyToParentAndAllSubReducers = (state, callback) => {
    let newState = {...state};
    if (typeof callback === 'function') {
        newState = callback(newState);
        if (typeof newState[SUB_REDUCER_STATE_KEY] === 'object' && newState[SUB_REDUCER_STATE_KEY] !== null) {
            Object.keys(newState[SUB_REDUCER_STATE_KEY]).forEach(key => {
                newState[SUB_REDUCER_STATE_KEY][key] = callback({...newState[SUB_REDUCER_STATE_KEY][key]});
            });
        }
    }
    return newState;
}

/**
 * Helper to clean up optionally getting a sub reducer from state
 * if no key provided, returns the provided state object
 * if key provided and key exists in state's subReducers object, returns state.subReducers[key]
 * if key provided and key does not exist in state's subReducers object, returns empty object
 *
 * Example: getState(state.ticketPicker, 'my_sub_reducer_key') === state.ticketPicker.subReducers.my_sub_reducer_key
 * 
 * @param  object state state
 * @param  string key   [description]
 * @return object
 */
export const getSubState = (state, key = null) => {
    if (!key) return state;
    return state?.[SUB_REDUCER_STATE_KEY]?.[key] ?? {};
}
