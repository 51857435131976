import { getReducerState, returnReducerState, applyToParentAndAllSubReducers } from './subReducerHelpers';

export const DISCOUNT_TYPE_FLAT = 1;
export const DISCOUNT_TYPE_PERCENT = 2;

const defaultState = {
    bestAvailableQuantities: {},
    sectionLevelQuantities: {},
    pyosLoading: false,
    pyosLoadingReason: ''
};
let initialState = defaultState;

export function reservedSeating(state = initialState, action) {

    let newState = getReducerState(state, defaultState, action);

    if (action.type === "RESERVED_SEATING_SET_BEST_AVAILABLE_QUANTITIES") {
        newState.bestAvailableQuantities = typeof action.val === 'object' && action.val !== null ? {...action.val} : newState.bestAvailableQuantities;
    } else if (action.type === "RESERVED_SEATING_SET_SECTION_LEVEL_QUANTITIES") {
        newState.sectionLevelQuantities = typeof action.val === 'object' && action.val !== null ? {...action.val} : newState.sectionLevelQuantities;
    } else if(action.type === "RESERVATION_CLEARED") {
        newState = applyToParentAndAllSubReducers(newState, s => {
            s.quantities = {};
            return s;
        });
    } else if(action.type === "RESERVED_SEATING_SET_PYOS_LOADING") {
        newState.pyosLoading = !!action.val;
        newState.pyosLoadingReason = action.reason;
    } else {
        return state;
    }

    return returnReducerState(state, newState, action);
}
