import { triggerTransition } from '@uirouter/redux';

import { submitMemberInfo, setCreatedNewAccount } from '../views/member/actions/member';
import { allStepsComplete, getDefaultCheckoutStepSettings, getKioskOptions, isKioskMode } from '../selectors/ui';
import { isReservationFree, reservationQualifiesForInsurance } from '../selectors/reservation';
import { setPaymentError, zeroDollarPayment } from './payment';
import { reservationCompleted, fetchReservation, sendBulkRequest } from './reservation';
import { scrollToTop } from 'checkout/helpers/helpers';
import { setLoading } from 'checkout/actions/loading';
import { processInsuranceResponse } from 'checkout/views/insurance/actions/insurance';
import { getAnswers } from 'checkout/selectors/questions';
import { getMemberPayload, getDeliveryPayload, getInsurancePayload, getDonationsPayload, orderRequiresNames, nameRequirementMet, getNamesPayload } from 'checkout/selectors/request';
import { getMobileDeliveryOptions, getWillCallDeliveryOptions, getEmailDeliveryOptions} from '../selectors/delivery';
import { fetchEvent } from 'checkout/actions/event';
import { PHONE_STRING_SEPARATOR } from '../views/questions/components/PhoneQuestion';
import { areAllQuestionsCompleted } from '../selectors/questions';

export const totalCostChange = (oldTotal, newTotal) =>
    ({ type: "TOTAL_COST_CHANGE", oldTotal, newTotal });

export function setCheckoutOverlay(overlay) {
    return {type: "CHECKOUT_OVERLAY", overlay};
}

export function removeAlert(index) {
    return {type: "REMOVE_ALERT", index};
}

export function addAlert(message, alertType = "error", dismissable = true, interpolation = {}) {
    scrollToTop();
    return {type: "ADD_ALERT", message, alertType, dismissable, interpolation};
}

export function clearAlerts() {
    return {type: "CLEAR_ALERTS"};
}

export function goToNextStep() {
    return {type: "NEXT_STEP"};
}

export function goToPrevStep() {
    return {type: "PREV_STEP"};
}

export function goToStep(index) {
    return {type: "GO_TO_STEP", index};
}

export function setStepComplete(index, value = true) {
    return {type: "SET_STEP_COMPLETE", index, value};
}

export function setApiUrl(url) {
    return {type: "SET_API_URL", url};
}

export function setListingSlug(slug) {
    return {type: "SET_LISTING_SLUG", slug};
}

export function setListingId(id) {
  return {type: "SET_LISTING_ID", id};
}

export function stepSeen(index) {
    return {type: "CHECKOUT_STEP_SEEN", index};
}

export function ticketPickerOpened() {
    return {type: "TICKET_PICKER_OPENED"};
}

export function setCancelConfirm(value) {
    return {type: "SET_CANCEL_CONFIRM" , value};
}

export function setLanguageModal(value) {
    return {type: "SET_LANGUAGE_MODAL", value};
}

export function setShowBestAvailablePicker(val) {
    return {type: "UI_SET_SHOW_BEST_AVAILABLE_PICKER" , val};
}

export function setShowPyosPicker(val) {
    return {type: "UI_SET_SHOW_PYOS_PICKER" , val};
}

export function setUseFocusTrap(val) {
    return {type: "UI_SET_USE_FOCUS_TRAP", val};
}

export function setIsEmbed(val) {
    return {type: "UI_SET_IS_EMBED", val};
}

export function setEmbedType(val) {
    return {type: "UI_SET_EMBED_TYPE", val};
}

export function setHideBestAvailable(val) {
    return {type: "UI_SET_HIDE_BEST_AVAILABLE", val};
}

export function setDisableBestAvailable(val) {
    return {type: "UI_SET_DISABLE_BEST_AVAILABLE", val};
}

export function setBestAvailableButtonText (val) {
  return {type: "UI_SET_BEST_AVAILABLE_BUTTON_TEXT", val};
}

// this is specifically to process errors responded back from the server because they're in a weird format
export function addServerErrors(errors) {
    let messages = [];
    for(let i = 0; i < errors.length; i++) {
        messages.push(errors[i].detail);
    }

    return addAlert(messages, "error", true);
}

const formatAnswers = (answers) =>
    answers.map((answerObj) => {
        const { answer, ...rest } = answerObj;

        // Check if that's a phone number
        if (typeof answer === 'string' && answer.startsWith('+')) {
            const [code, phone] = answer.split(PHONE_STRING_SEPARATOR);

            const newAnswer = code && phone ? code + phone : '';

            return {
                ...rest,
                answer: newAnswer
            }
        }

        return answerObj;
    })

export function submitStep(step, index) {
    return (dispatch, getState) => {
        dispatch(clearAlerts());
        const state = getState();
        let requests = [];
        for(let i = 0; i < step.components.length; i++) {
            const name = step.components[i].name;

            if(name === "memberInfo" || name === "address") {
                let memberPayload = getMemberPayload(state);
                if(memberPayload) {
                    requests.push({type: 'customer', payload: memberPayload});
                }
                if(name === "address"){
                  //comp order, delivery_options lives in address.
                  let deliveryPayload = getDeliveryPayload(state);
                  if(deliveryPayload && deliveryPayload.address) {
                      requests.push({type: 'delivery_options', payload: deliveryPayload});
                  }
                }
            } else if(name === "nameOnTicket") {
                if (orderRequiresNames(state)) {
                    if (!nameRequirementMet(state)) {
                        dispatch(addAlert('checkout__must_input_name_for_each_ticket'));
                        return false;
                    }
                    requests.push({type: 'nameOnTicket', payload: getNamesPayload(state)});
                }
            } else if(name === "questions") {
                if (!areAllQuestionsCompleted(state)) {
                    dispatch(addAlert('checkout__missing_required_answer'));
                    return;
                }

                let questionPayload = formatAnswers(getAnswers(state));

                if(questionPayload.length > 0) {
                    requests.push({type: 'answers', payload: {answers: questionPayload}});
                }
            } else if(name === "delivery") {
                let deliveryPayload = getDeliveryPayload(state);
                if(deliveryPayload) {
                    requests.push({type: 'delivery_options', payload: deliveryPayload});
                }
            } else if(name === "insurance") {
                if(state.insurance.provider !== "") {
                    if(
                        reservationQualifiesForInsurance(state) &&
                        ((state.insurance.selected === "" && state.insurance.declined === false) || (window.tg != null && ((typeof window.tg.isFormComplete === 'function' && !window.tg.isFormComplete()) && !window.tg_error)))) {
                        dispatch(addAlert("checkout__must_select_an_insurance_option"));
                        return Promise.reject();
                    } else {
                        let insurancePayload = getInsurancePayload(state);
                        requests.push({type: 'insurance', payload: insurancePayload})
                    }
                }
            } else if(name === "payment") {
                if (!state.payment.tosAccepted) {
                  dispatch(setPaymentError('tosAccepted', "Checkout__err_must_aggree_tos"));
                  return;
                }

                // no request, payment just submits itself
                document.getElementById('payment-form-invisible-button').click();
            } else if(name === "donation") {
                if (state.donations.hasDonations) {
                    requests.push({type: 'donation', payload: {donations: getDonationsPayload(state)}});
                }
            } else if (name === 'weather') {
              const swSelected = document.getElementById('sensible-weather-selected')?.value ?? false;
              const quoteId = document.getElementById('sensible-weather-quote')?.value ?? 0;
              const quotePrice = document.getElementById('sensible-weather-price-charged')?.value ?? 0;
              requests.push({type: 'weather', payload: {id: quoteId, status: swSelected === 'true', price: quotePrice}});
            }
        }

        if(requests.length > 0) {
            dispatch(setLoading("CHECKOUT_STEP_REQUEST"));
            return sendBulkRequest(state.ui.apiUrl, state.reservation.token, state.reservation.secret, requests).then((res) => {
                let state = getState();

                if(res.customer && res.customer.errors) {
                    throw res.customer.errors;
                }

                if(res.answers && res.answers.errors) {
                    throw res.answers.errors;
                }

                if(res.delivery_options && res.delivery_options.errors) {
                    throw res.delivery_options.errors;
                }

                if(res.insurance) {
                    processInsuranceResponse(dispatch, state, res.insurance);
                }

                if (res.donation && res.donation.errors) {
                    throw res.donation.errors;
                }

                if (res.nameOnTicket && res.nameOnTicket.errors) {
                    throw res.nameOnTicket.errors;
                }

                if (res.customer && res.customer.attributes && res.customer.attributes.hasOwnProperty('created_new_account')) {
                    const createdNewAccount = res.customer.attributes.created_new_account ? true : false;
                    dispatch(setCreatedNewAccount(createdNewAccount));
                }

                dispatch(setStepComplete(index, true));
                fetchReservation(state.reservation.token, state.reservation.secret)(dispatch, getState).then(() => {
                    state = getState();
                    if(allStepsComplete(state) && isReservationFree(state)) {
                        zeroDollarPayment()(dispatch, getState).then((result) => {
                            dispatch(goToConfirmation());
                            dispatch(setLoading("CHECKOUT_STEP_SUCCESS"));
                        }).catch(error => {
                            dispatch(setLoading("CHECKOUT_STEP_FAILURE"));
                            // error should already be displayed, the substeps should add their own alerts
                        });
                    } else {
                        dispatch(setLoading("CHECKOUT_STEP_SUCCESS"));
                        dispatch(goToNextStep());
                        dispatch(stepSeen(state.ui.currentStep + 1));
                    }
                });
            }).catch((errors) => {
                dispatch(setLoading("CHECKOUT_STEP_FAILURE"));
                dispatch(addServerErrors(errors));
                // console.log('there was an error submitting step '+ stepType);
                // console.log(error);
            });
        }

    }
}

export function goToConfirmation(pending = false, token = null) {
    return (dispatch, getState) => {
        let state = getState();
        if (pending) {
            dispatch(triggerTransition("pending", {token}));
        } else {
            dispatch(reservationCompleted());
            dispatch(triggerTransition("confirmation", {confId: state.reservation.confirmation_number}));
        }
        dispatch(triggerConfirmation());
        if (isKioskMode(state)) {
            dispatch(fetchEvent());
        }
    }
}

export function goToListing() {
    return (dispatch) => {
        dispatch(setCheckoutOverlay(false));
        dispatch(setConfirmation(false));
        dispatch(triggerTransition("ticketPicker"));
    }
}

export function triggerConfirmation() {
    return {type: "GO_TO_CONFIRMATION"};
}

export function setStepStatus(stepType, complete) {
    return {type: "SET_STEP_STATUS", stepType, complete};
}

export function setConfirmation(confirmation) {
    return {type: "SET_CONFIRMATION", confirmation};
}

export function setListingConfig(listingConfig) {
    listingConfig = processConfig(listingConfig);

    return {type: "SET_LISTING_CONFIG", listingConfig};
}



/**
 * update checkout steps to default maintaining the complete flag on the current checkout steps
 * @param  state - the current state of the redux store
 */
export function refreshCheckoutSteps(state) {
    const config = state.ui && state.ui.listingConfig ? state.ui.listingConfig : null;
    const questions = state.questions;

    if (config && config.checkoutSteps && Array.isArray(config.checkoutSteps)) {
        //get current step statuses
        let stepStatuses = {};
        config.checkoutSteps.forEach(step => {
            if (Object.keys(step).indexOf('complete') !== -1 && Object.keys(stepStatuses).indexOf(step.title) === -1) {
                stepStatuses[step.title] = step.complete;
            }
        });

        //get default checkout steps and apply step statuses
        let hasQuestions = Object.keys(questions.events).length !== 0 || questions.orders.length !== 0;

        let hasDeliveryOptions = false;

        if (isKioskMode(state)) {
            const kioskOptions = getKioskOptions(state);
            const hasMobile = kioskOptions.allowMobileDelivery && getMobileDeliveryOptions(state).length;
            const hasWillCall = kioskOptions.allowWillCallDelivery && getWillCallDeliveryOptions(state).length;
            const hasEmail = kioskOptions.allowEmailDelivery && getEmailDeliveryOptions(state).length;
            hasDeliveryOptions = hasMobile || hasWillCall || hasEmail;
        } else {
            hasDeliveryOptions = state.delivery.options.length ? true : false;
        }

        const isFree = isReservationFree(state);

        let checkoutSteps = getDefaultCheckoutStepSettings(config, hasQuestions, hasDeliveryOptions, isFree);
        if (checkoutSteps && Array.isArray(checkoutSteps)) {
            checkoutSteps.forEach((step, key) => {
                if (Object.keys(stepStatuses).indexOf(step.title) !== -1) {
                    checkoutSteps[key].complete = stepStatuses[step.title];
                }
            });
        }

        //update checkout steps and dispatch
        config.checkoutSteps = checkoutSteps;
        return {type: "SET_LISTING_CONFIG", listingConfig: config};
    }
}

export function resetUi() {
    return {type: "RESET_UI"};
}

export function setCheckoutConfig(config) {
    return {type: "SET_CHECKOUT_CONFIG", config};
}

export function setDefaultShowCalendarInModal(value) {
    return {type: "SET_DEFAULT_SHOW_CALENDAR_IN_MODAL", value};
}

export function setShowCalendarInModal(value) {
    return {type: "SET_SHOW_CALENDAR_IN_MODAL", value};
}

export function setInteractionBlock(value) {
    return {type: "SET_INTERACTION_BLOCK", value};
}

export function setCurrentMonth(value, subReducerKey = null) {
    return {type: "SET_CURRENT_MONTH", value, subReducerKey};
}

function processConfig(config) {
    config.checkoutSteps = getDefaultCheckoutStepSettings(config);
    return config;
}

export function setPaymentHandlesSubmission(value) {
    return {type: "SET_PAYMENT_HANDLES_SUBMISSION", value};
}

export function setMaintenanceMode(value) {
    return {type: "SET_MAINTENANCE_MODE", value};
}
