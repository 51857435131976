import querystring from 'querystring';
import { ISOStringToDate, isValidDateObject } from '../../helpers/time';
import { DateTimeFormatters } from '@showclix-projects/sc-intl';
import { fullDateRangeShort } from '@showclix-projects/sc-intl/dist/dateTimeFormatters';
export { getXssFilter } from '@showclix-shared-util/xssFilter';

export function toUrlParams(config) {
    let params = [];

    for (const key in config.styles) {
        params.push(key + "=" + encodeURIComponent(config.styles[key]));
    }

    if(config.images) {
        if(config.images['PAGE__background-image']) {
            params.push('PAGE__background-image='+encodeURIComponent(config.images['PAGE__background-image']));
            params.push('PAGE__background-image-dim='+encodeURIComponent(config.images['PAGE__background-image-dim']));
        }
    }

    if(config.partnerSlug) {
        params.push('GLOBAL__PARTNER__SLUG='+encodeURIComponent(config.partnerSlug));
        params.push('GLOBAL__PARTNER__URL='+encodeURIComponent(config.partnerUrl));
    }

    return params.join("&");
}

export function scrollToTop() {
    if(document.getElementById("checkout-wrap")) {
        document.getElementById("checkout-wrap").scrollTo(0,0);
    }
}

export function serializeToUrl(object) {
    return querystring.stringify(object);
}

export function getErrorText(e) {
    if(!Array.isArray(e)) {
        e = [e];
    }

    let msg = "";

    for(let i = 0; i < e.length; i++) {
        if(typeof e[i] === "string") {
            msg = e[i];
            break;
        } else if(typeof e[i] === "object" && e[i] !== null) {
            msg = e[i].detail;
        }
    }

    return msg;
}

/**
 * Helper function to strip internal attrs off of state to make it serializable
 */
export function stateToString(state) {
    let newState = {};

    for (const key in state) {
        if(!key.startsWith('_') && key !== "router") {
            try {
                JSON.stringify(state[key]);
            } catch (e) {
                continue;
            }

            newState[key] = state[key];
        }
    }

    return JSON.stringify(newState);
}

/**
 * returns a function that can be passed to focusTrapOptions.initialFocus to set the element to
 * initially focus on when using focus-trap-react.  By using this, we can pass a selector without
 * having to validate that the elements we are referencing exist or worry about an error being thrown.
 * In the event that a selector is passed that does not reference any dom elements, this will return false,
 * and nothing will be initially focused when the focus trap activates.
 * @param  string selector
 * @return function
 */
export const getInitialFocusSelector = selector => {
    return () => {
        const numberNodes = document.querySelectorAll(selector).length;
        return numberNodes ? selector : false;
    }
}

/**
 * Get localized event date string. If the event has an end, returns a range.
 * @param  string eventStart
 * @param  string eventEnd
 * @param  string locale
 * @param  bool hide_time
 * @param  bool hide_date_time
 * @return string
 */
export function getEventDate(eventStart, eventEnd, locale, hide_time = null, hide_date_time = null) {
    if (hide_date_time){
      return '';
    }

    const { fullDateShort } = DateTimeFormatters;

    if (typeof eventStart !== "string") {
        return '';
    }

    let startDate = ISOStringToDate(eventStart);
    if (!isValidDateObject(startDate)) {
        return '';
    }

    let endDate = null;
    if(eventEnd && typeof eventEnd === 'string') {
        endDate = ISOStringToDate(eventEnd);
        if (isValidDateObject(endDate)) {
            return fullDateRangeShort(locale, startDate, endDate, null, hide_time);
        }
    }
    return fullDateShort(locale, startDate, null, hide_time);
}
