/**
 * redux persist rehydrates the state to whatever it has stored.  When we add fields to reducers,
 * if the app is rehydrated from a previous state, the new fields will not exist in the reducer
 * state.  This helper function adds in any keys from default state that are missing and returns
 * the reconciled state object.
 * @param  object state        current state of reducer
 * @param  object defaultState default state of reducer
 * @return object              reconciled state
 */
const reconcilePersistedState  = (state, defaultState) => {
    const newState = {...state};
    const stateKeys = Object.keys(newState);
    Object.keys(defaultState).forEach(key => {
        if (!stateKeys.includes(key)) {
            newState[key] = defaultState[key];
        }
    });
    return newState;
}

export default reconcilePersistedState;
