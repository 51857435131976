import { getCheckoutClient } from "../../../config/CheckoutClient";
import { setPriceLevels, setProducts, setBundles, setDataForEventIfNotSet } from '../../../actions/event';
import { genSubReducerKey, UPSELL_TYPE_PRODUCT } from '../reducers/upsells';

const setUpsellContent = val => {
    return {type: 'SET_UPSELL_CONTENT', val};
}

export const setShowUpsellPopup = val => {
    return {type: 'SET_UPSELL_SHOW_POPUP', val};
}

export const setShouldFetchUpsells = val => {
    return {type: 'SET_SHOULD_FETCH_UPSELLS', val};
}

export const fetchUpsells = (token, secret) => {
    return (dispatch, getState) => {
        let state = getState();

        // get event map IDs of upsells we already have loaded.  This is to prevent storing inventory items
        // for series events multiple times because the parent inventory items eventually get overwritten
        // by child event items when a child event is selected. For series, we want to preserve whatever
        // inventory items are currently loaded into state.
        const currentUpsells = Array.isArray(state.upsells.upsells) ? state.upsells.upsells : [];
        const currentUpsellEventMapIds = currentUpsells.map(currentUpsell => +currentUpsell?.upsell?.event_map_id);

        return getCheckoutClient(state.ui.apiUrl).reservationGetRequest(token, 'upsells', secret).then(result => {
            const upsellContent = result.body.data && result.body.data.attributes && result.body.data.attributes.upsell_content
                ? result.body.data.attributes.upsell_content
                : [];
            dispatch(setUpsellContent(upsellContent));
            if (upsellContent.length) {
                upsellContent.forEach(content => {
                    let subReducerKey = null;
                    if (+content.upsell.type !== UPSELL_TYPE_PRODUCT && !currentUpsellEventMapIds.includes(+content.upsell.event_map_id)) {
                        subReducerKey = genSubReducerKey(content.upsell.event_map_id, content.upsell.type);

                        if (typeof content?.event === 'object') {
                            dispatch(setDataForEventIfNotSet(content.event.event_id, {event: content.event}, subReducerKey));
                        }

                        if (Array.isArray(content?.price_levels)) {
                            dispatch(setPriceLevels(content.price_levels, subReducerKey));
                        }

                        if (Array.isArray(content?.products)) {
                            dispatch(setProducts(content.products, subReducerKey));
                        }

                        if (Array.isArray(content?.bundles)) {
                            dispatch(setBundles(content.bundles, subReducerKey));
                        }
                    }

                    if (+content.upsell.type === UPSELL_TYPE_PRODUCT) {
                        subReducerKey = genSubReducerKey(content.upsell.item_id, content.upsell.type);
                        dispatch(setProducts([content], subReducerKey));
                    }
                });
            }
            return Promise.resolve();
        }).catch(err => {
            console.error(err);
        });
    }
}

/**
 * Check for cart match data for the provided event ID and upsell ID
 */
export const checkCartMatch = (eventId, upsellId) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token  = state.reservation.token
        const secret = state.reservation.secret

        if (!token || !secret) {
            return null;
        }

        let response = null;
        try {
            response = await getCheckoutClient(state.ui.apiUrl).reservationGetRequest(token, `upsell/tickets/cart-match/${upsellId}/${eventId}`, secret);
        } catch (err) {
            console.error(err);
        }

        return response && Array.isArray(response.body) && !!response.body.length ? response.body : null;
    }
}
