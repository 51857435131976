import React, { useContext } from 'react';

import Translations from '../helpers/translation';

let t = new Translations();
t.init('en_US');

export const TranslationContext = React.createContext(t);

export const useTranslation = () => useContext(TranslationContext);
