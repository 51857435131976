import { createSelector } from 'reselect';

const getLoadingStates = state => state.loading.states;
const getReservation = state => state.reservation;
const getFees = state => state.fees;
const getDonations = state => state.donations;

/**
 * Returns array of integer price level IDs as well as strings that correspond to 
 * upsell price levels in the format ${upsell_id}_${price_level_id}
 * Ex: [1, 2, '4_5']
 */
export const getLoadingLevels = createSelector([getLoadingStates], (states) => {
    let levels = [];

    Object.keys(states).forEach((key, i) => {
        let match = /^TICKET_QUANTITY_(\d+)/.exec(key);
        if (match && states[key] === "REQUEST") {
            levels.push(+match[1]); // cast to int
        }
    });

    Object.keys(states).forEach((key, i) => {
        let match = /^UPSELL_(\d+)_TICKET_QUANTITY_(\d+)/.exec(key);
        if (match && states[key] === "REQUEST") {
            const upsellLevelKey = `${match[1]}_${match[2]}`;
            if (!levels.includes(upsellLevelKey)) {
                levels.push(upsellLevelKey);
            }
        }
    });

    return levels;
});


export const getLoadingProductConfig = createSelector([getLoadingStates], (states) => {
    let configurations = [];

    Object.keys(states).forEach((key, i) => {

        let match = /PRODUCT_QUANTITY_(\d+)/.exec(key);
        if (match && states[key] === "REQUEST") {
            configurations.push(+match[1]); // cast to int
        }
    });

    return configurations;
});

export const getLoadingBundles = createSelector([getLoadingStates], (states) => {
    let bundles = [];

    Object.keys(states).forEach((key, i) => {
        let match = /BUNDLE_QUANTITY_(\d+)/.exec(key);
        if (match && states[key] === "REQUEST") {
            bundles.push(+match[1]); // cast to int
        }
    });

    return bundles;
});

export const getOrderTotal = createSelector([getReservation, getFees, getDonations], (reservation, fees, donations) => {
    if(!reservation.token) {
        return {amount: 0};
    }

    let amount = reservation.total_cost.amount;

    if (Object.keys(fees.insuranceFee).length > 0) {
        amount += fees.insuranceFee.amount;
    }

    if (Object.keys(fees.deliveryFee).length > 0) {
        if (reservation.total_delivery_fees && reservation.total_delivery_fees.amount > 0) {
            amount -= reservation.total_delivery_fees.amount;
        }

        amount += fees.deliveryFee.amount;
    }

    if (reservation.total_donation !== donations.totalReservationDonation) {
        amount += (donations.totalReservationDonation || 0) - (reservation.total_donation || 0);
    }


    return { ...reservation.total_cost, amount };
});

export const getNumItemsInCart = createSelector([getReservation], (reservation) => {
    return reservation.items_count;
});
