import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { sendMonitoring } from '../actions/kiosk-management';

const DEFAULT_INTERVAL = 60000;

class KioskMonitoring extends Component {
    monitorInterval = null;

    componentDidMount() {
        this.monitorInterval = setInterval(() => {
            this.sendMonitor();
        }, this.props.interval);
    }

    componentWillUnmount() {
        clearInterval(this.monitorInterval);
    }

    sendMonitor = () => {
        if(this.props.purchaseUser !== 0) {
            this.props.sendMonitoring();
        }
    }

    render() {
        return(
            <div></div>
        )
    }
}

KioskMonitoring.propTypes = {
    interval: PropTypes.number,
}

KioskMonitoring.defaultProps = {
    interval: DEFAULT_INTERVAL,
}

const mapStateToProps = (state, props) => {
    return {
        purchaseUser: +state.reservation.purchaseUser,
        sellerId: state.event.seller_id,
        listingSlug: state.ui.listingSlug,
        apiUrl: state.ui.apiUrl,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        sendMonitoring,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KioskMonitoring)
