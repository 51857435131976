import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import { resetTimeout, clearTimeoutTracker } from '../actions/timeout';
import { getBlockingErrors, isCurrentRouterState, getCurrentCheckoutStep } from 'checkout/selectors/ui';

class TimeoutPromptContainer extends Component {
    componentDidUpdate(prevProps) {
        // suspend timeout while on payment step
        if(prevProps.onPaymentStep === false && this.props.onPaymentStep === true) {
            this.props.clearTimeoutTracker();
        }

        if(this.props.onPaymentStep) {
            return;
        }

        //start count when opening ticketpicker
        if (prevProps.overlay === false && this.props.overlay === true) {
            this.props.resetTimeout();
        }

        if(this.props.timeoutTracker && prevProps.actionCounter !== this.props.actionCounter) {
            this.props.resetTimeout();
        }

        // suspend timeout while help is open
        if(prevProps.helpOpen === false && this.props.helpOpen === true) {
            this.props.clearTimeoutTracker();
        }

        // suspend timeout while management is open
        if(prevProps.managementOpen === false && this.props.managementOpen === true) {
            this.props.clearTimeoutTracker();
        }

        // suspend timeout while a blocking error is up
        if(prevProps.hasErrors === false && this.props.hasErrors === true) {
            this.props.clearTimeoutTracker();
        }

        // suspend timeout while cancel confirmation is up
        if(prevProps.cancelConfirm === false && this.props.cancelConfirm === true) {
            this.props.clearTimeoutTracker();
        }

        // restart timeout when help is closed (if management is closed)
        if(
            prevProps.helpOpen === true &&
            this.props.helpOpen === false &&
            this.props.managementOpen === false
        ) {
            this.props.resetTimeout();
        }

        // restart timeout when management is closed
        if(
            prevProps.managementOpen === true &&
            this.props.managementOpen === false
        ) {
            this.props.resetTimeout();
        }

        // restart when blocking errors are cleared
        if(
            prevProps.hasErrors === true &&
            this.props.hasErrors === false
        ) {
            this.props.resetTimeout();
        }

        // restart when blocking errors are cleared
        if(
            prevProps.cancelConfirm === true &&
            this.props.cancelConfirm === false
        ) {
            this.props.resetTimeout();
        }

        //clear when exiting overlay for any reason
        if (prevProps.overlay === true && this.props.overlay === false) {
            this.props.clearTimeoutTracker();
        }
    }

    render() {
        return null;
    }

}

const isCheckout = isCurrentRouterState("checkout");
const mapStateToProps = state => {
    let currentCheckoutStep = getCurrentCheckoutStep(state);
    return {
        actionCounter: state.timeout.actionCounter,
        showTimeoutPrompt: state.timeout.showTimeoutPrompt,
        helpOpen: state.ui.helpModal,
        managementOpen: state.ui.managementOpen,
        hasErrors: getBlockingErrors(state).length > 0,
        timeToReset: state.timeout.timeToReset,
        cancelConfirm: state.ui.cancelConfirm,
        overlay: state.ui.overlay,
        timeoutTracker: state.timeout.timeoutTracker,
        onPaymentStep: isCheckout(state) && currentCheckoutStep && currentCheckoutStep.title === "Payment",
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        resetTimeout,
        clearTimeoutTracker
    }, dispatch);
}

TimeoutPromptContainer.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeoutPromptContainer)
