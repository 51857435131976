import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { getRecentOrdersFetch } from '../actions/kiosk-management';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import RecentOrder from './RecentOrder';
import styled from 'styled-components';
import Button from 'shared/Button';
import getSidekick from 'shared/GetSidekick';
import Translation from '../../../../shared/Translation';

class RecentOrders extends Component {
    sidekick = null;

    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            sidekickLoaded: false,
            currentOrder: 0,
        }
    }

    componentDidMount() {
        if(this.props.purchaseUser) {
            this.getRecentOrders();
        }

        (async () => {
            this.sidekick = await getSidekick(this.props.adminUrl);
            this.setState({sidekickLoaded: true});
        })()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.purchaseUser !== this.props.purchaseUser) {
            this.getRecentOrders();
        }
    }

    getRecentOrders = () => {
        if(!this.props.purchaseUser) {
            return;
        }

        return this.props.getRecentOrdersFetch(this.props.purchaseUser).then((res) => {
            if(res.status === 200) {
                return res.json();
            }
        }).then((res) => {
            this.setState({orders: res.orders});
        }).catch((e) => {});
    }

    render() {
        return(
            <RecentOrdersContainer className="kiosk__management__recent-orders">
                <RecentOrdersLabel className="recent-orders__label">
                    <span><Translation>{'checkout__recent_orders'}</Translation></span>
                </RecentOrdersLabel>
                <RecentOrdersList className="recent-orders__list">
                    {this.renderOrders()}
                </RecentOrdersList>
            </RecentOrdersContainer>
        )
    }

    renderOrders = () => {
        if(this.state.orders.length === 0) {
            return (
                <RecentOrderEmptyText>
                    <Translation>{'checkout__no_recent_orders'}</Translation>
                </RecentOrderEmptyText>
            );
        }

        let order = this.state.orders[this.state.currentOrder];

        return <Fragment>
            <div className="card-group">
                <div className="card">
                    <div className="button--group">
                        <Button
                            className="button button--block"
                            onClick={this.previousOrder}
                            disabled={this.state.currentOrder === 0}
                        >
                            <Translation>{'checkout__previous'}</Translation>
                        </Button>
                        <Button
                            className="button button--block"
                            onClick={this.nextOrder}
                            disabled={this.state.currentOrder === this.state.orders.length - 1}
                        >
                            <Translation>{'checkout__next'}</Translation>
                        </Button>
                    </div>
                </div>
                <div className="card">
                    <RecentOrder
                    reserved={order.reserved}
                    id={order.id}
                    tickets={order.tickets}
                    confirmation={order.confirmation}
                    sidekick={this.sidekick}
                    apiUrl={this.props.apiUrl}
                    kioskOptions={this.props.kioskOptions}
                    />
                </div>
            </div>
        </Fragment>
    }

    previousOrder = () => {
        this.setState({currentOrder: this.state.currentOrder - 1});
    }

    nextOrder = () => {
        this.setState({currentOrder: this.state.currentOrder + 1});
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getRecentOrdersFetch,
    }, dispatch);
};

RecentOrders.propTypes = {
    purchaseUser: PropTypes.any.isRequired,
    apiUrl: PropTypes.string.isRequired,
}

RecentOrders.defaultProps = {
}

export default connect(null, mapDispatchToProps)(RecentOrders);

const RecentOrdersContainer = styled.div`
    display: flex;
    padding: 20px 30px;
    // margin-bottom: 85px;
    border-top: 1px solid #eeeeee;
`
const RecentOrdersLabel = styled.div`
    width: 150px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
`
const RecentOrdersList = styled.div`
    font-size: 15px;
    flex: auto;
`
const RecentOrderEmptyText = styled.span`
    font-size: 14px;
    line-height: 1.35714286;
    color: #6A6A6A;
`
