import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from '@s/FocusTrap';
import AlertContainer from '../../checkout/views/cart/containers/AlertContainer';
import CouponAlertsContainer from '../../checkout/views/ticket-picker/containers/CouponAlertsContainer';
import InventoryErrors from '../../checkout/views/ticket-picker/containers/InventoryErrors';
import KioskBodyContainer from '../../checkout/views/kiosk/containers/KioskBodyContainer';
import KioskHeaderContainer from '../../checkout/views/kiosk/containers/KioskHeaderContainer';
import KioskFooterContainer from '../../checkout/views/kiosk/containers/KioskFooterContainer';
import { getInitialFocusSelector } from '../../checkout/helpers/helpers';

class KioskOverlay extends Component {
    render() {
        return (
            <FocusTrap
                fallback = {'.kiosk__checkout'}
                focusTrapOptions={{
                    initialFocus: getInitialFocusSelector(".kiosk__body"),
                    allowOutsideClick: true,
                }}
            >
                <div className="kiosk__checkout">
                    {this.props.includeHeader && <KioskHeaderContainer />}
                    {this.props.couponAlerts && <CouponAlertsContainer />}
                    {this.props.inventoryErrors && <InventoryErrors />}
                    {this.props.alertContainer && <AlertContainer />}
                    <KioskBodyContainer>
                        {this.props.children ?? null}
                    </KioskBodyContainer>
                    {this.props.includeFooter && <KioskFooterContainer />}
                </div>
            </FocusTrap>
        );
    }
}

KioskOverlay.propTypes = {
    includeHeader:   PropTypes.bool,
    includeFooter:   PropTypes.bool,
    alertContainer:  PropTypes.bool,
    couponAlerts:    PropTypes.any,
    inventoryErrors: PropTypes.any,
}

KioskOverlay.defaultProps = {
    includeHeader:   true,
    includeFooter:   true,
    alertContainer:  false,
    couponAlerts:    false,
    inventoryErrors: false,
};

export default KioskOverlay;
