import { createSelector } from 'reselect';
const getTwoFactorConfigs = state => state.kioskManagement.twoFactorConfigs;

import { TYPE_U2F } from '../reducers/kioskManagement';

//return all two factor configs besides yubikey as it is currently not supported for kiosk 2FA
export const getFilteredTwoFactorConfigs = createSelector(
    [getTwoFactorConfigs],
    (twoFactorConfigs) => {
        let filtered = [];
        if (Array.isArray(twoFactorConfigs)) {
            for(let i = 0; i < twoFactorConfigs.length; i++) {
                if (twoFactorConfigs[i].type != TYPE_U2F) {
                    filtered.push(twoFactorConfigs[i]);
                }
            }
        }
        return filtered;
    }
);

export const onlyHasU2F = createSelector(
    [getTwoFactorConfigs],
    (twoFactorConfigs) => {
           return Array.isArray(twoFactorConfigs) && twoFactorConfigs.length === 1 && twoFactorConfigs[0].type == TYPE_U2F;
    }
);

export const getDefaultTwoFactorConfiguration = createSelector(
    [getTwoFactorConfigs],
    (twoFactorConfigs) => {
        twoFactorConfigs = getFilteredTwoFactorConfigs({kioskManagement: {twoFactorConfigs}});
        if (Array.isArray(twoFactorConfigs)) {
            for(let i = 0; i < twoFactorConfigs.length; i++) {
                if (twoFactorConfigs[i].default) {
                    return twoFactorConfigs[i];
                }
            }
            return twoFactorConfigs[0];
        }
        return null;
    }
);
