import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Possible settings:
 *  chargeDeliveryFeeOnFree - bool
 *  collectAddressesOnFree - bool
*/
interface ISettingsState {}
const initialState: ISettingsState = {};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase('FETCH_EVENT', (state, action) => {
                // @ts-ignore
                return {...state, ...action.data.attributes.settings};
            })
            .addDefaultCase((state, action) => {
                return state;
            });
    }
});
