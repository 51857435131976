// @flow

type State = {
    input: any,
    type: string,
    value: string,
    label: string,
    updateFunc: any,
}

let initialState = {
    input: null, // the html input object that we're editing
    type: 'text', // what type of keyboard we should be showing ('' is none)
    value: '', // text value for the keyboard's internal state
    label: '', // label for what the keyboard is editing
    updateFunc: null, // action to dispatch when update is received
    updateArgs: [], // arguments to pass to the update function
}

export function keyboard(state: State = initialState, action): State {
    if(action.type === "SET_KEYBOARD_INPUT") {
        let newState = {...state};
        newState.input = action.value;
        return newState;
    } else if(action.type === "SET_KEYBOARD_VALUE") {
        let newState = {...state};
        newState.value = action.value;
        return newState;
    } else if(action.type === "SET_KEYBOARD_TYPE") {
        let newState = {...state};
        newState.type = action.value;
        return newState;
    } else if(action.type === "SET_KEYBOARD_LABEL") {
        let newState = {...state};
        newState.label = action.value;
        return newState;
    } else if(action.type === "SET_KEYBOARD_UPDATE_FUNCTION") {
        let newState = {...state};
        newState.updateFunc = action.func;
        newState.updateArgs = action.updateArgs;
        return newState;
    } else {
        return state;
    }
}
