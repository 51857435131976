export const INCREMENT_BUNDLE = "INCREMENT_BUNDLE";
export const DECREMENT_BUNDLE = "DECREMENT_BUNDLE";
export const SET_BUNDLE = "SET_BUNDLE";
export const SET_NEW_BUNDLE_QUANTITIES = "SET_NEW_BUNDLE_QUANTITIES";
export const CLEAR_SELECTED_BUNDLES = "CLEAR_SELECTED_BUNDLES";

export const SET_BUNDLE_PRODUCT_CONFIG = "SET_BUNDLE_PRODUCT_CONFIG";

export function incrementBundle(id, subReducerKey = null) {
    return {type: INCREMENT_BUNDLE, id, subReducerKey}
}

export function decrementBundle(id, subReducerKey = null) {
    return {type: DECREMENT_BUNDLE, id, subReducerKey}
}

export function setBundle(id, value, subReducerKey = null) {
    return {type: SET_BUNDLE, id, value, subReducerKey}
}

export function setBundleProductConfig(configuration, subReducerKey = null) {
    return {type: SET_BUNDLE_PRODUCT_CONFIG, configuration, subReducerKey}
}

export function setBundlesQuantities(quantities, subReducerKey = null) {
    return {type: SET_NEW_BUNDLE_QUANTITIES, quantities, subReducerKey};
}

export function clearSelectedBundles(subReducerKey = null) {
    return {type: CLEAR_SELECTED_BUNDLES, subReducerKey};
}
