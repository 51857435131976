import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import { MessagePageBanner, MessagePageBannerVariant } from './MessagePageBanner';

class MaintenanceBanner extends PureComponent{
    render() {
        if(!this.props.maintenanceMode) {
            return null;
        }

        return <MessagePageBanner
            variant={MessagePageBannerVariant.caution}
            dismissable={false}
        >
            <span>The site is currently undergoing maintenance. Some functions may be unavailable.</span>
        </MessagePageBanner>
    }
}

MaintenanceBanner.propTypes = {
    maintenanceMode: PropTypes.bool,
};

MaintenanceBanner.defaultProps = {
    maintenanceMode: false,
};

export default MaintenanceBanner;
