import React, { Component } from 'react';

class ButtonLoading extends Component {
    render() {
        return (
            <span> <span className='loader'></span> {this.props.children}</span>
        );
    }
}

export default ButtonLoading;
