export const defaultState = {
    taggableCouponLoading: false,
    appliedTaggableAccessCode: false,
    appliedTaggableReserveCode: false,
    appliedTaggableInvitationCode: false,
    appliedTaggablePresaleCode: false,
    appliedTaggableDiscountCode: false,
    taggableCodeError: false,
};

let initialState = {...defaultState};

export function couponTag(state = initialState, action) {
    if(action.type === "SET_TAGGABLE_ACCESS_CODE_APPLIED") {
        let newState = {...state};
        newState.appliedTaggableAccessCode = !!action.val;
        return newState;
    } else if(action.type === "SET_TAGGABLE_RESERVE_CODE_APPLIED") {
        let newState = {...state};
        newState.appliedTaggableReserveCode = !!action.val;
        return newState;
    } else if(action.type === "SET_TAGGABLE_INVITATION_CODE_APPLIED") {
        let newState = {...state};
        newState.appliedTaggableInvitationCode = !!action.val;
        return newState;
    } else if(action.type === "SET_TAGGABLE_PRESALE_CODE_APPLIED") {
        let newState = {...state};
        newState.appliedTaggablePresaleCode = !!action.val;
        return newState;
    } else if(action.type === "SET_TAGGABLE_DISCOUNT_CODE_APPLIED") {
        let newState = {...state};
        newState.appliedTaggableDiscountCode = !!action.val;
        return newState;
    } else if(action.type === "SET_TAGGABLE_COUPON_LOADING") {
        let newState = {...state};
        newState.taggableCouponLoading = !!action.val;
        return newState;
    } else if(action.type === "SET_TAGGABLE_CODE_ERROR") {
        let newState = {...state};
        newState.taggableCodeError = !!action.val;
        return newState;
    } else {
        return state;
    }
}
