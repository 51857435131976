import React from "react";
import Translation from "@s/Translation";
import { clsx } from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { decrementPriceLevel, incrementPriceLevel } from "../actions/ticket-picker";
import {
  getSelectedTimeslotBuyout,
  regularTicketsSelected,
} from "../../../selectors/tickets";
import { bundlesSelected } from '../../../selectors/bundles';

const BookTimeslotBuyout = (props) => {
  const {
    priceLevel,
    regularTicketsSelected,
    bundlesSelected,
    selectedTimeslotBuyout,
    isSoldOut
  } = props

  const isBooked = selectedTimeslotBuyout?.price_level_id === priceLevel.price_level_id;
  const otherTimeslotBuyoutBooked = selectedTimeslotBuyout && selectedTimeslotBuyout.price_level_id !== priceLevel.price_level_id
  const isDisabled = regularTicketsSelected || bundlesSelected || otherTimeslotBuyoutBooked;

  const handleBooking = () => {
    if (isBooked) {
      props.decrementPriceLevel(priceLevel.price_level_id);
    } else {
      props.incrementPriceLevel(priceLevel.price_level_id);
    }
  }

  if (isSoldOut) {
    return (
      <div className="ticket-quantity">
        <div className="ticket__price">
          <span>
            <Translation>checkout__sold_out</Translation>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="ticket-quantity">
      <div className="ticket-action__add">
        <button
          disabled={isDisabled}
          className={clsx(
            "button ticket-action__book-button",
            isBooked ? "button--default" : "button--primary",
          )}
          onClick={handleBooking}
        >
          {isBooked && <i className="icon-check" />}
          {' '}
          <Translation>checkout__book</Translation>
        </button>
      </div>
    </div>
  );
};

BookTimeslotBuyout.propTypes = {
  // state
  priceLevel: PropTypes.object.isRequired,
  regularTicketsSelected: PropTypes.bool,
  bundlesSelected: PropTypes.bool,
  selectedTimeslotBuyout: PropTypes.object,
  isSoldOut: PropTypes.bool,

  // actions
  decrementPriceLevel: PropTypes.func.isRequired,
  incrementPriceLevel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const priceLevelId = props.id
  const priceLevel = state.priceLevel.levels[priceLevelId];
  const initialQuantities = state.ticketPicker.quantityArrays[priceLevelId];

  const isSoldOut = initialQuantities && initialQuantities.length === 0;

  return {
    priceLevel,
    regularTicketsSelected: regularTicketsSelected(state),
    bundlesSelected: bundlesSelected(state),
    selectedTimeslotBuyout: getSelectedTimeslotBuyout(state),
    isSoldOut,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  decrementPriceLevel,
  incrementPriceLevel,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookTimeslotBuyout);
