const initialState = {
    token: null,
    loginLoading: false,
    twoFactorConfigs: [],
    twoFactorChallengeLoading: false,
    pendingTwoFactorChallengeId: null,
    pendingTwoFactorChallengeType: null,
    pendingTwoFactorChallengeDescription: null,
};

export const TYPE_U2F = 3;

export function kioskManagement(state = initialState, action) {
    if(action.type === "SET_KIOSK_TOKEN") {
        let newState = {...state};
        newState.token = action.val;
        return newState;
    } else if(action.type === "SET_LOGIN_LOADING") {
    	let newState = {...state};
        newState.loginLoading = action.val;
        return newState;
    } else if(action.type === "SET_TWO_FACTOR_CHALLENGE_LOADING") {
        let newState = {...state};
        newState.twoFactorChallengeLoading = action.val;
        return newState;
    } else if(action.type === "SET_TWO_FACTOR_CONFIGS") {
    	let newState = {...state};
        newState.twoFactorConfigs = [...action.val];
        return newState;
    } else if(action.type === "SET_PENDING_TWO_FACTOR_CHALLENGE_ID") {
    	let newState = {...state};
        newState.pendingTwoFactorChallengeId = action.val;
        return newState;
    } else if(action.type === "SET_PENDING_TWO_FACTOR_CHALLENGE_TYPE") {
    	let newState = {...state};
        newState.pendingTwoFactorChallengeType = action.val;
        return newState;
    } else if(action.type === "SET_PENDING_TWO_FACTOR_CHALLENGE_DESCRIPTION") {
        let newState = {...state};
        newState.pendingTwoFactorChallengeDescription = action.val;
        return newState;
    } else {
        return state;
    }
}
