import { getSubState } from '../../../reducers/subReducerHelpers';

export const INCREMENT_PRICE_LEVEL = "INCREMENT_PRICE_LEVEL";
export const DECREMENT_PRICE_LEVEL = "DECREMENT_PRICE_LEVEL";
export const SET_PRICE_LEVEL = "SET_PRICE_LEVEL";
export const SET_NEW_QUANTITIES = "SET_NEW_QUANTITIES";
export const CLEAR_SELECTED_PRICE_LEVELS = "CLEAR_SELECTED_PRICE_LEVELS";
export const SET_DISPLAY_CONFIGURATION_ERROR = "SET_DISPLAY_CONFIGURATION_ERROR";
export const PRICE_LEVEL_SET_PWYW_PRICE = "PRICE_LEVEL_SET_PWYW_PRICE";

export function incrementPriceLevel(id, subReducerKey = null) {
    return (dispatch, getState) => {
        let state = getState();
        let level = getSubState(state.priceLevel, subReducerKey)?.levels?.[id];
        let quantity = state.ticketPicker.quantities[id];

        if(level && quantity === +level.transaction_limit) {
            return;
        }

        dispatch({type: INCREMENT_PRICE_LEVEL, id, subReducerKey});
    }
}

export function decrementPriceLevel(id, subReducerKey = null) {
    return {type: DECREMENT_PRICE_LEVEL, id, subReducerKey};
}

export function setPriceLevel(id, value, subReducerKey = null) {
    return {type: SET_PRICE_LEVEL, id, value, subReducerKey};
}

export function setPriceLevelsQuantities(quantities, subReducerKey = null) {
    return {type: SET_NEW_QUANTITIES, quantities, subReducerKey};
}

export function clearSelectedPriceLevels(subReducerKey = null) {
    return {type: CLEAR_SELECTED_PRICE_LEVELS, subReducerKey};
}

export function setDisplayConfigurationError(value, subReducerKey = null) {
    return {type: SET_DISPLAY_CONFIGURATION_ERROR, value, subReducerKey};
}

export function setPWYWPrice(id, price, subReducerKey = null) {
    return {type: PRICE_LEVEL_SET_PWYW_PRICE, id, price, subReducerKey};
}
