import React, { Component } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { CheckoutProcessContext } from '../contexts/CheckoutProcessContext';

export class Translation extends Component {
  render() {
    let stub = this.props.children;
    let additionalInfo = this.props.interpolation ? this.props.interpolation : {};

    return (
      <CheckoutProcessContext.Consumer>
        {({ TranslationContext }) => (
          <TranslationContext.Consumer>
            {(t) => <span>{parse(t.t(stub, additionalInfo))}</span>}
          </TranslationContext.Consumer>
        )}
      </CheckoutProcessContext.Consumer>
    );
  }
}

Translation.propTypes = {
  interpolation: PropTypes.object,
};

export default Translation;
