// @flow
export type SetAnswerAction = { +type: 'SET_ANSWER', +questionId: number, +id: number, +answer: string };
export type SetQuestionACtion = { +type: 'SET_QUESTIONS', +questionSet: Array };

export type Action = SetQuestionACtion | SetAnswerAction;

import { defaultSelectMultipleAnswer } from '../../../selectors/questions';

type Question = {
  allows_write_in: boolean,
  answer: string,
  answer_resource_id: string,
  answer_resource_type: string,
  element_id: string, // not used in this frontend
  element_name: string, // not used in this frontend
  has_locked_responses: boolean,
  has_required_unanswered: boolean,
  has_unanswered: boolean,
  question: string,
  question_id: string,
  question_type: string,
  required: string, // dumb -- "0" or "1"
  resource_id: string,
  resource_type: string,
  write_in_option: string // maybe? it's null on a regular question
};

type State = {
  questionSet: Array<Object>,
  events: Object,
  orders: Array<Question>,
  opt_in_questions: Array<Question>
};

let initialState = {
  questionSet: [], // mostly for debugging, this can probably be removed
  events: {},
  orders: [],
  opt_in_questions :[]
};

export const TYPE_TEXT = '1';
export const TYPE_WAIVER = '2';
export const TYPE_ADDRESS = '3';
export const TYPE_MULTIPLE_CHOICE = '4';
export const TYPE_PHONE = '5';
export const TYPE_NUMBER = '6';
export const TYPE_RANGE = '7';
export const TYPE_EMAIL = '8';
export const TYPE_URL = '9';
export const TYPE_DATE = '10';
export const TYPE_OPT_IN = '12';

const supportedTypes = [
  TYPE_TEXT,
  TYPE_WAIVER,
  TYPE_ADDRESS,
  TYPE_MULTIPLE_CHOICE,
  TYPE_PHONE,
  TYPE_NUMBER,
  TYPE_RANGE,
  TYPE_EMAIL,
  TYPE_URL,
  TYPE_DATE
];

/** Reducer for question actions */
export function questions(state: State = initialState, action: Action): State {
  if (action.type === 'SET_QUESTIONS') {
    let newState = { ...state };
    newState.questionSet = action.questionSet.question_set;
    let questions = newState.questionSet;

    let events = {};
    let orders = [];
    let opt_in_questions = [];

    // split up the questions by event (and by ticket)
    for (let i = 0; i < questions.length; i++) {
      // per-event questions
      if (questions[i].resource_type === 'Event') {
        if (questions[i].has_content) {
          events[questions[i].event_id] = {
            eventTitle: questions[i].event_name,
            questions: [],
            ticketQuestions: {}
          };
        }

        for (let j = 0; j < questions[i].content.length; j++) {
          // if this object is a question then it's event level
          if (questions[i].content[j].question) {
            if (!isSupportedQuestionType(questions[i].content[j].question_type)) {
              continue;
            }

            questions[i].content[j] = formatQuestion(questions[i].content[j]);

            events[questions[i].event_id].questions.push(questions[i].content[j]);
          } else if (questions[i].content[j].has_content) {
            // nesting at this point means ticket level q's
            for (let k = 0; k < questions[i].content[j].content.length; k++) {
              if (!isSupportedQuestionType(questions[i].content[j].content[k].question_type)) {
                continue;
              }

              if (
                !events[questions[i].event_id].ticketQuestions[questions[i].content[j].content[k].answer_resource_id]
              ) {
                events[questions[i].event_id].ticketQuestions[
                  questions[i].content[j].content[k].answer_resource_id
                ] = [];
              }
              questions[i].content[j].content[k] = formatQuestion(questions[i].content[j].content[k]);

              events[questions[i].event_id].ticketQuestions[questions[i].content[j].content[k].answer_resource_id].push(
                questions[i].content[j].content[k]
              );
            }
          }
        }
      } else if (questions[i].resource_type === 'Reservation') {
        if (questions[i].has_content) {
          for (let j = 0; j < questions[i].content.length; j++) {
            if (!isSupportedQuestionType(questions[i].content[j].question_type)) {
              if (questions[i].content[j].question_type == TYPE_OPT_IN ){
                opt_in_questions.push(questions[i].content[j]);
              }
              continue;
            }

            questions[i].content[j] = formatQuestion(questions[i].content[j]);

            orders.push(questions[i].content[j]);
          }
        }
      }
    }

    for (const id in events) {
      if (events[id].questions.length === 0 && Object.keys(events[id].ticketQuestions).length === 0) {
        delete events[id];
      }
    }

    newState.events = events;
    newState.orders = orders;
    newState.opt_in_questions = opt_in_questions;

    return newState;
  } else if (action.type === 'SET_ANSWER') {
    let newState = { ...state };
    newState.events = { ...newState.events };
    newState.orders = [...newState.orders];

    if (action.resourceType === 'Sale') {
      for (let i = 0; i < newState.orders.length; i++) {
        // this needs to also be passed question id in the case of multiple
        if (
          newState.orders[i].resource_id === action.questionId &&
          newState.orders[i].answer_resource_id === action.id
        ) {
          newState.orders = [...newState.orders];
          newState.orders[i] = { ...newState.orders[i] };
          newState.orders[i].answer = action.answer;
        }
      }
    } else {
      if (action.resourceType === 'Event') {
        for (let i = 0; i < newState.events[action.id].questions.length; i++) {
          if (
            newState.events[action.id].questions[i].resource_id === action.questionId &&
            newState.events[action.id].questions[i].answer_resource_id === action.id
          ) {
            newState.events = { ...newState.events };
            newState.events[action.id] = { ...newState.events[action.id] };
            newState.events[action.id].questions = [...newState.events[action.id].questions];
            newState.events[action.id].questions[i] = { ...newState.events[action.id].questions[i] };
            newState.events[action.id].questions[i].answer = action.answer;
          }
        }
      } else if (action.resourceType === 'Ticket' || action.resourceType === 'BundleSale') {
        for (const id in newState.events) {
          if (newState.events[id].ticketQuestions[action.id]) {
            for (let i = 0; i < newState.events[id].ticketQuestions[action.id].length; i++) {
              if (newState.events[id].ticketQuestions[action.id][i].resource_id === action.questionId) {
                newState.events = { ...newState.events };
                newState.events[id] = { ...newState.events[id] };
                newState.events[id].ticketQuestions = { ...newState.events[id].ticketQuestions };
                newState.events[id].ticketQuestions[action.id] = [...newState.events[id].ticketQuestions[action.id]];
                newState.events[id].ticketQuestions[action.id][i] = {
                  ...newState.events[id].ticketQuestions[action.id][i]
                };
                newState.events[id].ticketQuestions[action.id][i].answer = action.answer;
                break;
              }
            }
            break;
          }
        }
      }
    }

    return newState;
  } else if (action.type === 'UPDATE_QUESTION') {
    let newState = { ...state };
    newState.events = { ...newState.events };
    newState.orders = [...newState.orders];

    const { field, value } = action;

    if (action.resourceType === 'Sale') {
      for (let i = 0; i < newState.orders.length; i++) {
        // this needs to also be passed question id in the case of multiple
        if (
          newState.orders[i].resource_id === action.questionId &&
          newState.orders[i].answer_resource_id === action.id
        ) {
          newState.orders = [...newState.orders];
          newState.orders[i] = { ...newState.orders[i] };
          newState.orders[i][field] = value;
        }
      }
    } else {
      if (action.resourceType === 'Event') {
        for (let i = 0; i < newState.events[action.id].questions.length; i++) {
          if (
            newState.events[action.id].questions[i].resource_id === action.questionId &&
            newState.events[action.id].questions[i].answer_resource_id === action.id
          ) {
            newState.events = { ...newState.events };
            newState.events[action.id] = { ...newState.events[action.id] };
            newState.events[action.id].questions = [...newState.events[action.id].questions];
            newState.events[action.id].questions[i] = { ...newState.events[action.id].questions[i] };
            newState.events[action.id].questions[i][field] = value;
          }
        }
      } else if (action.resourceType === 'Ticket' || action.resourceType === 'BundleSale') {
        for (const id in newState.events) {
          if (newState.events[id].ticketQuestions[action.id]) {
            for (let i = 0; i < newState.events[id].ticketQuestions[action.id].length; i++) {
              if (newState.events[id].ticketQuestions[action.id][i].resource_id === action.questionId) {
                newState.events = { ...newState.events };
                newState.events[id] = { ...newState.events[id] };
                newState.events[id].ticketQuestions = { ...newState.events[id].ticketQuestions };
                newState.events[id].ticketQuestions[action.id] = [...newState.events[id].ticketQuestions[action.id]];
                newState.events[id].ticketQuestions[action.id][i] = {
                  ...newState.events[id].ticketQuestions[action.id][i]
                };
                newState.events[id].ticketQuestions[action.id][i][field] = value;
                break;
              }
            }
            break;
          }
        }
      }
    }

    return newState;
  } else {
    return state;
  }
}

/**
 * Apply formatting to question when it gets loaded into state
 */
const formatQuestion = question => {
  // set answer to true for waiver question
  if (question.question_type === TYPE_WAIVER && question.answer === '1') {
    question.answer = true;
  }
  // format multiple choice answers
  if (question.question_type === TYPE_MULTIPLE_CHOICE) {
    try {
      // eslint-disable-next-line no-extra-boolean-cast
      const multipleChoiceAnswers = !!question?.answer ? JSON.parse(question.answer) : [];
      question.answer = defaultSelectMultipleAnswer();
      if (Array.isArray(multipleChoiceAnswers) && Array.isArray(question?.options)) {
        multipleChoiceAnswers.forEach(ans => {
          if (question.options.includes(ans)) {
            question.answer.selectedOptions.push(ans);
          } else {
            question.answer.writeIn = ans;
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return question;
};

function isSupportedQuestionType(type) {
  return supportedTypes.indexOf(type.toString()) > -1;
}
