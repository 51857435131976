import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { timeSlice } from '../../../slices/time';

class TimeClock extends Component {

    state = {
        interval: null,
    };

    componentDidMount() {
        this.setState({interval:setInterval(() => {
            this.props.setCurrentTime();
        }, 10000)})
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
        this.setState({interval: null});
    }

    render() {
        return null;
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setCurrentTime: timeSlice.actions.setCurrentTime,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeClock)
