// @flow
import type {ClearAction, CreateAction} from '../../../reducers/reservation';
import reconcilePersistedState from '../../../../helpers/reconcilePersistedState';
export type SetCodeAction = {type: "SET_COUPON_CODE", code: string};
export type AddCodeAction = {type: "ADD_COUPON_CODE", code: string};
export type ClearCodesAction = {type: "CLEAR_COUPON_CODES"};
export type SetPresaleCodeAction  = {type: "SET_PRESALE_CODE", presaleCode: string};
export type SetShowErrorAction = {type: "SET_SHOW_ERROR", showError: boolean};
export type SetShowSuccessAction = {type: "SET_SHOW_SUCCESS", showSuccess: boolean};
export type SetShowWarningAction = {type: "SET_SHOW_WARNING", showWarning: boolean};
export type SetIsPresaleValidAction = {type: "SET_IS_PRESALE_VALID", isPresaleValid: boolean};
export type SetIsPresaleAppliedAction = {type: "SET_IS_PRESALE_APPLIED", isPresaleCodeApplied: boolean};
export type SetCouponData = {type: "SET_COUPON_DATA", couponData: Array};
export type SetAutoCouponAmount = {type: "SET_AUTO_COUPON_AMOUNT", autoCouponAmount: number};
export type SetPresale = {type: "SET_PRESALE", presale: Object};
export type SetAccessCode = {type: "SET_ACCESS_CODE", accessCode: string};
export type SetIsAccessCodeValidAction = {type: "SET_IS_ACCESS_CODE_VALID", isAccessCodeValid: boolean};
export type SetIsAccessCodeAppliedAction = {type: "SET_IS_ACCESS_CODE_APPLIED", isAccessCodeApplied: boolean};
export type SetShowAccessCodeErrorAction = {type: "SET_SHOW_ACCESS_CODE_ERROR", showAccessCodeError: boolean};
export type SetShowAccessCodeSuccessAction = {type: "SET_SHOW_ACCESS_CODE_SUCCESS", showAccessCodeSuccess: boolean};

export type Action = SetCodeAction | AddCodeAction | ClearCodesAction | ClearAction | CreateAction | SetShowErrorAction | SetShowSuccessAction | SetShowWarningAction | SetCouponData | SetPresale | SetPresaleCodeAction | SetIsPresaleValidAction | SetIsPresaleAppliedAction |
    SetAutoCouponAmount | SetAccessCode | SetIsAccessCodeValidAction | SetIsAccessCodeAppliedAction | SetShowAccessCodeErrorAction | SetShowAccessCodeSuccessAction;
type State = { +code: string, presaleCode: string, showError: boolean, showSuccess: boolean, showWarning: boolean, couponData: Object, autoCouponAmount: number, isPresaleValid: boolean, isPresaleCodeApplied: boolean, accessCode:string, isAccessCodeValid: boolean, isAccessCodeApplied: boolean, showAccessCodeError: boolean, showAccessCodeSuccess: boolean, unlockedPresaleEvents: array};

export const defaultState = {
    code: "",
    codes: [],
    couponInput: "",
    presaleCode: "",
    showError: false,
    showSuccess: false,
    showWarning: false,
    couponData: [],
    autoCouponAmount: "",
    isPresaleValid: false,
    isPresaleCodeApplied: false,
    accessCode: "",
    isAccessCodeValid: false,
    isAccessCodeApplied: false,
    showAccessCodeError: false,
    showAccessCodeSuccess: false,
    unlockedPresaleEvents: [],
    accessInventory: null,
    reserveCouponUsages: [],
    inviteCouponUsages: [],
    loading: false,
};

let initialState = {...defaultState};

/** Reducer for member actions */
export function coupon(state: State = initialState, action:Action): State {
    if(action.type === "SET_COUPON_CODE") {
        let newState = {...state};
        newState.code = action.code;
        return newState;
    } else if(action.type === "ADD_COUPON_CODE") {
        let newState = {...state};
        newState.codes.push(action.code);
        return newState;
    } else if(action.type === "CLEAR_COUPON_CODES") {
        let newState = {...state};
        newState.codes = [];
        return newState;
    } else if(action.type === "SET_COUPON_INPUT") {
        let newState = {...state};
        newState.couponInput = action.code;
        return newState;
    } else if(action.type === "SET_PRESALE_CODE") {
        let newState = {...state};
        newState.presaleCode = action.presaleCode;
        return newState;
    } else if(action.type === "SET_COUPON_DATA") {
        let newState = {...state};
        newState.couponData = action.couponData;
        return newState;
    }  else if(action.type === "SET_AUTO_COUPON_AMOUNT") {
        let newState = {...state};
        newState.autoCouponAmount = action.autoCouponAmount;
        return newState;
    } else if(action.type === "SET_SHOW_ERROR") {
        let newState = {...state};
        newState.showError = action.showError;
        return newState;
    } else if(action.type === "SET_SHOW_SUCCESS") {
        let newState = {...state};
        newState.showSuccess = action.showSuccess;
        return newState;
    } else if(action.type === "SET_SHOW_WARNING") {
        let newState = {...state};
        newState.showWarning = action.showWarning;
        return newState;
    } else if(action.type === "SET_IS_PRESALE_VALID") {
        let newState = {...state};
        newState.isPresaleValid = action.isPresaleValid;
        return newState;
    }  else if(action.type === "SET_IS_PRESALE_APPLIED") {
        let newState = {...state};
        newState.isPresaleCodeApplied = action.isPresaleCodeApplied;
        return newState;
    } else if(action.type === "SET_ACCESS_CODE") {
        let newState = {...state};
        newState.accessCode = action.accessCode;
        return newState;
    } else if(action.type === "SET_IS_ACCESS_CODE_VALID") {
        let newState = {...state};
        newState.isAccessCodeValid = action.isAccessCodeValid;
        return newState;
    } else if(action.type === "SET_IS_ACCESS_CODE_APPLIED") {
        let newState = {...state};
        newState.isAccessCodeApplied = action.isAccessCodeApplied;
        return newState;
    } else if(action.type === "SET_SHOW_ACCESS_CODE_ERROR") {
        let newState = {...state};
        newState.showAccessCodeError = action.showAccessCodeError;
        return newState;
    }  else if(action.type === "SET_SHOW_ACCESS_CODE_SUCCESS") {
        let newState = {...state};
        newState.showAccessCodeSuccess = action.showAccessCodeSuccess;
        return newState;
    } else if(action.type === "SET_UNLOCKED_PRESALE_EVENT") {
        let newState = {...state, unlockedPresaleEvents: [...state.unlockedPresaleEvents]};
        //Make sure unlockedPresaleEvents is an array of int.
        newState.unlockedPresaleEvents.push(parseInt(action.id));
        return newState;
    } else if(action.type === "SET_ACCESS_INVENTORY") {
        let newState = {...state};
        newState.accessInventory = action.val;
        return newState;
    } else if(action.type === "SET_RESERVE_COUPON_USAGES") {
        let newState = {...state};
        newState.reserveCouponUsages = Array.isArray(action.val) ? [...action.val] : [];
        return newState;
    } else if(action.type === "SET_INVITE_COUPON_USAGES") {
        let newState = {...state};
        newState.inviteCouponUsages = Array.isArray(action.val) ? [...action.val] : [];
        return newState;
    } else if(action.type === "RESERVATION_CLEARED") {
        return {
            ...initialState,
            codes: []
        };
    } else if (action.type === 'RECONCILE_PERSISTED_STATE') {
        return reconcilePersistedState(state, initialState);
    } else if(action.type === "SET_COUPON_LOADING") {
        let newState = {...state};
        newState.loading = action.val;
        return newState;
    } else {
        return state;
    }
}
