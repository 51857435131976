// @flow
export type IncrementProductAction = {type: "INCREMENT_PRODUCT", id: number};
export type DecrementProductAction = {type: "DECREMENT_PRODUCT", id: number};
export type SetProductAction = {type: "SET_PRODUCT", id: number, value: number};
export type SetProductConfigAction = {type: "SET_PRODUCT_CONFIG", product_id: number, id: number, value: number};
export type FetchAction = {type: "FETCH_EVENT", data: Object};
export type SetProductQuantitiesAction = {type: "SET_NEW_PRODUCT_QUANTITIES", quantities: Object};

export type Action = FetchAction | IncrementProductAction | DecrementProductAction | SetProductAction | SetProductConfigAction | SetProductQuantitiesAction;

type State = { +productsQuantities: Object, +productsIndexes: Object, +productsQuantityArrays: Object, +configQuantities: Object, +selectedConfigurations: Object, +configurationsQuantityArrays: Object, +availableQuantities: Object, +initialQuantities: Object };

import { getReducerState, returnReducerState, applyToParentAndAllSubReducers } from '../../../reducers/subReducerHelpers';

const defaultState = { productsQuantities: {}, productsIndexes: {}, productsQuantityArrays: {}, configQuantities: {}, selectedConfigurations: {}, configurationsQuantityArrays: {}, availableQuantities: {}, initialQuantities: {} };
let initialState = defaultState;
/** Reducer for product picker actions */
export function productPicker(state: State = initialState, action:Action): State {

    let newState = getReducerState(state, defaultState, action);

    if(action.type === "INCREMENT_PRODUCT") {
        let id = action.id;
        let quantityArray = newState.productsQuantityArrays[action.id];
        newState.productsIndexes = {...newState.productsIndexes};
        newState.productsQuantities = {...newState.productsQuantities};
        if(newState.productsIndexes[id] === undefined) {
            newState.productsIndexes[id] = 0;
        } else {
            newState.productsIndexes[id] += 1;
        }

        newState.productsQuantities[id] = quantityArray[newState.productsIndexes[id]];
    } else if(action.type === "DECREMENT_PRODUCT") {
        let id = action.id;
        let quantityArray = newState.productsQuantityArrays[action.id];

        newState.productsIndexes = {...newState.productsIndexes};
        newState.productsQuantities = {...newState.productsQuantities};
        if(newState.productsIndexes[id] === 0) {
            newState.productsIndexes[id] = undefined;
            newState.productsQuantities[id] = undefined;
        } else {
            newState.productsIndexes[id] -= 1;
            newState.productsQuantities[id] = quantityArray[newState.productsIndexes[id]];
        }

        // update the configurations
        if (newState.configQuantities[id] && Object.keys(newState.configQuantities[id]).length > 0) {
            delete newState.configQuantities[id][Object.keys(newState.configQuantities[id]).length - 1];
        } else {
            delete newState.configQuantities[id];
        }

        newState.selectedConfigurations = buildSelectedConfigurations(newState.configQuantities);
    } else if(action.type === "SET_PRODUCT") {
        newState.productsIndexes = {...newState.productsIndexes};
        newState.productsQuantities = {...newState.productsQuantities};

        let quantityArray = newState.productsQuantityArrays[action.id];
        newState.productsIndexes[action.id] = quantityArray.indexOf(action.value);
        newState.productsQuantities[action.id] = quantityArray[newState.productsIndexes[action.id]];

        newState.configQuantities = {...newState.configQuantities};
        newState.configQuantities[action.id] = {};
        for(let i = 0; i < action.value; i++) {
            newState.configQuantities[action.id][i] = {};
            newState.configQuantities[action.id][i][action.configId] = 1;
        }
        newState.selectedConfigurations = buildSelectedConfigurations(newState.configQuantities);
    } else if(action.type === "SET_PRODUCT_CONFIG") {
        let configId = action.configuration["configurationId"];
        let productId = action.configuration["productId"];
        let index = action.configuration["index"];

        newState.configQuantities = {...newState.configQuantities};
        newState.selectedConfigurations = {...newState.selectedConfigurations};
        newState.configQuantities[productId] = {...newState.configQuantities[productId]};
        newState.configQuantities[productId][index] = {};

        if (configId !== "") {
            if (newState.configQuantities[productId][index][configId] === undefined) {
                newState.configQuantities[productId][index][configId] = 1;
            } else {
                newState.configQuantities[productId][index][configId] += 1;
            }
        } else {
            if (newState.configQuantities[productId][index]) {
                delete newState.configQuantities[productId][index];
            }
        }

        newState.selectedConfigurations = buildSelectedConfigurations(newState.configQuantities);
    } else if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {

        for (const eventId in action.data.attributes.events) {
            let eventProducts = action.data.attributes.events[eventId].products;
            for (let i = 0; i < eventProducts.length; i++) {
                let product = eventProducts[i];
                newState.productsQuantityArrays[product.product_id] = buildProductQuantityArray(product);
                newState.initialQuantities[product.product_id] = buildProductQuantityArray(product);

                product.configurations.forEach(configuration => {
                    newState.configurationsQuantityArrays[configuration.product_configuration_id] = buildConfigurationQtyArray(configuration);
                })
            }
        }
    } else if(action.type === "SET_PRODUCTS") {

        for(let i = 0; i < action.data.length; i++) {
            let product = action.data[i];
            newState.productsQuantityArrays[product.product_id] = buildProductQuantityArray(product);
            newState.initialQuantities[product.product_id] = buildProductQuantityArray(product);

            product.configurations.forEach(configuration => {
                newState.configurationsQuantityArrays[configuration.product_configuration_id] = buildConfigurationQtyArray(configuration);
            })
        }
    } else if(action.type === "SET_PRODUCT_SALES") {
        newState.productsQuantities = {};
        newState.productsIndexes = {};
        newState.selectedConfigurations = {};
        newState.configQuantities= {};
    } else if(action.type === "SET_NEW_PRODUCT_QUANTITIES") {
        newState.availableQuantities = action.quantities;
    } else if(action.type === "RESERVATION_CLEARED" || action.type === "CLEAR_SELECTED_PRODUCTS") {
        if(!action.reset && action.type !== "CLEAR_SELECTED_PRODUCTS") {
            return state;
        }

        newState = applyToParentAndAllSubReducers(newState, s => {
            s.productsQuantities = {};
            s.productsIndexes = {};
            s.selectedConfigurations = {};
            s.configQuantities= {};
            s.availableQuantities= {};
            return s;
        });
    } else {
        return state;
    }

    return returnReducerState(state, newState, action);
}

function buildProductQuantityArray(product) {
    let min = 1;
    let max = product.transaction_limit;
    let incrementBy = 1;
    let quantities = [];

    for(var i = min; i <= max; i += incrementBy) {
        quantities.push(i);
    }
    return quantities;
}

function buildConfigurationQtyArray(configuration) {
    let min = 1;
    let max = configuration.limit;
    let incrementBy = 1;
    let quantities = [];

    for(var i = min; i <= max; i += incrementBy) {
        quantities.push(i);
    }
    return quantities;
}

function buildSelectedConfigurations(configQuantities) {
    let configuration = [];
    Object.keys(configQuantities).map(productId => {
        Object.keys(configQuantities[productId]).map(keyName => {
            configuration.push(configQuantities[productId][keyName]);
        });
    });

    let reducedConfig = {};

    configuration.map(config => {
        Object.keys(config).map(configId => {
            if (reducedConfig.hasOwnProperty(configId)) {
                reducedConfig[configId] += config[configId];
            } else {
                reducedConfig[configId] = config[configId];
            }
        });
    });

    return reducedConfig;
}
