import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import UserSelect from './UserSelect';
import TerminalSelect from './TerminalSelect';
import RecentOrders from './RecentOrders';
import Message from 'shared/Message';
import styled from 'styled-components';
import { TranslationContext } from '../../../../checkout/config/Translations';

const KioskManagement = props => {

    const translate  = React.useContext(TranslationContext).t;

    return(
        <>
            <KioskManagementAdmin className="kiosk__management-admin">
                <Message info inlineText title={translate('checkout__kiosk_must_select_user_and_emv')} />
                <UserSelect
                    user={props.purchaseUser}
                    onUserSelect={props.onUserSelect}
                    listingSlug={props.listingSlug}
                    apiUrl={props.apiUrl}
                    apiToken={props.apiToken}
                />
                <TerminalSelect
                    sellerId={props.sellerId}
                    terminal={props.terminalId}
                    onTerminalSelect={props.onTerminalSelect}
                    onLoadTerminals={props.onLoadTerminals}
                    apiUrl={props.apiUrl}
                    terminals={props.terminals}
                />
            </KioskManagementAdmin>
            <RecentOrders
                purchaseUser={props.purchaseUser}
                apiUrl={props.apiUrl}
                adminUrl={props.adminUrl}
                kioskOptions={props.kioskOptions}
                apiToken={props.apiToken}
            />
            <ManagementPaneFooter className="pane-footer">
                <Button className="button button--link button--icon" disabled={props.cmsMode} onClick={() => {window.location.reload(false)}}>
                    <svg width="14" height="14" viewBox="0 0 24 24">
                        <path fill="#000000" d="M12,18A6,6 0 0,1 6,12C6,11 6.25,10.03 6.7,9.2L5.24,7.74C4.46,8.97 4,10.43 4,12A8,8 0 0,0 12,20V23L16,19L12,15M12,4V1L8,5L12,9V6A6,6 0 0,1 18,12C18,13 17.75,13.97 17.3,14.8L18.76,16.26C19.54,15.03 20,13.57 20,12A8,8 0 0,0 12,4Z" />
                    </svg>
                    {translate('checkout__refresh')}
                </Button>
                <Button className="button button--link button--icon" disabled={props.cmsMode} onClick={() => {window.location.href = "/exitkiosk"}}>
                    <svg width="14" height="14" viewBox="0 0 24 24">
                        <path fill="#000000" d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z" />
                    </svg>
                    {translate('checkout__exit_kiosk_mode')}
                </Button>
                <Button className="button button--link button--icon" onClick={props.onLogout}>
                    <svg width="14" height="14" viewBox="0 0 24 24">
                        <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                    {translate('checkout__logout')}
                </Button>
                <Button className="button button--link button--icon" onClick={props.onClose}>
                    <svg width="14" height="14" viewBox="0 0 24 24">
                        <path fill="#000000" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                    {translate('checkout__close')}
                </Button>
            </ManagementPaneFooter>
        </>
    )
}

KioskManagement.propTypes = {
    onClose: PropTypes.func.isRequired,
    purchaseUser: PropTypes.any,
    sellerId: PropTypes.any,
    terminalId: PropTypes.string,
    apiUrl: PropTypes.string.isRequired,
    listingSlug: PropTypes.string.isRequired,
};

KioskManagement.defaultProps = {
};

export default KioskManagement;


const KioskManagementAdmin = styled.div`
    padding: 0 30px;
`

const ManagementPaneFooter = styled.div`
    padding: 20px 10px;
    border-top: 1px solid #eeeeee;
    // margin-top: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 500px;
    display: flex;
    justify-content: space-between;
`
