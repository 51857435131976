import { getCheckoutClient } from '../config/CheckoutClient';
import { fetchReservation, deleteReservation } from './reservation';
import { setPriceLevelsQuantities } from '../views/ticket-picker/actions/ticket-picker';
import { setLoading } from 'checkout/actions/loading';
import { addServerErrors, addAlert } from './ui';
import { isReservationEmpty } from 'checkout/selectors/reservation';
import { submitInsurance } from 'checkout/views/insurance/actions/insurance';
import { updateDonations } from '../views/donations/actions/donations';
import { levelIsPWYW, getPWYWPriceForPriceLevel } from 'checkout/views/ticket-picker/selectors/tickets';
import { setInventoryItemsFromServer } from '../helpers/inventoryItems';

export const setTickets = (tickets, fireHooks = true) => {
  return (dispatch, getState) => {
    dispatch(
      setInventoryItemsFromServer(
        tickets,
        getState().tickets?.tickets ?? [],
        (tickets) => {
          return getSetTicketAction(tickets);
        },
        (tickets) => {
          return { type: 'TICKETS_ADDED_TO_CART', tickets };
        },
        (tickets) => {
          return { type: 'TICKETS_REMOVED_FROM_CART', tickets };
        },
        fireHooks,
      ),
    );
  };
};

export const getSetTicketAction = (tickets) => {
  return { type: 'SET_TICKETS', tickets };
};

export const setRawTicketsState = (state) => ({
  type: 'SET_RAW_TICKETS_SALES_STATE',
  state,
});

const setBundleTickets = (tickets) => {
  return { type: 'SET_BUNDLE_SALE_TICKETS', tickets };
};

export const setTicketName = (id, value) => {
  return { type: 'SET_TICKET_NAME', id, value };
};

export function fetchTickets(token, secret, fireHooks = true) {
  return (dispatch, getState) => {
    let state = getState();
    return getCheckoutClient(state.ui.apiUrl)
      .reservationGetRequest(token, 'tickets', secret)
      .then((result) => {
        let nonBundleTickets = result.body.data.filter((item) => item.attributes.hasBundleSaleId === false);
        let bundleTickets = result.body.data.filter((item) => item.attributes.hasBundleSaleId !== false);
        dispatch(setTickets(nonBundleTickets, fireHooks));
        dispatch(setBundleTickets(bundleTickets));
        let tickets = getSoldOrHeldTickets(nonBundleTickets);

        // updated quantities
        let priceLevelsQuantities = buildPriceLevelsQuantities(state.priceLevel.levels, tickets);
        dispatch(setPriceLevelsQuantities(priceLevelsQuantities));
      });
  };
}

export function updateTicketQuantities(quantities, token, secret, reFetch = true, reSubmitDonations, upsellId = null) {
  return (dispatch, getState) => {
    let state = getState();
    let plId = quantities.tickets[0].pricing_level_id;
    const loadingPrefix = upsellId ? `UPSELL_${upsellId}_TICKET_QUANTITY_${plId}` : `TICKET_QUANTITY_${plId}`;
    dispatch(setLoading(loadingPrefix + '_REQUEST'));

    const endpoint = upsellId ? 'upsell/tickets' : 'tickets';

    if (Array.isArray(quantities?.tickets)) {
      quantities.tickets = quantities.tickets.map((ticketQuantity) => {
        if (levelIsPWYW(state.priceLevel, ticketQuantity?.pricing_level_id)) {
          ticketQuantity.pwyw_price = getPWYWPriceForPriceLevel(
            state.priceLevel,
            state.ticketPicker,
            state.tickets,
            ticketQuantity?.pricing_level_id,
          );
        }
        return ticketQuantity;
      });
    }

    return getCheckoutClient(state.ui.apiUrl)
      .reservationUpdateRequest(token, endpoint, quantities, secret)
      .then((result) => {
        let insuranceReq = null;
        if (state.insurance.provider !== '' && state.insurance.selected !== '') {
          insuranceReq = true;
        }

        if (reFetch) {
          const doRefetch = () => {
            if (insuranceReq) {
              return dispatch(submitInsurance()).then(() => {
                Promise.all([dispatch(fetchReservation(token, secret)), dispatch(fetchTickets(token, secret))])
                  .then(() => {
                    dispatch(setLoading(loadingPrefix + '_SUCCESS'));
                    if (isReservationEmpty(getState())) {
                      dispatch(deleteReservation());
                    }
                  })
                  .catch((e) => {
                    dispatch(setLoading(loadingPrefix + '_FAILURE'));
                  });
              });
            } else {
              return Promise.all([dispatch(fetchReservation(token, secret)), dispatch(fetchTickets(token, secret))])
                .then(() => {
                  dispatch(setLoading(loadingPrefix + '_SUCCESS'));
                  if (isReservationEmpty(getState())) {
                    dispatch(deleteReservation());
                  }
                })
                .catch((e) => {
                  dispatch(setLoading(loadingPrefix + '_FAILURE'));
                });
            }
          };

          //if updating donations, needs to happen before refetching so that
          //the reservation data reflects the updated donation amount(s)
          if (reSubmitDonations) {
            dispatch(updateDonations())
              .catch((e) => {
                //do nothing. At this point the event may have been removed from the reservation
                //as a result of removing tickets which will cause the donation request to 400.
                //Any actual errors will be reflected in the reservation when it is refetched.
              })
              .finally(() => {
                return doRefetch();
              });
          } else {
            return doRefetch();
          }
        } else {
          dispatch(setLoading(loadingPrefix + '_SUCCESS'));
          return dispatch(fetchTickets(token, secret));
        }
      })
      .catch((e) => {
        dispatch(setLoading(loadingPrefix + '_FAILURE'));
        if (e.response && e.response.body) {
          dispatch(addServerErrors(e.response.body.errors));
        } else {
          dispatch(addAlert(e));
        }
        return Promise.reject('');
      });
  };
}

export const deleteTicketsById = (ticketIds) => {
  return (dispatch, getState) => {
    const payload = {
      ticket_ids: Array.isArray(ticketIds) ? ticketIds : [ticketIds],
    };
    const state = getState();
    return getCheckoutClient(state.ui.apiUrl).reservationUpdateRequest(
      state.reservation.token,
      'tickets/remove-by-id',
      payload,
      state.reservation.secret,
    );
  };
};

function buildPriceLevelsQuantities(priceLevels, tickets) {
  let quantityArrays = {};
  Object.keys(priceLevels).forEach((priceLevelId) => {
    let level = priceLevels[priceLevelId];
    quantityArrays[priceLevelId] = buildQuantityArray(
      level.minimum_purchase_limit,
      level.transaction_limit,
      level.increment_by,
    );
  });

  Object.keys(quantityArrays).forEach((priceLevelId) => {
    if (tickets[priceLevelId]) {
      quantityArrays[priceLevelId].length = Math.abs(quantityArrays[priceLevelId].length - tickets[priceLevelId]);
    }
  });

  return quantityArrays;
}

function buildQuantityArray(min = 0, max = 10, step = 1) {
  min = typeof min == 'undefined' || min === 0 ? 1 : min;
  let quantities = [];

  for (var i = +min; i <= +max; i += +step) {
    quantities.push(i);
  }

  return quantities;
}

function getSoldOrHeldTickets(tickets) {
  let counts = {};
  for (let i = 0; i < tickets.length; i++) {
    if (counts[tickets[i].attributes.price_level_id] === undefined) {
      counts[tickets[i].attributes.price_level_id] = 1;
    } else {
      counts[tickets[i].attributes.price_level_id] += 1;
    }
  }

  return counts;
}
