// @flow
import type {ClearAction, CreateAction} from '../../../reducers/reservation';
import reconcilePersistedState from '../../../../helpers/reconcilePersistedState';
export type NameAction = {type: "SET_MEMBER_NAME", name: string};
export type EmailAction = {type: "SET_MEMBER_EMAIL", email: string};
export type PhoneAction = {type: "SET_MEMBER_PHONE", phone: string};

export type Action = NameAction | EmailAction | PhoneAction | ClearAction | CreateAction;

type Address = {+name: string, address: string, address2: string, city: string, state: string, country: string, zip: string, phone: string, countryCode: string};

type State = { +name: string, +email: string, +phone: string, address: Address };

let initialState = {
    name: "",
    email: "",
    phone: "",
    address: false,
    infoSaved: false,
    emailOptOut: false,
    createdNewAccount: false,
    errors: {}
};

/** Reducer for member actions */
export function member(state: State = initialState, action:Action): State {
    if(action.type === "SET_MEMBER_NAME") {
        let newState = {...state};
        newState.name = action.name;
        return newState;
    } else if(action.type === "SET_MEMBER_EMAIL") {
        let newState = {...state};
        newState.email = action.email;
        return newState;
    } else if(action.type === "SET_MEMBER_PHONE") {
        let newState = {...state};
        newState.phone = action.phone;
        return newState;
    } else if(action.type === "SET_MEMBER_ADDRESS") {
        let newState = {...state};
        newState.address = action.address;
        return newState;
    } else if(action.type === "RESERVATION_CLEARED") {
        return initialState;
    } else if(action.type === "RESERVATION_CREATED") {
        return initialState;
    } else if(action.type === "SET_MEMBER_SAVED") {
        let newState = {...state};
        newState.infoSaved = action.value;
        return newState;
    } else if(action.type === "SET_MEMBER_EMAIL_OPT_OUT") {
        let newState = {...state};
        newState.emailOptOut = action.value;
        return newState;
    } else if(action.type === "SET_DEFAULT_MEMBER_EMAIL_OPT_OUT") {
        initialState.emailOptOut = action.value;
        return {...state};
    } else if(action.type === "SET_MEMBER_CREATED_NEW_ACCOUNT") {
        let newState = {...state};
        newState.createdNewAccount = action.value;
        return newState;
    } else if (action.type === "SET_MEMBER_FIELD_ERROR") {
        let newState = {...state};
        newState.errors = { ...state.errors };
        newState.errors[action.payload.field] = action.payload.error;
        return newState;
    } else if (action.type === 'RECONCILE_PERSISTED_STATE') {
        return reconcilePersistedState(state, initialState);
    } else if (action.type === 'RESERVATION_DATA_SET') {
      const newState = { ...state };

      const { membership_autofill } = action.reservation.attributes;

      if (!membership_autofill) return newState;
      
      const { firstName, lastName, email } = membership_autofill;
      newState.name = `${firstName} ${lastName}`;
      newState.email = email;

      return newState;
    } else {
        return state;
    }
}
