import { wrappedFetch } from "../../checkout/helpers/fetch";

export function sendSlackMessage(message, type) {
    return (dispatch, getState) => {
        let state = getState();
        let req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({message, type, user: state.reservation.purchaseUser}),
        };

        wrappedFetch(state.ui.apiUrl + '/kiosks/send-notification', req).then((res) => {});
    }
}
