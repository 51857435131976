import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';
import {saveOptInQuestionAnser} from '../../../actions/reservation';

class QuestionOptIn extends Component {

  //remove <p></p>
  getOptInText = () => {
    let str = this.props.opt_in_question.opt_in_text;
    if (str.startsWith('<p>')) {
      str = str.substring(3);
    }
    if (str.endsWith('</p>')) {
      str = str.substring(0, str.length - 4);
    }
    return str;
  }

  componentDidMount() {
    //default OPTED_OUT
    this.submitAnswer(); 
  }

  submitAnswer = (e) => {
    let payload ={
      question_resource_map_id: this.props.opt_in_question.resource_id,
      answer: e ? e.target.checked : false,
    };
    this.props.saveOptInQuestionAnser(this.props.opt_in_question.question_id,payload);
  } 
  
  render() {
    return (
      <section className="checkout-form__section">
        <div className="input">
          <div className={`input__checkbox tos__checkbox`}>
            <div className="input__checkbox-wrap" >
              <div className="input__checkbox__input">
                <input type="checkbox" name="opt_in_question" id={`opt_in_question${this.props.opt_in_question.question_id}`}  onChange={this.submitAnswer} />
              </div>

              <label 
                htmlFor={`opt_in_question${this.props.opt_in_question.question_id}`} 
                className="input__checkbox__label" 
                dangerouslySetInnerHTML={{__html: this.getOptInText()}} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

QuestionOptIn.defaultProps = {
}

QuestionOptIn.propTypes = {
  opt_in_question: PropTypes.object,
};

const mapStateToProps = state => {
  return {
      token: state.reservation.token,
      secret: state.reservation.secret,
  }
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    saveOptInQuestionAnser,
  }, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(QuestionOptIn)

