import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFee {
    type: 'delivery' | 'insurance';
    price: number;
    amount: number;
}

interface IFeesState {
    deliveryFee: IFee | {};
    insuranceFee: IFee | {};
    totalFees: number;
}

const initialState: IFeesState = {
    deliveryFee: {},
    insuranceFee: {},
    totalFees: 0
};


export const feesSlice = createSlice({
  name: 'fees',
  initialState,
  reducers: {
    clearFees: () => ({ ...initialState }),
    setFee: (state, { payload }: PayloadAction<IFee>) => {
        let newState = {...state};

        let totalFees = 0;
        if (payload.type === 'delivery') {
            newState.deliveryFee = payload.price;
        } else if (payload.type === 'insurance') {
            newState.insuranceFee = payload.price;
        }

        // @ts-ignore idk why it's angry about these, they're definitely defined
        let deliveryFeeAmount = Object.keys(newState.deliveryFee).length === 0 ? 0 : newState.deliveryFee.amount;
        // @ts-ignore
        let insuranceFeeAmount = Object.keys(newState.insuranceFee).length === 0 ? 0 : newState.insuranceFee.amount;

        totalFees += deliveryFeeAmount + insuranceFeeAmount;
        newState.totalFees = totalFees;

        return newState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('RESERVATION_DATA_SET', () => ({...initialState}));
    builder.addCase('RESERVATION_CLEARED', () => ({...initialState}));
    builder.addCase('RESERVATION_CREATED', () => ({...initialState}));
  },
});
