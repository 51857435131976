import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export class Message extends Component {
  render() {
    const classes = clsx({
      message: true,
      'message__inline-text': this.props.inlineText,
      'message__inline-banner': this.props.inlineBanner,
      'message__page-banner': this.props.pageBanner,
      'message--error': this.props.error,
      'message--success': this.props.success,
      'message--caution': this.props.caution,
      'message--info': this.props.info,
    });

    return (
      <div className={classes}>
        <span className="message__icon">
          {this.props.error && (
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                <path d="M13,13H11V7H13M12,17.3A1.3,1.3 0 0,1 10.7,16A1.3,1.3 0 0,1 12,14.7A1.3,1.3 0 0,1 13.3,16A1.3,1.3 0 0,1 12,17.3M15.73,3H8.27L3,8.27V15.73L8.27,21H15.73L21,15.73V8.27L15.73,3Z" />
              </svg>
            </i>
          )}
          {this.props.caution && (
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
              </svg>
            </i>
          )}
          {this.props.success && (
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
              </svg>
            </i>
          )}
          {this.props.info && (
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                <path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
              </svg>
            </i>
          )}
        </span>
        <span className="message-text">{this.props.title}</span>
      </div>
    );
  }
}

Message.propTypes = {
  title: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Message;
