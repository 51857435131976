import React, { useState } from "react";

export enum MessagePageBannerVariant {
  error = "message--error",
  success = "message--success",
  caution = "message--caution",
  info = "message--info",
}

interface IMessagePageBannerProps {
  children: React.ReactNode,
  variant?: MessagePageBannerVariant,
  dismissable?: boolean,
  onClose?: () => void,
}

export const MessagePageBanner = ({
  children,
  variant = MessagePageBannerVariant.caution,
  dismissable = true,
  onClose = () => {},
}: IMessagePageBannerProps) => {
  const [isVisible, setVisibility] = useState(true);
    return isVisible && (
      <div className={`message message__page-banner ${variant}`}>
        <span className={`message-text ${dismissable ? "" : "message__not-dismissable"}`}>{children}</span>
        <div className="message__action">
          {dismissable &&
            <button className="message__dismiss" onClick={() => {
              setVisibility(false);
              onClose();
            }}>
              <i className="icon-close" aria-hidden="true"><span className="sr-only">Close Modal</span></i>
            </button>
          }
        </div>
      </div>
    );
}
