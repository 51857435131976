import { createSelector } from 'reselect';
import { POSITION_POPUP_BEFORE_REGISTRATION, UPSELL_TYPE_PRODUCT } from '../reducers/upsells';

const getUpsells  = state => state.upsells.upsells;
const getTickets  = state => state.tickets.tickets;
const getProducts = state => state.productSales.products;
const getLoadingStates = state => state.loading.states;

/**
 * Check if there are any upsells loaded that are configured to popup before registration
 * @return bool
 */
export const hasPopupUpsells = createSelector(
    [getUpsells, getTickets],
    (upsells, tickets) => {
        const eventsFromTickets = new Set(
          tickets
            .map(t => {
              const result = [t.event_id];

              if (t.parent_event_id && !isNaN(t.parent_event_id)) {
                result.push(t.parent_event_id);
              }

              return result
            })
            .flat()
          );

        // filter upsells by event ids on tickets that are related to current listing
        const upsellsForCurrentEvents = upsells.filter(upsell =>
          upsell.upsell?.listings_events?.some((id) => eventsFromTickets.has(+id)));

        return !!filterUpsells(upsellsForCurrentEvents, POSITION_POPUP_BEFORE_REGISTRATION).length;
    }
);

/**
 * Get popup product upsells and group them by upsell ID
 */
export const groupPopupProductUpsellsByUpsellId = createSelector(
    [getUpsells],
    (upsells) => {
        const grouped = {};
        const filtered = filterUpsells(upsells, POSITION_POPUP_BEFORE_REGISTRATION, UPSELL_TYPE_PRODUCT);
        filtered.forEach(upsell => {
            if (upsell?.upsell?.id) {
                if (!Array.isArray(grouped[upsell.upsell.id])) {
                    grouped[upsell.upsell.id] = [];
                }
                grouped[upsell.upsell.id].push(upsell);
            }
        });
        return grouped;
    }
);

/**
 * Filter list of loaded upsells
 */
const filterUpsells = (upsells, position = null, type = null) => {
    return upsells.filter(upsell => {
        if (position && upsell?.upsell?.position != position) {
            return false;
        }
        if (type && +upsell?.upsell?.type !== +type) {
            return false;
        }
        return true;
    });
}

/**
 * Get the count of upsold items carted
 * @param upsellId    if provided, filter by items that match this upsell ID
 * @param eventId     if provided, filter by tickets whose event ID or parent event ID matches
 * @param isSeries    if event ID provided and this is true, match against parent event id
 * @param productId   if provided, filter by products whose product ID matches
 */
export const countCartedItemsForUpsell = (upsellId = null, eventId = null, isSeries = false, productId = null) => {
    return createSelector(
        [getTickets, getProducts],
        (tickets, products) => {
            let count = 0;
            tickets.forEach(ticket => {
                if ((upsellId && +upsellId === +ticket.listing_upsell_id) || (!upsellId && ticket.listing_upsell_id)) {
                    if (eventId) {
                        if (+ticket.event_id === +eventId || (+ticket.parent_event_id === +eventId && isSeries)) {
                            count++;
                        }
                    } else {
                        count++;
                    }
                }
            });
            products.forEach(product => {
                if ((upsellId && +upsellId === +product.listing_upsell_id) || (!upsellId && !!product.listing_upsell_id)) {
                    if (!productId || +productId === +product.product_id) {
                        count++;
                    }
                }
            });
            return count;
        }
    );
}

/**
 * Are there any active requests to cart upsell items
 */
export const upsellRequestLoading = createSelector(
    [getUpsells, getLoadingStates],
    (upsells, loadingStates) => {
        let isLoading = false;
        const keysToCheck = [];
        upsells.forEach(upsell => {
            if (+upsell?.upsell?.type === UPSELL_TYPE_PRODUCT) {
                keysToCheck.push(getProductUpsellLoadingKey(upsell?.upsell?.id, upsell?.upsell?.item_id));
            } else {
                keysToCheck.push(getEventUpsellLoadingKey(upsell?.upsell?.id, upsell?.event?.event_id));
            }
        });
        keysToCheck.forEach(key => {
            if (loadingStates?.[key] == 'REQUEST') {
                isLoading = true;
            }
        });
        return isLoading;
    }
);

export const getEventUpsellLoadingKey = (upsellId, eventId) => {
    return `UPSELL_${upsellId}_${eventId ?? ''}_UPDATE_RESERVATION`;
}

export const getProductUpsellLoadingKey = (upsellId, itemId) => {
    return `PRODUCT_UPSELL_${upsellId}_${itemId}_UPDATE_RESERVATION`;
}
