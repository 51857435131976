import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import CancelButton from '../../checkout/components/CancelButton'
import CheckoutButton from '../../ticket-picker/containers/CheckoutButton';
import SubmitStepButton from '../../checkout/components/SubmitStepButton';
import { isCurrentRouterState } from '../../../selectors/ui';
import HelpButtonContainer from '../../../../listing/views/help/containers/HelpButtonContainer';
import KioskSelectLanguageButtonContainer from './KioskSelectLanguageButtonContainer';

class KioskFooterContainer extends Component {

    renderButton = () => {
        // hide the button for upsell popup
        if (this.props.inUpsellPopup) {
            return null;
        }
        return this.props.inCheckout ? <SubmitStepButton /> : <CheckoutButton />
    }

    render() {

        if (this.props.inWaiver) {
            return null;
        }

        return(
            <div className="kiosk__footer">
                <div>
                    <CancelButton />
                    <HelpButtonContainer />
                    <KioskSelectLanguageButtonContainer />
                </div>
                {this.renderButton()}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        inUpsellPopup: !!state.upsells.showPopup,
        inCheckout: isCurrentRouterState('checkout')(state),
        inWaiver: isCurrentRouterState('kioskWaiverView')(state),
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

KioskFooterContainer.propTypes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(KioskFooterContainer)
