import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '@s/Dropdown';
import { TranslationContext } from '../../../../checkout/config/Translations';
import { getUsersFetch } from '../actions/kiosk-management';

const UserSelect = props => {
    const dispatch = useDispatch();
    const [ userList, setUserList ] = React.useState([]);
    const translate = React.useContext(TranslationContext).t;

    React.useEffect(() => {
        return dispatch(getUsersFetch(props.listingSlug)).then((res) => {
            if(res.status === 200) {
                return res.json();
            }
        }).then((res) => {
            setUserList([...userList].concat(res.users));
        }).catch((e) => {});
    }, []);

    return(
        <div className="input">
            <div className="input__horizontal">
                <label className="input__label" htmlFor="userSelect">
                    {translate('checkout__kiosk_user')}
                </label>
                <Dropdown
                    id="userSelect"
                    className="input__control"
                    value={props.user}
                    options={userList}
                    onChange={id => {props.onUserSelect(id)}}
                    placeholder={translate('checkout__select_a_kiosk_user')}
                />
            </div>
        </div>
    );
}

UserSelect.propTypes = {
    user: PropTypes.any,
    onUserSelect: PropTypes.func.isRequired,
    apiUrl: PropTypes.string.isRequired,
    listingSlug: PropTypes.string.isRequired,
}

UserSelect.defaultProps = {
}

export default UserSelect;
