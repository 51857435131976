import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PresentTime from '../../time/components/PresentTime';
import NextAvailableTimeSlot from '../../series/containers/NextAvailableTimeSlot';
import ReactDOM from 'react-dom';

const KIOSK_TIME_CONTAINER_ID = "kiosk__time__container";

class KioskTimeContainer extends Component {

    render() {
        return(
            ReactDOM.createPortal(
                <div className="kiosk__time">
                    <div className="kiosk__time--current">
                        <PresentTime />
                    </div>
                    <div className="kiosk__time--next-avaliable">
                        <NextAvailableTimeSlot />
                    </div>
                </div>
            ,document.getElementById(KIOSK_TIME_CONTAINER_ID))
        )
    }
}

KioskTimeContainer.propTypes = {
}

export default KioskTimeContainer;
