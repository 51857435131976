import {event} from './event';
import {venueSlice} from '../slices/venue';
import {partner} from './partner';
import {priceLevel} from './price-levels';
import {products} from './products';
import {reservation} from './reservation';
import {tickets} from './tickets';
import {productSales} from './product-sales';
import {bundleSales} from './bundle-sales';
import {ticketPicker} from '../views/ticket-picker/reducers/ticket-picker';
import {productPicker} from '../views/product-picker/reducers/product-picker';
import {bundlePicker} from '../views/bundle-picker/reducers/bundle-picker';
import {member} from '../views/member/reducers/member';
import {delivery} from '../views/delivery/reducers/delivery';
import {questions} from '../views/questions/reducers/questions';
import {coupon} from '../views/coupon/reducers/coupon';
import {couponTag} from '../views/coupon/reducers/couponTag';
import {eventSeries} from '../views/series/reducers/event-series';
import {donations} from '../views/donations/reducers/donations';
import {ui} from './ui';
import {payment} from './payment';
import {confirmationSlice} from '../slices/confirmation';
import {bundles} from './bundles';
import {hooks} from './hooks';
import {loadingSlice} from '../slices/loading';
import {insurance} from '../views/insurance/reducers/insurance';
import {weather} from '../views/insurance/reducers/sensible-weather';
import {feesSlice} from '../slices/fees';
import {settingsSlice} from '../slices/settings';
import {timeout} from '../views/timeout-prompt/reducers/timeout';
import {intl} from './intl';
import {contactInfoSlice} from '../slices/contact-info';
import {socialSettings} from './socialSettings';
import {keyboard} from './keyboard';
import {reservedSeating} from './reservedSeating';
import {timeSlice} from '../slices/time';
import {kioskManagement} from '../../listing/views/kiosk-management/reducers/kioskManagement';
import {inventoryFilter} from '../views/series/reducers/inventory-filter';
import {livestreamSlice} from '../slices/livestream';
import {upsells} from '../views/upsells/reducers/upsells';

export default {
    event,
    venue: venueSlice.reducer,
    partner,
    priceLevel,
    products,
    productSales,
    productPicker,
    ticketPicker,
    bundleSales,
    reservation,
    tickets,
    ui,
    member,
    delivery,
    payment,
    confirmation: confirmationSlice.reducer,
    questions,
    bundles,
    bundlePicker,
    hooks,
    coupon,
    couponTag,
    loading: loadingSlice.reducer,
    eventSeries,
    fees: feesSlice.reducer,
    insurance,
    timeout,
    intl,
    contactInfo: contactInfoSlice.reducer,
    donations,
    socialSettings,
    keyboard,
    time: timeSlice.reducer,
    settings: settingsSlice.reducer,
    kioskManagement,
    inventoryFilter,
    livestream: livestreamSlice.reducer,
    upsells,
    reservedSeating,
    weather
};
