import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Translation from '@s/Translation';
import { getSelectedEvent } from '../../../selectors/event';
class CouponAlertsContainer extends Component {

    render() {
        return(
            <Fragment>
                { this.props.hasPresale && this.props.isPresaleCodeApplied && <div className="alert alert--success">
                    <p><Translation>{'checkout__presale_code_has_been_applied'}</Translation></p></div>
                }
                { this.props.showAccessCodeSuccess === true && <div className="alert alert--success">
                    <p><Translation>{'checkout__access_code_has_been_applied'}</Translation></p></div>
                }
                { this.props.showSuccess && <div className="alert alert--success">
                    <p><Translation>{'checkout__discount_will_be_applied_to_your_order'}</Translation></p></div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        hasPresale: !!getSelectedEvent(state)?.event?.hasPresale,
        isPresaleCodeApplied: state.coupon.isPresaleCodeApplied,
        showAccessCodeSuccess: state.coupon.showAccessCodeSuccess,
        showSuccess: state.coupon.showSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    },
    dispatch);
}

CouponAlertsContainer.propTypes = {
    hasPresale: PropTypes.bool,
    isPresaleCodeApplied: PropTypes.bool,
    showAccessCodeSuccess: PropTypes.bool,
    showSuccess: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponAlertsContainer)
