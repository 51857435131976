import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@s/Dropdown';
import Message from '@s/Message';
import { setTerminals } from 'checkout/actions/payment';
import { TranslationContext } from '../../../../checkout/config/Translations';

const TerminalSelect = props => {

    const translate = React.useContext(TranslationContext).t;

    return(
        <div className="input">
            <div className="input__horizontal">
                <label className="input__label" htmlFor="terminalSelect">{translate('checkout__emv_terminal')}</label>
                {props.terminals.length ?
                    <Dropdown
                        id="terminalSelect"
                        className="input__control"
                        value={props.terminal}
                        options={props.terminals}
                        onChange={props.onTerminalSelect}
                        placeholder={translate('checkout__select_terminal')}
                    />
                    :
                    <Message caution inlineText title={translate('checkout__no_terminal_available')} />
                }
            </div>
        </div>
    )
}

TerminalSelect.propTypes = {
    sellerId: PropTypes.any,
    terminal: PropTypes.string,
    apiUrl: PropTypes.string.isRequired,
    onTerminalSelect: PropTypes.func.isRequired,
};

TerminalSelect.defaultProps = {
};

export default TerminalSelect;
