import { createSelector } from 'reselect';

const getSelectedTickets = state => Object.values(state.ticketPicker.quantities);
const getSelectedBundles = state => Object.values(state.bundlePicker.bundleQuantities);
const getProductsQuantities = state =>  state.productPicker.productsQuantities;
const getProductConfigurationQuantities = state =>  state.productPicker.configQuantities;
const getProducts = state =>  state.products.products;
const getBundles = state => state.bundles.bundles;
const getBundlePickerQuantities = state => state.bundlePicker.bundleQuantities;
const getSelectedBundleConfigurations = state => state.bundlePicker.selectedConfigurations;

export const getTotalSelectedItems = createSelector(
    [getSelectedTickets, getSelectedBundles],
    (tickets, bundles) => {
        return getTotalSelectedItemsFromState(tickets, bundles);
    }
);

export const getTotalSelectedItemsFromState = (ticketQuantitiesArray, bundleQuantitiesArray) => {
    let ticketCount = ticketQuantitiesArray.reduce((total, val) => {
        return total + (val === undefined ? 0 : val);
    }, 0);

    let bundleCount = bundleQuantitiesArray.reduce((total, val) => {
        return total + (val === undefined ? 0 : val);
    }, 0);
    return ticketCount + bundleCount;
}

export const getProductErrors = createSelector(
    [getProductsQuantities, getProductConfigurationQuantities, getProducts],
    (productsQuantities, configurationQuantities, products) => {
        return getProductErrorsFromState(productsQuantities, configurationQuantities, products);
    }
);

export const getProductErrorsFromState = (productsQuantities, configurationQuantities, products) => {
    let productsWithErrors = {};
    for (const productId in productsQuantities) {
        if (productsQuantities[productId] !== undefined &&
            (configurationQuantities[productId] === undefined ||
                productsQuantities[productId] !==
                    Object.keys(configurationQuantities[productId]).length)) {
            if (!productsWithErrors[productId]) {
                productsWithErrors[productId] = [{
                    productId: products[productId].product_id,
                    productName: products[productId].name,
                    optionName: products[productId].configurations[0].optionName,
                }];
            }
        }
    }
    return productsWithErrors;
}

export const getBundleErrors = createSelector(
    [getBundles, getBundlePickerQuantities, getSelectedBundleConfigurations],
    (bundles, bundlePickerQuantities, selectedBundleConfigurations) => {
        return getBundleErrorsFromState(bundles, bundlePickerQuantities, selectedBundleConfigurations);
    }
);

export const getBundleErrorsFromState = (bundles, bundlePickerQuantities, selectedBundleConfigurations) => {
    let erroredBundleProducts = {}
    for (const bundleId in bundlePickerQuantities) {
        if (bundles[bundleId].contents.products) {
            Object.values(bundles[bundleId].contents.products).map(product => {
                if (bundlePickerQuantities[bundleId] !== undefined &&
                    (selectedBundleConfigurations[bundleId] === undefined ||
                        (selectedBundleConfigurations[bundleId] !== undefined &&
                            ((selectedBundleConfigurations[bundleId][product.product_id]) === undefined ||
                                (!product.single_configuration && product.quantity * bundlePickerQuantities[bundleId] !== Object.keys(selectedBundleConfigurations[bundleId][product.product_id]).length)))
                    )
                ){
                    if (!erroredBundleProducts[product.product_id]) {
                        erroredBundleProducts[product.product_id] = [{
                            productId: bundles[bundleId].contents.products[product.product_id].product_id,
                            productName:bundles[bundleId].contents.products[product.product_id].name,
                            optionName: bundles[bundleId].contents.products[product.product_id].configurations[0].optionName,
                        }];
                    }
                }
            });
        }
    }
    return erroredBundleProducts;
}
