// @flow
import type { ClearAction } from './reservation';

export type BundleSalesAction = { type: 'SET_BUNDLE_SALES', bundles: Array<Object> };

export type Action = BundleSalesAction | ClearAction;

import { formatInventoryItemFromServer } from '../helpers/inventoryItems';

type State = { +bundles: Array<Object> };

let initialState = { bundles: [], namesOnBundles: {} };

export function bundleSales(state: State = initialState, action: Action): State {
  if (action.type === 'SET_BUNDLE_SALES') {
    let newState = { ...state };
    newState.bundles = [];
    for (var i = 0; i < action.bundles.length; i++) {
      newState.bundles.push(formatInventoryItemFromServer(action.bundles[i]));
    }

    return newState;
  } else if (action.type === 'SET_RAW_BUNDLES_SALES_STATE') {
    return action.state;
  } else if (action.type === 'SET_BUNDLE_SALE_NAME') {
    return {
      ...state,
      namesOnBundles: {
        ...state.namesOnBundles,
        [action.id]: action.value,
      },
    };
  } else if (action.type === 'RESERVATION_CLEARED') {
    return initialState;
  } else {
    return state;
  }
}
