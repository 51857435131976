import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEvent } from '../actions/event';
import { reservationActive } from '../selectors/reservation';

/**
 * Calls end point on an interval to refresh listing data
 */
const intervalInSeconds = 300;

class RefreshListingData extends Component {
    state = {
        interval: null,
    };

    componentDidMount() {
        this.props.fetchEvent();
        this.setState({interval:setInterval(() => {
            if (this.props.refreshOnInterval) {
                this.props.fetchEvent();
            }
        }, intervalInSeconds * 1000)});
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
        this.setState({interval: null});
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        //do not refresh if
        //  1. User is actively using the listing - don't want to hurt UX
        //  2. There is already a refresh in progress - avoid overlapping requests
        refreshOnInterval: !reservationActive(state) && !state.ui.overlay && !state.event.refreshLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchEvent,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(RefreshListingData)
