import { hasQuestions, getAnswers, isQuestionComplete } from '../../../selectors/questions';
import { getCheckoutClient } from '../../../config/CheckoutClient';
import { addAlert } from '../../../actions/ui';
import { questionValidators } from '../helpers/questions-validation';
import { TYPE_ADDRESS, TYPE_PHONE } from '../reducers/questions';

export function setAnswer(questionId, id, resourceType, answer) {
  return { type: 'SET_ANSWER', questionId, id, resourceType, answer };
}

export function updateQuestion(questionId, id, resourceType, field, value) {
  return { type: 'UPDATE_QUESTION', questionId, id, resourceType, field, value };
}

export function setQuestions(questionSet) {
  return { type: 'SET_QUESTIONS', questionSet };
}

export function submitQuestions() {
  return (dispatch, getState) => {
    let state = getState();
    if (!hasQuestions(state)) {
      return Promise.resolve();
    }

    if (isQuestionComplete(state)) {
      dispatch(addAlert('checkout__please_answer_all_required_questions'));
      return Promise.reject('Please answer all required questions');
    }

    let payload = {
      answers: getAnswers(state)
    };

    return getCheckoutClient(state.ui.apiUrl)
      .reservationCreateRequest(state.reservation.token, 'answers', payload, state.reservation.secret)
      .then(response => {
        // don't need to really do anything in here
        // maybe check unanswered required flag in here?
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
}

export function fetchQuestions(token, secret) {
  return (dispatch, getState) => {
    let state = getState();
    return getCheckoutClient(state.ui.apiUrl)
      .reservationGetRequest(token, 'questions', secret)
      .then(result => {
        dispatch(setQuestions(result.body.data.attributes.question_set));
        return Promise.resolve();
      });
  };
}

export function validateQuestion(question) {
  return (dispatch, getState) => {
    const {
      question_type,
      answer: answerProp,
      required: requiredProp,
      pre_validation,
      resource_id,
      answer_resource_id,
      answer_resource_type
    } = question;

    const locale = getState().intl.locale;

    // No validation for Address
    if (question_type == TYPE_ADDRESS) {
      return;
    }

    let answer = typeof answerProp === 'string' ? answerProp?.trim() : answerProp;

    if (question_type == TYPE_PHONE) {
      answer = answer?.slice(answer?.indexOf(':') + 1);
    }

    const required = Boolean(Number(requiredProp));

    if (!required && !answer) return;

    if (required && !answer) {
      dispatch(
        updateQuestion(resource_id, answer_resource_id, answer_resource_type, 'error', {
          message: 'checkout__answer_required'
        })
      );

      return;
    }

    const validate = questionValidators[question_type];

    if (!validate) {
      return;
    }

    const error = validate(answer, pre_validation, locale);

    if (!error) {
      dispatch(updateQuestion(resource_id, answer_resource_id, answer_resource_type, 'error', undefined));
      return;
    }

    dispatch(updateQuestion(resource_id, answer_resource_id, answer_resource_type, 'error', error));
  };
}
