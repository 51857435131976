// convert  [1,2,3,4] to '1-4',see  Ticket#groupSeatRanges
const groupStrings = seats => {
  const sortedSeats = seats
      .filter(item => typeof item === 'string' && isNaN(item))
      .sort();
  const ranges = [];
  let start = sortedSeats[0];
  let end = sortedSeats[0];
  for (let key = 0; key < sortedSeats.length; key++) {
      const seat = sortedSeats[key];
      if (key < sortedSeats.length - 1 && seat.charCodeAt(0) + 1 === sortedSeats[key + 1].charCodeAt(0)) {
          end = sortedSeats[key + 1];
      } else {
          if (start === end) {
              ranges.push(start);
          } else {
              ranges.push(start + '-' + end);
          }
          if (key < sortedSeats.length - 1) {
              start = sortedSeats[key + 1];
              end = sortedSeats[key + 1];
          }
      }
  }
  return ranges.join(', ');
}

const groupNumbers = seats => {
  const sortedSeats = seats
      .filter(item =>!isNaN(parseInt(item, 10)))
      .map(function (seat) {return parseInt(seat)})
      .sort(function (a, b) {return a - b});
  const ranges = [];
  let start = sortedSeats[0];
  let end = sortedSeats[0];
  for (let key = 0; key < sortedSeats.length; key++) {
      const seat = sortedSeats[key];
      if (key < sortedSeats.length - 1 && seat + 1 === sortedSeats[key + 1]) {
          end = sortedSeats[key + 1];
      } else {
          if (start === end) {
              ranges.push(start);
          } else {
              ranges.push(start + '-' + end);
          }
          if (key < sortedSeats.length - 1) {
              start = sortedSeats[key + 1];
              end = sortedSeats[key + 1];
          }
      }
  }
  return ranges.join(', ');
}

export const getSeatsRange = seats => {
  const n = groupNumbers(seats);
  const s = groupStrings(seats);
  if (n.length === 0){
    return s;
  }
  if (s.length === 0){
    return n;
  }
  return [n,s].join(', ');
}
