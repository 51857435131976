import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';

import Alert from '../components/Alert';
import {removeAlert} from '../../../actions/ui';
import ContainerProps from './AlertContainerComponent';

class AlertContainer extends Component<ContainerProps> {
    render() {
        return(
            this.props.alerts.map((alert, i) => {
                return <Alert alert={alert} key={i} onDismiss={this.onDismiss} index={i}/>
            })
        )

    }

    onDismiss = (index) => {
        return () => {
            this.props.removeAlert(index);
        }
    }
}

const mapStateToProps = state => {
    return {
        alerts: state.ui.alerts
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        removeAlert
    }, dispatch);
}

AlertContainer.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer)
