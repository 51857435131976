import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IVenueState {
    loading: boolean;
    venues: object;
}

const initialState: IVenueState = {
    loading: true,
    venues: {}
};

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase('FETCH_EVENT', (state, action) => {
            let newState = {...state, loading: false};
            newState.venues = {};
            // @ts-ignore
            for (let id in action.data.attributes.events) {
                // @ts-ignore
                newState.venues[id] = action.data.attributes.events[id].venue;
            }

            return newState;
        })
        .addDefaultCase((state, action) => {
            return state;
        });
  },
});

export default venueSlice.reducer;

