import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import SelectLanguage from '../../../../listing/components/SelectLanguage';
import { setLanguageModal } from '../../../actions/ui';

class KioskSelectLanguageContainer extends Component {
    render() {
        return(
            <SelectLanguage
                languageModal={this.props.languageModal}
                setLanguageModal={(val) => this.setLanguageModal(val)}
            />
        )
    };

    setLanguageModal = (val) => {
        this.props.setLanguageModal(val);
    };
}

const mapStateToProps = (state, props) => {
    return {
        languageModal: state.ui.languageModal,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setLanguageModal,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskSelectLanguageContainer);
