const initialState = {
    locale: navigator.language || navigator.userLanguage,
    serverLocale: null,
    languages: [],
    loadedTranslationsByServerLocale: {},
    changedLanguageCount: 0,
    defaultLocale: null,
    defaultServerLocale: null,
};

export function intl(state = initialState, action) {

    if(action.type === 'SET_JS_LOCALE') {
        return { ...state, locale: action.value };
    } else if(action.type === 'SET_DEFAULT_JS_LOCALE') {
        return { ...state, defaultLocale: action.value };
    } else if(action.type === "SET_LANGUAGES") {
        return {...state, languages: Array.isArray(action.value) ? [...action.value] : state.languages};
    } else if(action.type === "CHANGED_LANGUAGE") {
        return {...state, changedLanguageCount: state.changedLanguageCount + 1};
    } else if(action.type === "SET_SERVER_LOCALE") {
        const newState = {...state};
        if (typeof action.locale === 'string') {
            newState.serverLocale = action.locale;
            if (
                typeof action.translations === 'object' &&
                action.translations !== null
            ) {
                newState.loadedTranslationsByServerLocale[action.locale] = {...action.translations};
            }
        }
        return newState;
    } else if(action.type === "SET_DEFAULT_SERVER_LOCALE") {
        return { ...state, defaultServerLocale: action.value };
    } else if(action.type === "FETCH_EVENT") {
        let event = Object.values(action.data.attributes.events)[0].event;
        return {
            ...state,
            defaultLocale: event.defaultJsLocale,
            defaultServerLocale: event.defaultServerLocale
        };
    }
    return state;
}
