let initialState = {
    actionCounter: 0,
    showTimeoutPrompt: false,
    timeoutTracker: null,
    hardResetInterval: null,
    shouldTimeout: false,
}

let skipActions = [
    "TICK",
    "SET_CURRENT_TIME",
    "SET_SHOW_TIMEOUT_OVERLAY",
    "SET_TIMEOUT_TRACKER",
    "SET_HARD_RESET_INTERVAL",
    "CLEAR_TIMEOUT_TRACKER",
    "CLEAR_HARD_RESET_INTERVAL",
];

export function timeout(state: State = initialState, action:Action): State {
    if(skipActions.indexOf(action.type) === -1) {
        let newState = {...state};
        newState.actionCounter = newState.actionCounter + 1;
        return newState;
    } else if(action.type === "SET_SHOW_TIMEOUT_OVERLAY") {
        let newState = {...state};
        newState.showTimeoutPrompt = action.value;
        return newState;
    } else if(action.type === "SET_TIMEOUT_TRACKER") {
        let newState = {...state};
        newState.timeoutTracker = action.value;
        return newState;
    } else if(action.type === "SET_HARD_RESET_INTERVAL") {
        let newState = {...state};
        newState.hardResetInterval = action.value;
        return newState;
    } else if(action.type === "CLEAR_TIMEOUT_TRACKER") {
        clearTimeout(state.timeoutTracker);
        let newState = {...state}
        newState.timeoutTracker = null;
        return newState;
    } else if(action.type === "CLEAR_HARD_RESET_INTERVAL") {
        clearInterval(state.hardResetInterval);
        let newState = {...state}
        newState.hardResetInterval = null;
        return newState;
    } else {
        return state;
    }
}
