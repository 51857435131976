import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux';

class InteractionBlock extends PureComponent {
    render() {
        if(this.props.interactionBlock === true) {
            return(
                <div id="interaction-block"
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: 500,
                }}></div>
            )
        }

        return null;
    }
}

const mapStateToProps = state => {
    return {
        interactionBlock: state.ui.interactionBlock,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InteractionBlock)
