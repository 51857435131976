import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { UIRouterReact } from '@uirouter/react';
import { createRouterMiddleware, routerReducer } from '@uirouter/redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../checkout/reducers/index';
import InitRollbar from '@c/InitRollbar';
import reduxDevToolsComposeOptions from '../listing/helpers/reduxDevtoolsConfig';
import { getOrderTotal } from "../checkout/selectors/cart";
import { totalCostChange } from "../checkout/actions/ui";
import { getTopWindow } from '@h/window';

const setupApp = (brandName, actionCallback = null, persistSuffix = '') => {

    InitRollbar(brandName);
    const router = new UIRouterReact();
    // match with or without trailing slash
    // https://ui-router.github.io/react/docs/0.8.9/classes/url.urlconfig.html#strictmode
    router.urlService.config.strictMode(false);
    const routerMiddleware = createRouterMiddleware(router);

    // @ts-ignore
    const reduxDevConfig = {
        ...reduxDevToolsComposeOptions,
        name: `checkout_${new Date().getTime().toString()}`,
    };

    // if we are in an iframe, attach to the top window
    let windowToAttachTo = getTopWindow();

    const composeEnhancers = windowToAttachTo.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? windowToAttachTo.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevConfig) : compose;

    const compareTotalCost = (oldValue, newValue) =>
        JSON.stringify(oldValue) === JSON.stringify(newValue);

    const dispatchTotalCostChangeMiddleware = getTotal => store => next => action => {
        const oldTotal = getTotal(store.getState());
        let result = next(action);
        const newTotal = getTotal(store.getState());
        if (!compareTotalCost(oldTotal, newTotal)) {
            store.dispatch(totalCostChange(oldTotal, newTotal));
        }

        return result;
    };

    // general purpose middleware for subscribing to dispatched actions
    const actionCallbackMiddleware = store => next => action => {
        const result = next(action);
        if (typeof actionCallback === 'function') {
            actionCallback(action, store);
        }
        return result;
    }

    // config for persisting reducer state -- storage is LocalStorage for web
    const persistConfig = {
        key: 'root' + persistSuffix,
        storage: storage,
        /**
         * IMPORTANT: Reducers in this list should have a case for RECONCILE_PERSISTED_STATE
         * The exception is reducers that use createSlice from '@reduxjs/toolkit' as they
         * seem to handle properties missing from default defined state
         */
        whitelist: ['member', 'delivery', 'coupon', 'ui', 'fees']
    };

    // all of our reducers need to be wrapped by persistReducer even though we only actually persist based on the whitelist
    const persistedReducer = persistReducer(persistConfig, combineReducers({
        ...rootReducer,
        router: routerReducer
    }));

    // create store as normal with persisted reducers
    const store = createStore(
        persistedReducer,
        composeEnhancers(
            applyMiddleware(thunk, routerMiddleware, dispatchTotalCostChangeMiddleware(getOrderTotal), actionCallbackMiddleware)
        )
    );

    let reconciledState = false;

    // persist the store
    const persistor = persistStore(store, null, () => {
        // add any missing fields from default state to the rehydrated state
        if (!reconciledState) {
            store.dispatch({type: 'RECONCILE_PERSISTED_STATE'});
            reconciledState = true;
        }
    });

    return {
        router,
        persistor,
        store,
    };
}

export default setupApp;
