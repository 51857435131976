import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

export default class Skeleton extends Component {
  render() {
    return (
      <SkeletonBlock
        height={this.props.height}
        width={this.props.width}
        borderRadius={this.props.borderRadius}
        marginBottom={this.props.marginBottom}
        className={this.props.className}
        display={this.props.display}
      />
    );
  }
}
const skeletonGradient = keyframes`
  0% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonBlock = styled.div`
  display: ${props => props.display || "block"};
  width: ${props => props.width || "100px"};
  height: ${props => props.height || "100px"};
  background: rgb(238, 238, 238);
  border-radius: ${props => props.borderRadius || "0px"};
  margin-bottom: ${props => props.marginBottom || "0px"};
  overflow: hidden;
  &:after{
    content: "";
    background-image: linear-gradient(
      to right,
      rgb(238, 238, 238) 0%,
      rgb(242, 242, 242) 30%,
      rgb(244, 244, 244) 50%,
      rgb(242, 242, 242) 70%,
      rgb(238, 238, 238) 100%
    );
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    animation: 1.5s ${skeletonGradient} linear infinite;
  }
`;