import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Props from './AddTicketsComponent';
import { isKioskMode, isMultiEmbed } from '../../../selectors/ui';
import Translation from '@s/Translation';

class AddTickets extends Component<Props> {
    render() {
        return(
            <div className="ticket-quantity">
                {this.getContent()}
            </div>
        )
    }

    getContent = () => {
        let maxQuant = this.props.quantities[this.props.quantities.length -1];
        let soldOut = this.props.initialQuantities && this.props.initialQuantities.length === 0;
        let limitReached = this.props.quantities.length === 0;
        if (soldOut) {
            return <div className="ticket__price"><span><Translation>{'checkout__sold_out'}</Translation></span></div>;
        } else if(limitReached) {
            return <div className="ticket__price"><span><Translation>{'checkout__limit_reached'}</Translation></span></div>;
        } else {
            if (!this.props.showZeroQuantity && (this.props.quantity === undefined || this.props.quantity === 0)) {
                return (
                    <div className="ticket-action__add">
                        <button disabled={!!this.props.disabled} className="button button--primary ticket-action__add-button" onClick={this.props.onIncrement}><Translation>{'checkout__add'}</Translation></button>
                    </div>
                );
            } else {
                return (
                     <div className="ticket-action__quantity">
                        <button disabled={!!this.props.disabled} className="button button--default quantity__decrement" onClick={this.onDecrement}><i className="icon-remove" aria-hidden="true"></i><span className="sr-only"><Translation>{'checkout__subtract'}</Translation></span></button>

                        <select className="form-control quantity__input" name="quantity_input" onChange={this.props.onQuantityInput} type="tel" value={this.props.quantity === undefined ? 0 : this.props.quantity}>
                            {this.props.showZeroQuantity && this.props.quantities.indexOf(0) === -1 && <option>0</option>}
                            {this.props.quantities.map((num, index) => {
                                return <option key={index} value={num}>{num}</option>
                            })}
                        </select>
                        <span className="quantity__input quantity__input--mask">{this.props.quantity === undefined ? 0 : this.props.quantity}</span>
                        <button className="button button--primary quantity__increment" disabled={this.props.quantity >= maxQuant || !!this.props.disabled} onClick={this.props.onIncrement} autoFocus={!this.props.showZeroQuantity}><i className="icon-add" aria-hidden="true"></i><span className="sr-only"><Translation>{'checkout__add'}</Translation></span></button>
                    </div>
                );
            }
        }
    }

    onDecrement = () => {
        if (!isNaN(this.props.quantity) && this.props.quantity > 0) {
            this.props.onDecrement();
        }
    }
}

AddTickets.propTypes = {
    quantity: PropTypes.number,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onQuantityInput: PropTypes.func.isRequired,
    quantities: PropTypes.arrayOf(PropTypes.number).isRequired,
    initialQuantities: PropTypes.arrayOf(PropTypes.number)
};

const mapStateToProps = (state, ownProps) => {
    return {
        showZeroQuantity: isKioskMode(state) || isMultiEmbed(state) || !!ownProps.showZeroQuantity,
    }
};

export default connect(mapStateToProps)(AddTickets)
