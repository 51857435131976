import { createSelector } from 'reselect';
import { applyToParentAndAllSubReducers } from '../../../reducers/subReducerHelpers';
import { toDecimals } from '@h/currency';
import { getSeatsRange } from '../../../../helpers/seatsRange';
import {isPyos} from '../../../selectors/reserved-seating';

const getTickets = state => state.tickets.tickets;
const getPriceLevelState = state => state.priceLevel;
const getTicketPickerState = state => state.ticketPicker;
const isPyosEvent = state => isPyos(state);
const appliedTaggableDiscountCode = state => state?.couponTag?.appliedTaggableDiscountCode;

const isSeatedTicket = ticket => {
  return !!ticket?.is_seated;
};

/**
 * Combines all tickets into groups organized by event and then price level and then by listing upsell ID
 * End object is of the form:
 *  {
 *      <event_id>:[{price_level_id: <id>...}, {price_level_id: <id2>...}...],
 *      <event_id2>:[{price_level_id: <id>...}, {price_level_id: <id2>...}...]
 *  }
 */
export const getPriceLevelSelectionsByEvent = createSelector(
  [getTickets, getPriceLevelState, getTicketPickerState, isPyosEvent, appliedTaggableDiscountCode],
  (tickets, priceLevelState, ticketPickerState, isPyosEvent, appliedTaggableDiscountCode) => {
    const priceLevels = getAllLoadedPriceLevelsFromPriceLevelState(priceLevelState);
    const quantities = getAllLoadedQuantityArraysFromState(ticketPickerState);

    let levels = {};
    for (var i = 0; i < tickets.length; i++) {
      const level = priceLevels[tickets[i].price_level_id];
      if (!level) {
        continue;
      }

      if (!levels[tickets[i].event_id]) {
        levels[tickets[i].event_id] = [];
      }

      const isSeated = isSeatedTicket(tickets[i]);
      const isPartOfTimeslotBuyout = !!level.timeslot_buyout_settings
      const useCostAmount = isSeated || isPartOfTimeslotBuyout
      const useCostAmountOrPriceLevel = (appliedTaggableDiscountCode && isPyosEvent) ? false : useCostAmount;

      const costAmount = tickets[i].cost.amount;
      const priceLevelCostAmount = tickets[i].price_level_cost.amount
      const amount = useCostAmountOrPriceLevel ? costAmount : priceLevelCostAmount

      // if we've already added a ticket that matches this one, just increase the quantity, subtotal, etc.
      // match by price level ID, cost, and listing upsell ID

      let found = false;
      for (var j = 0; j < levels[tickets[i].event_id].length; j++) {
        let checkLevelAndUpsellId =
          levels[tickets[i].event_id][j].price_level_id === tickets[i].price_level_id &&
          levels[tickets[i].event_id][j].listing_upsell_id === tickets[i].listing_upsell_id;
        let samePrice = false;

        if (isSeated) {
          samePrice = +levels[tickets[i].event_id][j].price === +tickets[i]?.cost?.amount;
        } else {
          samePrice = +levels[tickets[i].event_id][j].price === +tickets[i]?.price_level_cost?.amount;
        }
        if (checkLevelAndUpsellId && samePrice || isPartOfTimeslotBuyout) {
          levels[tickets[i].event_id][j].total_price += tickets[i].cost.amount;
          levels[tickets[i].event_id][j].total_fee += tickets[i].buyer_fee.amount;
          levels[tickets[i].event_id][j].subtotal += amount + tickets[i].buyer_fee.amount;
          levels[tickets[i].event_id][j].quantity++;

          //seats group by row
          if (isSeated) {
            let found_row = false;
            for (let k = 0; k < levels[tickets[i].event_id][j].rows.length; k++) {
              if (levels[tickets[i].event_id][j].rows[k].row == tickets[i].seat_info.row) {
                levels[tickets[i].event_id][j].rows[k].seats.push(tickets[i].seat_info.seat);
                levels[tickets[i].event_id][j].rows[k].seats_info = getSeatsRange(
                  levels[tickets[i].event_id][j].rows[k].seats
                );
                found_row = true;
                break;
              }
            }
            if (!found_row) {
              let _seat_info = tickets[i].seat_info;
              _seat_info.seats = [_seat_info.seat];
              _seat_info.seats_info = _seat_info.seat;
              levels[tickets[i].event_id][j].rows.push(_seat_info);
            }
          }
          found = true;
          break;
        }
      }

      if (!found) {
        let _seat_info = null;
        if (isSeated) {
          _seat_info = tickets[i].seat_info;
          _seat_info.seats = [_seat_info.seat];
          _seat_info.seats_info = _seat_info.seat;
        }
        let priceLevelEntry = {
          price_level_id: tickets[i].price_level_id,
          name: tickets[i].price_level_name,
          price: tickets[i].price_level_cost.amount,
          total_price: tickets[i].cost.amount,
          fee: tickets[i].buyer_fee.amount,
          total_fee: tickets[i].buyer_fee.amount,
          quantity: 1,
          subtotal: amount + tickets[i].buyer_fee.amount,
          currency: tickets[i].cost.currency,
          currency_symbol: tickets[i].cost.symbol,
          event_id: tickets[i].event_id,
          quantities: quantities[tickets[i].price_level_id],
          description: priceLevels[tickets[i].price_level_id].description,
          listing_upsell_id: tickets[i].listing_upsell_id,
          is_seated: isSeated,
          timeslot_buyout_settings: level.timeslot_buyout_settings,

          rows: isSeated ? [_seat_info] : []
        };
        if (!priceLevelEntry.is_seated) {
          priceLevelEntry.price = tickets[i]?.price_level_cost?.amount;
        }
        levels[tickets[i].event_id].push(priceLevelEntry);
      }
    }
    return levels;
  }
);

export const getPriceLevelsWithUnmetMinimums = createSelector(
  [getTickets, getPriceLevelState],
  (tickets, priceLevelState) => {
    const levels = Object.values(priceLevelState.levels);
    const quantitiesByPriceLevelId = {};
    tickets.forEach(ticket => {
      if (ticket?.price_level_id) {
        if (!quantitiesByPriceLevelId[ticket.price_level_id]) {
          quantitiesByPriceLevelId[ticket.price_level_id] = 0;
        }
        quantitiesByPriceLevelId[ticket.price_level_id]++;
      }
    });
    return levels.filter(lvl => {
      return (
        !!lvl?.minimum_purchase_limit &&
        +lvl.minimum_purchase_limit > 0 &&
        !!quantitiesByPriceLevelId[lvl.price_level_id] &&
        +quantitiesByPriceLevelId[lvl.price_level_id] < +lvl.minimum_purchase_limit
      );
    });
  }
);

export const getNumTicketsByLevel = upsellId => {
  return createSelector(
    [getTickets],
    tickets => {
      let levels = {};
      for (let i = 0; i < tickets.length; i++) {
        if (upsellId && +tickets[i].listing_upsell_id !== +upsellId) {
          continue;
        }
        if (!levels[tickets[i].price_level_id]) {
          levels[tickets[i].price_level_id] = 1;
        } else {
          levels[tickets[i].price_level_id]++;
        }
      }
      return levels;
    }
  );
};

/**
 * Get all loaded price levels including those loaded in price levels sub reducers
 */
export const getAllLoadedPriceLevels = createSelector(
  [getPriceLevelState],
  priceLevelState => {
    return getAllLoadedPriceLevelsFromPriceLevelState(priceLevelState);
  }
);
const getAllLoadedPriceLevelsFromPriceLevelState = priceLevelState => {
  const levels = {};
  applyToParentAndAllSubReducers({ ...priceLevelState }, state => {
    if (typeof state.levels === 'object' && state.levels !== null) {
      Object.keys(state.levels).forEach(levelKey => {
        if (levels[levelKey] === undefined) {
          levels[levelKey] = state.levels[levelKey];
        }
      });
    }
    return state;
  });
  return levels;
};

/**
 * Get all loaded quantity arrays from ticket picker state including from sub reducers
 */
const getAllLoadedQuantityArraysFromState = ticketPickerState => {
  const quantityArrays = {};
  applyToParentAndAllSubReducers({ ...ticketPickerState }, state => {
    if (typeof state.quantityArrays === 'object' && state.quantityArrays !== null) {
      Object.keys(state.quantityArrays).forEach(quantityArrayKey => {
        if (quantityArrays[quantityArrayKey] === undefined) {
          quantityArrays[quantityArrayKey] = state.quantityArrays[quantityArrayKey];
        }
      });
    }
    return state;
  });
  return quantityArrays;
};

export const getTicketsOfLevel = (ticketsState, levelId) => {
  let result = [];
  if (Array.isArray(ticketsState?.tickets)) {
    ticketsState.tickets.forEach(ticket => {
      if (+ticket?.price_level_id === +levelId) {
        result.push(ticket);
      }
    });
  }
  return result;
};

export const hasTicketsCartedForLevel = (ticketsState, levelId) => {
  return !!getTicketsOfLevel(ticketsState, levelId).length;
};

export const levelIsPWYW = (priceLevelState, levelId) => {
  return (
    typeof priceLevelState?.levels === 'object' &&
    priceLevelState.levels[levelId] !== undefined &&
    !!priceLevelState.levels[levelId]?.pwyw
  );
};

export const getPWYWPriceForPriceLevel = (priceLevelState, ticketPickerState, ticketsState, levelId) => {
  let price = null;

  if (levelIsPWYW(priceLevelState, levelId)) {
    const priceLevel = priceLevelState.levels[levelId];

    // if the user already carted pwyw tickets for this level, use the price on the tickets
    if (Array.isArray(ticketsState?.tickets)) {
      ticketsState.tickets.forEach(ticket => {
        if (+ticket?.price_level_id === +levelId && !!ticket?.cost) {
          price = toDecimals({ currency: ticket.cost?.currency ?? 'USD', amount: +ticket.cost.amount });
        }
      });
    }

    // check if the user set a price for the level
    if (
      price === null &&
      typeof ticketPickerState?.pwywPrices === 'object' &&
      ticketPickerState.pwywPrices[levelId] !== undefined
    ) {
      price = +ticketPickerState.pwywPrices[levelId];
    }

    // otherwise check for the default price to show the user
    if (price === null) {
      price = toDecimals({ currency: priceLevel?.price?.currency ?? 'USD', amount: +priceLevel?.price?.price });
    }
  }

  return price;
};
