import { createSelector } from 'reselect';
import {
    DELIVERY_TYPE_POST,
    DELIVERY_TYPE_EMAIL,
    DELIVERY_TYPE_MOBILE,
    DELIVERY_TYPE_WILL_CALL,
    DELIVERY_TYPE_NO_DELIVERY,
} from '../views/delivery/reducers/delivery';
import { getListingConfig, getCaptureMethod, getKioskOptionsFromConfig } from './ui';

const getDeliveryOptions = state => state.delivery.options;
const getDelivery = state => state.delivery;
const getReservation = state => state.reservation;

export const getNoDeliveryOptions = createSelector([getDeliveryOptions], options => getDeliveryOptionsByType(options, DELIVERY_TYPE_NO_DELIVERY));
export const getMobileDeliveryOptions = createSelector([getDeliveryOptions], options => getDeliveryOptionsByType(options, DELIVERY_TYPE_MOBILE));
export const getWillCallDeliveryOptions = createSelector([getDeliveryOptions], options => getDeliveryOptionsByType(options, DELIVERY_TYPE_WILL_CALL));
export const getEmailDeliveryOptions = createSelector([getDeliveryOptions], options => getDeliveryOptionsByType(options, DELIVERY_TYPE_EMAIL));
export const getPostDeliveryOptions = createSelector([getDeliveryOptions], options => getDeliveryOptionsByType(options, DELIVERY_TYPE_POST));

/**
 * Return all delivery options that match the provided type
 */
function getDeliveryOptionsByType(options, type) {
    let matchingOptions = [];
    if (Array.isArray(options)) {
        for (let i = 0; i < options.length; i++) {
            if (+options[i].method_type === type) {
                matchingOptions.push(options[i]);
            }
        }
    }
    return matchingOptions;
}

export const isDeliveryComplete = createSelector([getDelivery], (delivery) => {
    return delivery.id !== undefined;
});

/**
 * Gets delivery options minus DELIVERY_TYPE_NO_DELIVERY
 */
export const getAvailableDeliveryOptions = createSelector([getDelivery, getCaptureMethod, getListingConfig],
    (delivery, captureMethod, listingConfig) => {
        let deliveryOptions = delivery.options;
        // Kiosk capture method
        if (captureMethod === 13) {
            const kioskSettings = getKioskOptionsFromConfig(listingConfig);
            let availableOptions = getKioskDeliveryOptions(kioskSettings, deliveryOptions);

            return availableOptions;
        }

        return deliveryOptions;
    }
);

/**
 * Gets every delivery option type
 */
export const getAllAvailableDeliveryOptions = createSelector([getDelivery, getCaptureMethod, getListingConfig],
    (delivery, captureMethod, listingConfig) => {
        let deliveryOptions = delivery.options;
        // Kiosk capture method
        if (captureMethod === 13) {
            const kioskSettings = getKioskOptionsFromConfig(listingConfig);
            let availableOptions = getKioskDeliveryOptions(kioskSettings, deliveryOptions, true);
            return availableOptions;
        }

        return deliveryOptions;
    }
);

function getKioskDeliveryOptions(kioskSettings, deliveryOptions, includeNoDelivery = false) {
    let availableOptions = [];
    if (kioskSettings.allowMobileDelivery) {
        const mobileOptions = getDeliveryOptionsByType(deliveryOptions, DELIVERY_TYPE_MOBILE);
        if (mobileOptions.length) {
            availableOptions.push(mobileOptions[0]);
        }
    }
    if (kioskSettings.allowWillCallDelivery) {
        const willCallOptions = getDeliveryOptionsByType(deliveryOptions, DELIVERY_TYPE_WILL_CALL);
        if (willCallOptions.length) {
            availableOptions.push(willCallOptions[0]);
        }
    }
    if (kioskSettings.allowEmailDelivery) {
        const emailOptions = getDeliveryOptionsByType(deliveryOptions, DELIVERY_TYPE_EMAIL);
        if (emailOptions.length) {
            let emailOption = emailOptions[0];
            availableOptions.push({
                ...emailOption,
                name: "Email",
            });
        }
    }

    if(includeNoDelivery && kioskSettings.printReceiptsAndTickets === true) {
        const noDeliveryOptions = getDeliveryOptionsByType(deliveryOptions, DELIVERY_TYPE_NO_DELIVERY);
        if (noDeliveryOptions.length) {
            let options = noDeliveryOptions[0];
            availableOptions.push({
                ...options,
                name: "No Delivery",
            });
        }
    }

    return availableOptions;
}

export const getSelectedDeliveryMethodType = createSelector([getDelivery], (delivery) => {
    let selectedDeliveryMethodType = null;
    if (delivery.id && Array.isArray(delivery.options)) {
        delivery.options.map(option => {
            if (typeof option === 'object' && +option.id === +delivery.id) {
                selectedDeliveryMethodType = option.method_type;
            }
        });
    }
    return selectedDeliveryMethodType;
});

export const getSelectedDeliveryOption = createSelector(
  [getDeliveryOptions, getReservation],
  (options, reservation) => {
    return options.find((option) => option.id === reservation.selected_delivery_option) || { method_name: null }
})
