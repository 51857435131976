const initialState = {};

const addHookActionTypeToReducerFieldMap = {
    ADD_RESERVATION_START_HOOK           : 'reservationStart',
    ADD_RESERVATION_COMPLETE_HOOK        : 'reservationComplete',
    ADD_RESERVATION_DATA_SET_HOOK        : 'reservationDataSet',
    ADD_RESERVATION_CLEAR_HOOK           : 'reservationClear',
    ADD_RESERVATION_EXPIRES_IN_HOOK      : 'reservationExpiresIn',
    ADD_LISTING_PRICE_LEVELS_UPDATE_HOOK : 'listingPriceLevelsUpdate',
    ADD_STATE_TRANSITION_HOOK            : 'stateTransition',
    ADD_STATE_COUPON_SUBMISSION_HOOK     : 'couponSubmission',
    ADD_TICKETS_ADDED_TO_CART_HOOK       : 'ticketsAddedToCart',
    ADD_PRODUCTS_ADDED_TO_CART_HOOK      : 'productsAddedToCart',
    ADD_BUNDLES_ADDED_TO_CART_HOOK       : 'bundlesAddedToCart',
    ADD_TICKETS_REMOVED_FROM_CART_HOOK   : 'ticketsRemovedFromCart',
    ADD_PRODUCTS_REMOVED_FROM_CART_HOOK  : 'productsRemovedFromCart',
    ADD_BUNDLES_REMOVED_FROM_CART_HOOK   : 'bundlesRemovedFromCart',
    ADD_INSURANCE_OPTION_CHANGED_HOOK    : 'insuranceOptionChanged'
};

Object.values(addHookActionTypeToReducerFieldMap).forEach(field => {
    initialState[field] = [];
})

export function hooks(state: State = initialState, action): State {
    if (Object.keys(addHookActionTypeToReducerFieldMap).includes(action.type)) {
        let newState = {...state};
        newState[addHookActionTypeToReducerFieldMap[action.type]].push(action.hook);
        return newState;
    } else if(action.type === "RESERVATION_CREATED") {
        for (let hookFunction of state.reservationStart) {
            hookFunction();
        }
        return state;
    } else if(action.type === "RESERVATION_DATA_SET") {
        for (let hookFunction of state.reservationDataSet) {
            hookFunction(action.reservation, action.forward);
        }
        return state;
    } else if(action.type === "RESERVATION_COMPLETED") {
        for (let hookFunction of state.reservationComplete) {
            hookFunction();
        }
        return state;
    } else if(action.type === "RESERVATION_CLEARED") {
        for (let hookFunction of state.reservationClear) {
          hookFunction(action.forward, action.reason);
        }
        return state;
    } else if(action.type === "RESERVATION_EXPIRES_IN") {
        for (let hookFunction of state.reservationExpiresIn) {
            hookFunction(action.timeRemaining);
        }
        return state;
    } else if(action.type === "@ui-router/FINISH_TRANSITION") {
        let page = action.transition._targetState._definition.name;
        // ticketPicker fires when the picker is still invisible, it's handled elsewhere
        // checkout is handled by the step seen trigger below
        if(page === 'ticketPicker' || page === "checkout") {
            return state;
        }

        for (let hookFunction of state.stateTransition) {
            hookFunction(page);
        }
        return state;
    } else if(action.type === "TICKET_PICKER_OPENED") {
        for (let hookFunction of state.stateTransition) {
            hookFunction('ticketPicker');
        }
        return state;
    } else if(action.type === "CHECKOUT_STEP_SEEN") {
        let page = 'checkout-' + (action.index + 1);
        for (let hookFunction of state.stateTransition) {
            hookFunction(page);
        }
        return state;
    } else if(action.type === "COUPON_SUBMITTED") {
        for (let hookFunction of state.couponSubmission) {
            hookFunction(action.code);
        }
        return state;
    } else if (action.type === 'TICKETS_ADDED_TO_CART') {
        if (Array.isArray(state.ticketsAddedToCart)) {
            for (let hookFunction of state.ticketsAddedToCart) {
                hookFunction(action.tickets);
            }
        }
        return state;
    } else if (action.type === 'TICKETS_REMOVED_FROM_CART') {
        if (Array.isArray(state.ticketsRemovedFromCart)) {
            for (let hookFunction of state.ticketsRemovedFromCart) {
                hookFunction(action.tickets);
            }
        }
        return state;
    } else if (action.type === 'PRODUCTS_ADDED_TO_CART') {
        if (Array.isArray(state.productsAddedToCart)) {
            for (let hookFunction of state.productsAddedToCart) {
                hookFunction(action.products);
            }
        }
        return state;
    } else if (action.type === 'PRODUCTS_REMOVED_FROM_CART') {
        if (Array.isArray(state.productsRemovedFromCart)) {
            for (let hookFunction of state.productsRemovedFromCart) {
                hookFunction(action.products);
            }
        }
        return state;
    } else if (action.type === 'BUNDLES_ADDED_TO_CART') {
        if (Array.isArray(state.bundlesAddedToCart)) {
            for (let hookFunction of state.bundlesAddedToCart) {
                hookFunction(action.bundles);
            }
        }
        return state;
    } else if (action.type === 'BUNDLES_REMOVED_FROM_CART') {
        if (Array.isArray(state.bundlesRemovedFromCart)) {
            for (let hookFunction of state.bundlesRemovedFromCart) {
                hookFunction(action.bundles);
            }
        }
        return state;
    } else if (action.type === 'SET_INSURANCE_OPTION') {
        if (Array.isArray(state.insuranceOptionChanged)) {
            for (let hookFunction of state.insuranceOptionChanged) {
                hookFunction(action);
            }
        }
        return state;
    } else {
        return state;
    }
}
