import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import Message from 'shared/Message';
import Numpad from  './Numpad';
import styled from 'styled-components';
import { TranslationContext } from '../../../../checkout/config/Translations';
import { getPinAuthenticateFetch } from '../actions/kiosk-management';

const PinEntry = props => {
    const dispatch = useDispatch();
    const [ pinNumber, setPinNumber ] = React.useState('');
    const [ pinError, setPinError ]   = React.useState(false);
    const translate                   = React.useContext(TranslationContext).t;

    const changeValue = numText => {
        let pin = pinNumber;
        if (numText === '<') {
            pin = pin.slice(0, -1);
        } else {
            pin += numText;
        }
        setPinNumber(pin)
    }

    const submitPin = () => {
        if(pinNumber.length < 4) {
            return;
        }

        return dispatch(getPinAuthenticateFetch(props.listingSlug, pinNumber)).then((res) => {
            if(res.status === 200) {
                return res.json();
            }
        }).then((res) => {
            if(res.authenticated === true) {
                setPinError(false);
                props.onAuthenticated(true);
            } else {
                setPinError(true);
            }
        }).catch((e) => {});
    }

    const onPinChange = e => {
        e.preventDefault();
        e.stopPropagation();

        let val = e.target.value;

        if(val.length > 6) {
            val = val.substr(0, 6);
        }
        setPinNumber(val);
    }

    return(
        <PinPad className="pinpad">
            {pinError &&
                <PinPadMessage>
                    <Message error inlineText title={translate('checkout__incorrect_pin')} />
                </PinPadMessage>
            }
            <div className="input">
                <NumInput
                    className="input__control input--pinpad-number"
                    type="password"
                    value={pinNumber}
                    onChange={onPinChange}
                    placeholder={translate('checkout__enter_pin')}
                    autoFocus
                />
            </div>
            <Numpad onKeyPress={changeValue} />
            <Button className="button button--block button--block--primary button--full" onClick={submitPin}>
                {translate('checkout__ok')}
            </Button>
        </PinPad>
    )
}

PinEntry.propTypes = {
    listingSlug: PropTypes.string.isRequired,
    onAuthenticated: PropTypes.func.isRequired,
}

PinEntry.defaultProps = {
}

export default PinEntry;


const PinPad = styled.div`
    width: 270px;
    margin: 0 auto;
    // padding: 0 30px;
`
const PinPadMessage = styled.div`
    margin-bottom: 20px;
    display: block;
`

const NumInput = styled.input`
    width: 100%;
    -moz-appearance:textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus, &:active{
        box-shadow: none;
        outline: none;
        border-color: blue;
    }
`
