import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setWidth, lazyLoadSeriesDates } from '../actions/event-series';
import { getNumberOfMonths,
    getFormattedStartMonth,
    getFormattedEndMonth,
    getDisabledDates,
    getFormattedSoldOutDates,
    getFormattedEventDates,
    getCalendarColors,
    seriesHasSchedule,
    getCurrentMonth,
    needsDatesLoaded
} from '../selectors/eventSeries';
import { createLoadingSelector } from 'checkout/selectors/helpers';
import DayPicker from './DayPicker';

/**
 * Wrapper that connects DayPicker to redux
 */
class SeriesDayPicker extends Component {

    componentDidMount = () => {

        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);


        if (needsDatesLoaded(this.props)) {
            this.props.lazyLoadSeriesDates(this.props.currentMonth);
        }
    }

    updateDimensions = () => {
        this.props.setWidth(window.innerWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {

        if (!this.props.hasSchedule) {
            return null;
        }

        return (
            <DayPicker {...this.props} />
        );
    }
};

SeriesDayPicker.propTypes = {
    locale: PropTypes.string.isRequired,
    selectedDay: PropTypes.string,
    startingMonth: PropTypes.instanceOf(Date),
    endingMonth: PropTypes.instanceOf(Date),
    selectedDays: PropTypes.array.isRequired,
    disabledDays: PropTypes.array,
    onDayClick: PropTypes.func.isRequired,
    onMonthChange: PropTypes.func.isRequired,
};

const calendarLoading = createLoadingSelector("SERIES_CALENDAR");
const inventoryFilterLoading = createLoadingSelector("INVENTORY_FILTER");
const mapStateToProps = (state) => {
    // all kinds of things crash here if the series does not have a schedule
    let hasSchedule = seriesHasSchedule(state);

    if (!hasSchedule) {
        return {
            locale: state.intl.locale,
            selectedDays: [],
            hasSchedule,
        };
    }

    return {
        locale: state.intl.locale,
        numberOfMonths: getNumberOfMonths(state),
        fromMonth: getFormattedStartMonth(state),
        initialMonth: getFormattedStartMonth(state),
        toMonth: getFormattedEndMonth(state),
        disabledDays: getDisabledDates(state),
        soldOutDays: getFormattedSoldOutDates(state),
        eventDates: getFormattedEventDates(state),
        calendarColors: getCalendarColors(state),
        selectedDays: getFormattedEventDates(state),
        accentColor: state.ui.styleVars.GLOBAL__COLOR__ACCENT,
        loading: calendarLoading(state),
        fullLoading: inventoryFilterLoading(state),
        currentMonth: getCurrentMonth(state),
        needsDatesLoaded: needsDatesLoaded(state),
        loadedDates: state.eventSeries.datesLazyLoaded,
        hasSchedule,
        inventoryFilterResults: state.inventoryFilter.filterResults,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        lazyLoadSeriesDates,
        setWidth,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesDayPicker);
