import { createSelector } from 'reselect';

const getMemberInfo = state => state.member;
const getVenueInfo = state => state.venues;
const getCountry = state => state.reservation.country;

export const getDefaultAddress = createSelector(
    [
        getMemberInfo,
        getVenueInfo,
        getCountry,
    ],
    (member, venues, country) => {
        let address = {
            name:     "",
            address:  "",
            address2: "",
            city:     "",
            state:    "",
            country:  country,
            zip:      "",
            phone:    ""
        };

        // @todo other address fallthroughs like delivery, payment, etc
        if(member.address) {
            address = member.address;
        } else {
            if(member.name) {
                address.name = member.name;
            }

            if(member.phone) {
                address.phone = member.phone;
            }

            // default to venue country
            if(venues) {
                const firstVenue = Object.values(venues)[0];
                if(firstVenue.address) {
                    address.country = firstVenue.address.country;
                }
            }
        }


        return address;
    }
);

// @todo fallthrough to other emails?
export const getEmail = createSelector(
    [getMemberInfo],
    (member) => {
        return member.email;
    }
)

export const isMemberComplete = createSelector(
    [getMemberInfo],
    (member) => {
        return member.name !== "" && member.email !== "";
    }
)
