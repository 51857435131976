import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Props from './ListingBrandingComponent';

/**
 * props
 * slug - string slug ('showclix')
 * brand - string brand ('ShowClix')
 *
 * @todo This component probably needs to be updated to do something with the passed props
 */
class ListingBranding extends Component<Props> {
    render() {
        return(
            <div className="branding">
                <div className="branding__image">
                </div>
            </div>
        )
    }
}

ListingBranding.propTypes = {
    slug: PropTypes.string,
    branding: PropTypes.string
}

export default ListingBranding
