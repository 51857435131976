import { createSelector } from 'reselect';

const getLoadingStates = state => state.loading.states;

// creates a new selector for a specific type
export function createLoadingSelector(types) {
    if(!Array.isArray(types)) {
        types = [types];
    }

    return createSelector([getLoadingStates], (states) => {
        for(let i = 0; i < types.length; i++) {
            if(states[types[i]] === "REQUEST") {
                return true;
            }
        }

        return false;
    });
}
