const initialState = {
	displaySocial: false,
	facebookAppId: null,
	socialLink: "",
	twitterMessage: "",
};

export function socialSettings(state = initialState, action) {

    if (action.type === "RESERVATION_DATA_SET") {
    	const socialSettings = action.reservation.attributes.social_settings ? action.reservation.attributes.social_settings : {};
    	const displaySocial = socialSettings.display_social ? socialSettings.display_social : false;
    	const facebookAppId = socialSettings.fb_app_id ? socialSettings.fb_app_id : null;
    	const socialLink = socialSettings.social_link ? socialSettings.social_link : "";
    	const twitterMessage = socialSettings.tw_message ? socialSettings.tw_message : "";
    	return {
    		...state,
    		displaySocial: displaySocial,
    		facebookAppId: facebookAppId,
    		socialLink: socialLink,
    		twitterMessage: twitterMessage,
    	};
	} else if (action.type === "RESERVATION_CLEARED" || action.type === "RESERVATION_COMPLETED") {
		return initialState
	} else {
        return state;
    }
}
