import React, { Component } from 'react';
import PropTypes from 'prop-types';

const CLICKS_REQUIRED = 5;
const CLICK_WINDOW = 5000; // in ms

class AccessDiv extends Component {
    numClicks = 0;
    clickWindowOpen = false;
    clickTimeout = null;

    render() {
        return(
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 10000000,
                    backgroundColor: "transparent",
                    height: "100px",
                    width: "150px",
                    userSelect: "none",
                }}
                onClick={this.handleClick}
            ></div>
        )
    }

    handleClick = () => {
        this.numClicks++;
        if(this.clickWindowOpen) {
            if(this.numClicks >= CLICKS_REQUIRED) {
                this.clickWindowOpen = false;
                this.numClicks = 0;
                clearTimeout(this.clickTimeout);
                this.props.onTriggered();
            }
        } else {
            this.clickWindowOpen = true;
            this.clickTimeout = setTimeout(() => {
                this.clickWindowOpen = false;
                this.numClicks = 0;
            }, CLICK_WINDOW);
        }
    }
}

AccessDiv.propTypes = {
    onTriggered: PropTypes.func.isRequired,
}

AccessDiv.defaultProps = {
}

export default AccessDiv;
