const localizations = {
    defaults: {
        state: {
            show: true,
            label: 'Territory',
            autocomplete: 'address-level1'
        },
        zip: {
            show: true,
            label: 'Zip Code',
            autocomplete: 'postal-code',
            inputType: 'text',
        },
        city: {
            show: true,
            label: 'City',
            autocomplete: 'address-level2'
        },
        address: {
            show: true,
            label: 'Address',
            autocomplete: 'address-line1'
        },
        address2: {
            show: true,
            label: 'Address 2',
            autocomplete: 'address-line2'
        }
    },
    AA: {
        state: {
            show: false
        }
    },
    AE: {
        state: {
            show: false
        },
        zip: {
            show: false
        },
    },
    AG: {
        zip: {
            show: false
        },
    },
    AN: {
        zip: {
            show: false
        },
    },
    AO: {
        zip: {
            show: false
        },
    },
    AP: {
        state: {
            show: false
        }
    },
    AU: {
        state: {
            show: true,
            label: 'State',
            autocomplete: 'address-level1',
            options: {
                ACT: 'Australian Capital Territory',
                NSW: 'New South Wales',
                NT: 'Northern Territory',
                QLD: 'Queensland',
                SA: 'South Australia',
                TAS: 'Tasmania',
                VIC: 'Victoria',
                WA: 'Western Australia'
              }
          },
          city: {
              show: true,
              label: 'Suburb',
              autocomplete: 'address-level2'
          },
          zip: {
              show: true,
              label: 'Postcode',
              autocomplete: 'postal-code'
          }
      },
    AW: {
        zip: {
            show: false
        },
    },
    BB: {
        state: {
            show: true,
            label: 'Parish'
        }
    },
    BF: {
        zip: {
            show: false
        },
    },
    BI: {
        zip: {
            show: false
        },
    },
    BJ: {
        zip: {
            show: false
        },
    },
    BM: {
        zip: {
            show: false
        },
    },
    BO: {
        zip: {
            show: false
        },
    },
    BQ: {
        zip: {
            show: false
        },
    },
    BS: {
        zip: {
            show: false
        },
        state: {
            show: true,
            label: 'Territory',
            autocomplete: 'address-level1'
        }
    },
    BW: {
        zip: {
            show: false
        },
    },
    BZ: {
        zip: {
            show: false
        },
    },
    CA: {
        state: {
            show: true,
            label: 'Province',
            autocomplete: 'address-level1',
            options: {
                AB: 'Alberta',
                BC: 'British Columbia',
                MB: 'Manitoba',
                NB: 'New Brunswick',
                NL: 'Newfoundland',
                NT: 'Northwest Territories',
                NS: 'Nova Scotia',
                NU: 'Nunavut',
                ON: 'Ontario',
                PE: 'Prince Edward Island',
                SK: 'Saskatchewan',
                QC: 'Quebec',
                YT: 'Yukon'
            }
        },
        zip: {
            show: true,
            autocomplete: 'postal-code',
            label: 'Postcode'
        }
    },
    CD: {
        zip: {
            show: false
        },
    },
    CF: {
        zip: {
            show: false
        },
    },
    CG: {
        zip: {
            show: false
        },
    },
    CK: {
        zip: {
            show: false
        },
    },
    CI: {
        zip: {
            show: false
        },
    },
    CM: {
        zip: {
            show: false
        },
    },
    CW: {
        zip: {
            show: false
        },
    },
    DJ: {
        zip: {
            show: false
        },
    },
    DM: {
        zip: {
            show: false
        },
    },
    ER: {
        zip: {
            show: false
        },
    },
    FI: {
        state: {
            show: true,
            label: 'Province'
        },
        zip: {
            show: true,
            label: 'Postal Code'
        }
    },
    FJ: {
        zip: {
            show: false
        },
    },
    GA: {
        zip: {
            show: false
        },
    },
    GB: {
        state: {
            show: true,
            autocomplete: 'address-level1',
            label: 'County'
        }
    },
    GD: {
        zip: {
            show: false
        },
    },
    GH: {
        zip: {
            show: false
        },
    },
    GM: {
        zip: {
            show: false
        },
    },
    GQ: {
        zip: {
            show: false
        },
    },
    GY: {
        zip: {
            show: false
        },
    },
    HK: {
        zip: {
            show: false
        },
    },
    HM: {
        zip: {
            show: false
        },
    },
    IE: {
        zip: {
            show: true,
            label: 'Eircode',
            autocomplete: 'postal-code'
        },
    },
    IL: {
        zip: {
            show: true,
            label: 'Postal Code',
            autocomplete: 'postal-code'
        }
    },
    KI: {
        zip: {
            show: false
        },
    },
    KM: {
        zip: {
            show: false
        },
    },
    KN: {
        zip: {
            show: false
        },
    },
    KP: {
        zip: {
            show: false
        },
    },
    LY: {
        zip: {
            show: false
        },
    },
    MC: {
        state: {
            show: false
        },
        city: {
            show: true,
            label: 'Locality',
            autocomplete: 'address-level2'
        },
        zip: {
            show: true,
            label: 'Postal Code',
            autocomplete: 'postal-code'
        }
    },
    ML: {
        zip: {
            show: false
        },
    },
    MO: {
        zip: {
            show: false
        },
    },
    MR: {
        zip: {
            show: false
        },
    },
    MW: {
        zip: {
            show: false
        },
    },
    NL: {
      zip: {
        show: true,
        label: 'Postcode',
        autocomplete: 'postal-code'
      },
    },
    NR: {
        zip: {
            show: false
        },
    },
    NU: {
        zip: {
            show: false
        },
    },
    QA: {
        zip: {
            show: false
        },
    },
    RW: {
        zip: {
            show: false
        },
    },
    SB: {
        zip: {
            show: false
        },
    },
    SC: {
        zip: {
            show: false
        },
    },
    SE: {
        state: {
            show: true,
            label: 'Province'
        },
        zip: {
            show: true,
            label: 'Postal Code'
        }
    },
    SL: {
        zip: {
            show: false
        },
    },
    SO: {
        zip: {
            show: false
        },
    },
    SR: {
        zip: {
            show: false
        },
    },
    ST: {
        zip: {
            show: false
        },
    },
    SX: {
        zip: {
            show: false
        },
    },
    SY: {
        zip: {
            show: false
        },
    },
    TD: {
        zip: {
            show: false
        },
    },
    TF: {
        zip: {
            show: false
        },
    },
    TG: {
        zip: {
            show: false
        },
    },
    TK: {
        zip: {
            show: false
        },
    },
    TL: {
        zip: {
            show: false
        },
    },
    TO: {
        zip: {
            show: false
        },
    },
    TT: {
        zip: {
            show: false
        }
    },
    TV: {
        zip: {
            show: false
        },
    },
    UG: {
        zip: {
            show: false
        },
    },
    UK: {
        state: {
            show: true,
            label: 'County',
            autocomplete: 'address-level1'
        }
    },
    US: {
        state: {
            show: true,
            label: 'State',
            autocomplete: 'address-level1',
            options: {
                AA: 'Armed Forces Americas',
                AE: 'Armed Forces Europe/Middle East/Africa',
                AP: 'Armed Forces Asia/Pacific',
                AL: 'Alabama',
                AK: 'Alaska',
                AZ: 'Arizona',
                AR: 'Arkansas',
                CA: 'California',
                CO: 'Colorado',
                CT: 'Connecticut',
                DE: 'Delaware',
                DC: 'District Of Columbia',
                FL: 'Florida',
                GA: 'Georgia',
                HI: 'Hawaii',
                ID: 'Idaho',
                IL: 'Illinois',
                IN: 'Indiana',
                IA: 'Iowa',
                KS: 'Kansas',
                KY: 'Kentucky',
                LA: 'Louisiana',
                ME: 'Maine',
                MD: 'Maryland',
                MA: 'Massachusetts',
                MI: 'Michigan',
                MN: 'Minnesota',
                MS: 'Mississippi',
                MO: 'Missouri',
                MT: 'Montana',
                NE: 'Nebraska',
                NV: 'Nevada',
                NH: 'New Hampshire',
                NJ: 'New Jersey',
                NM: 'New Mexico',
                NY: 'New York',
                NC: 'North Carolina',
                ND: 'North Dakota',
                OH: 'Ohio',
                OK: 'Oklahoma',
                OR: 'Oregon',
                PA: 'Pennsylvania',
                PR: 'Puerto Rico',
                RI: 'Rhode Island',
                SC: 'South Carolina',
                SD: 'South Dakota',
                TN: 'Tennessee',
                TX: 'Texas',
                UT: 'Utah',
                VT: 'Vermont',
                VA: 'Virginia',
                WA: 'Washington',
                WV: 'West Virginia',
                WI: 'Wisconsin',
                WY: 'Wyoming'
            }
        },
        zip: {
            inputType: 'tel',
        },
    },
    VU: {
        zip: {
            show: false
        },
    },
    YE: {
        zip: {
            show: false
        },
    },
    ZA: {
        state: {
            show: true,
            label: 'Province'
        },
        zip: {
            show: true,
            label: 'Postal Code',
            autocomplete: 'postal-code'
        }
    },
    ZW: {
        zip: {
            show: false
        },
    }
};

export default localizations;
