import { dateToYMD } from '../../helpers/time';

export function getModifiers(eventDates, colorRanges, descriptionRanges, inventoryFilterResults) {
    let modifiers = {};

    // add in our event dates as the default color
    let dates = [];
    for(let i = 0; i < eventDates.length; i++) {
        dates.push(new Date(eventDates[i]));
    }

    modifiers.eventDates = dates;

    // add in previously saved ranges from props
    for (const color in colorRanges) {
        modifiers = addDatesToModifiers(modifiers, color.substr(1), colorRanges[color]);
    }

    for (const desc in descriptionRanges) {
        modifiers = addDescriptionIndicatorToModifiers(modifiers, descriptionRanges[desc]);
    }

    //get dates filtered out by inventory filter feature
    let filteredDates = [];
    if (typeof inventoryFilterResults === 'object' && inventoryFilterResults !== null) {
        let validDates = Object.keys(inventoryFilterResults);
        for (let i = 0; i < eventDates.length; i++) {
            if (validDates.indexOf(dateToYMD(eventDates[i])) === -1) {
                filteredDates.push(eventDates[i]);
            }
        }
        if (filteredDates.length) {
            modifiers.filteredDates = filteredDates;
        }
    }

    return modifiers;
}

export function addDatesToModifiers(modifiers, key, dates) {
    if(!modifiers[key]) {
        modifiers[key] = dates;
    } else {
        modifiers[key] = [...modifiers[key], ...dates];
    }

    return modifiers;
}

export function addDescriptionIndicatorToModifiers(modifiers, dates) {
    const key = 'customDescription';
    if(!modifiers[key]) {
        modifiers[key] = dates;
    } else {
        modifiers[key] = [...modifiers[key], ...dates];
    }

    return modifiers;
}

export function getStyleModifiers(accentColor, modifiers) {
    // start with making event dates our accent color always
    let style = `.DayPicker-Day--eventDates:after {background-color: ${accentColor} !important;}`;

    const skipModifiers = ['eventDates', 'customDescription', 'start', 'end']; // no custom style/color needed for those
    for (const key in modifiers) {
        if (skipModifiers.includes(key)) {
            continue;
        }
        style += `.DayPicker-Day--eventDates.DayPicker-Day--${key}:after {background-color: #${key} !important;}`;
    }

    return style;
}

export function eventInFilterResults(eventId, filterResults) {
    if (typeof filterResults === 'object' && filterResults !== null) {
        let dates = Object.keys(filterResults);
        for(let i = 0; i < dates.length; i++) {
            if (Array.isArray(filterResults[dates[i]]) && filterResults[dates[i]].indexOf(eventId) !== -1) {
                return true;
            }
        }
    }
    return false;
}
