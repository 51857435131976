import React, { Component } from "react";

class PencilIcon extends Component {
  render() {
    return (
      <i className="icon-mode_edit"></i>
    );
  }
}


class DotsIcon extends Component {
  render() {
    return (
      <i className="icon-more_horiz"></i>
      );
    }
  }

  class CarrotDownIcon extends Component {
    render() {
      return (
        <i className="icon-keyboard_arrow_down"></i>
    );
  }
}

class TrashIcon extends Component {
  render() {
    return (
      <i className="icon-trash-o"></i>
    );
  }
}

class CloseIcon extends Component {
  render() {
    return (
      <i className="icon-close"></i>
    );
  }
}

class CloseCircleIcon extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        aria-labelledby="close-circle-icon"
      >
        <span id="close-circle-icon" className="sr-only">Close</span>
        <path fill={this.props.color ? this.props.color : '#000000'} d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
      </svg>
    );
  }
}


class FilterIcon extends Component {
  render() {
    return (
      <i className="icon-tune"></i>
    );
  }
}

class CautionIcon extends Component {
  render() {
    return (
      <i className="icon-warning">
        <span className="sr-only">Caution Icon</span>
      </i>
    );
  }
}

class SuccessIcon extends Component {
  render() {
    return (
      <i className="icon-check">
        <span className="sr-only">Success Icon</span>
      </i>
    );
  }
}

class ErrorIcon extends Component {
  render() {
    return (
      <i className="icon-report">
        <span className="sr-only">Error Icon</span>
      </i>
    );
  }
}

class InfoIcon extends Component {
  render() {
    return (
      <i className="icon-info_outline">
        <span className="sr-only">Info Icon</span>
      </i>
    );
  }
}

class CopyIcon extends Component {
  render() {
    return (
      <i className="icon-content_copy"></i>
    );
  }
}

class AddIcon extends Component {
  render() {
    return (
      <i className="icon-add"></i>
    );
  }
}

export { PencilIcon, DotsIcon, CarrotDownIcon, TrashIcon, CloseIcon, CloseCircleIcon, FilterIcon, CautionIcon, SuccessIcon, ErrorIcon, InfoIcon, CopyIcon, AddIcon };
