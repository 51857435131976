import { setLoading } from '../../../actions/loading';
import { wrappedFetch } from '../../../helpers/fetch';
import { getWeatherPayload } from '../../../selectors/request';

export function setSensibleWeatherQuote(quote) {
  return { type: 'SET_QUOTE', quote };
}

export function clearSensibleWeatherData() {
  setSensibleWeatherQuote({});
}

export function submitWeatherQuote() {
  return (dispatch, getState) => {
    dispatch(setLoading('SW_SUBMIT_REQUEST'));
    const state = getState();
    const token = state.reservation.token;
    const secret = state.reservation.secret;
    const payload = getWeatherPayload(state);

    if (payload.id === 0) {
      return;
    }

    if (!payload.selected) {
      let req = {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'reservation-secret': secret
        },
        body: JSON.stringify({ token: token, ...payload })
      }

      return wrappedFetch(state.ui.apiUrl + '/insurance/sensible-weather/decline', req).then(res => {
        return res.json();
      }).finally(() => clearSensibleWeatherData());
    }

    let req = {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'reservation-secret': secret
      },
      body: JSON.stringify({ token: token, ...payload })
    };
    return wrappedFetch(state.ui.apiUrl + '/insurance/sensible-weather/accept', req).then(res => {
      return res.json();
    }).finally(() => clearSensibleWeatherData());
  }
}
