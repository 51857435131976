import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useCheckoutProcessContext } from '../../contexts/CheckoutProcessContext';
import { InsetWrapper } from '../InsetWrapper';
import { Translation } from '../Translation';

const Name = props => {
    const { TranslationContext } = useCheckoutProcessContext();
    const translationContext = useContext(TranslationContext);

    const onNameChange = e => {
        props.onNameChange(e);
    }

    const onFocus = e => {
        if(props.onFocus) {
            props.onFocus(e, translationContext.t('checkout__full_name'));
        }
    }

    return (
        <InsetWrapper hasError={!!props.error?.message}>
            <div className="input__top">
              <label className="input__label"
                     htmlFor="customerName">{translationContext.t("checkout__full_name")}</label>
              {props.required && <span
                className="input__required text__required">{translationContext.t('checkout__required')}</span>}
            </div>
            <input
              name="name"
              type="text"
              className="input__control"
              placeholder={translationContext.t('checkout__full_name')}
              value={props.name}
              onChange={onNameChange}
              required
              onFocus={onFocus}
              onBlur={props.onBlur}
              disabled={props.disabled}
            />
            {props.error?.message && (
                <p className="input--error__text">
                    <Translation interpolation={props.error?.interpolation}>
                        {props.error.message}
                    </Translation>
                </p>
            )}
        </InsetWrapper>
    );
}

Name.propTypes = {
    name: PropTypes.string.isRequired,
    onNameChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool
}

Name.defaultProps = {
    required: true
}

export default Name;
