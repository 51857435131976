import React from 'react';
import PropTypes from "prop-types";
import { TranslationContext } from '../checkout/config/Translations';

const DetailsToggle = props => {
    const [ toggleOn, setToggleOn ] = React.useState(true);
    const translate  = React.useContext(TranslationContext).t;
    const lessText = props.lessToggle ?? translate('checkout__less_details');
    const moreText = props.moreToggle ?? translate('checkout__more_details');
    const isToggleOn = props.toggleOn === undefined ? toggleOn : props.toggleOn;

    return (
        <button
            className="button button--link ticket-description__toggle"
            onClick={() => {
                setToggleOn(!toggleOn);
                props.onClick(props.index);
            }}
        >
            {isToggleOn ? '+ ' + moreText : '- ' + lessText}
        </button>
    )
}

DetailsToggle.propTypes = {
    onClick: PropTypes.func.isRequired,
    lessToggle: PropTypes.string,
    moreToggle: PropTypes.string,
    toggleOn: PropTypes.bool,
};

export default DetailsToggle;
