import { toMinorUnit } from '../../../helpers/currency';

/**
 * Note: it is necessary to distinguish between the donation amount that the user entered and the actual donation amount
 * calculated server side based on the entered amount.  This is because of fixed amount and per ticket donation settings.
 * For example, if a user enters $10 per ticket on a 5 ticket order, we need to be able to display 10.00 in the input but store
 * 5000 as the total donation amount.
 */
const initialState = {

    //whether the reservation has donation opportunities
    hasDonations: false,

    //Title to display above donation input(s)
    donationTitle: '',

    //Data pertaining to each donation opportunity
    donationOrganizations: {},

    //user entered donation amounts
    userDonations: {},

    //actual donation amounts
    donationAmounts: {},

    //total amount of all donations for this reservation
    totalReservationDonation: 0,
};

export function donations(state = initialState, action) {

    if(action.type === "SET_HAS_DONATIONS") {

        let newState = {...state};
        newState.hasDonations = action.hasDonations ? true : false;
        return newState

    } else if(action.type === "SET_DONATION_TITLE") {

        let newState = {...state};
        newState.donationTitle = action.donationTitle;
        return newState;

    } else if (action.type === "SET_DONATION_ORGANIZATIONS") {

        let newOrgs = {};
        let newDonationAmounts = {};
        let newUserDonations = {...state.userDonations};

        Object.keys(action.donationOrganizations).forEach(key => {

            //set organization data
            newOrgs[action.donationOrganizations[key].event_id] = action.donationOrganizations[key];

            //set real donation amount
            newDonationAmounts[action.donationOrganizations[key].event_id] = action.donationOrganizations[key].amount;

            //populate key for this event in user donations if it doesn't already exist
            if (!(action.donationOrganizations[key].event_id in newUserDonations)) {
                newUserDonations[action.donationOrganizations[key].event_id] = 0;
            }

        });

        const newState = {
            ...state,
            donationOrganizations: newOrgs,
            donationAmounts: newDonationAmounts,
            userDonations: newUserDonations,
        }
        return newState;

    } else if (action.type === "SET_USER_DONATION") {

        let newUserDonations = {...state.userDonations};
        newUserDonations[action.eventId] = action.value;
        const totalReservationDonation = Object.keys(newUserDonations).reduce(
            (memo, eventId) => memo + toMinorUnit(newUserDonations[eventId], state.currency), 0
        );
        const newState = {
            ...state,
            userDonations: newUserDonations,
            totalReservationDonation,
        }
        return newState;

    } else if (action.type === "SET_TOTAL_RESERVATION_DONATION") {

        let newState = {...state};
        newState.totalReservationDonation = action.value;
        return newState;

    } else if (action.type === "RESERVATION_DATA_SET") {
        return { ...state, currency: action.reservation.attributes.currency };
    } else if (action.type === "RESERVATION_CLEARED") {

        return initialState;

    } else {

        return state;

    }
}
