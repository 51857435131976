import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import AccessDiv from '../components/AccessDiv';
import ManagementPane from '../components/ManagementPane';
import { setPurchaseUser } from 'checkout/actions/reservation';
import { setTerminal, setTerminals, setIsReaderConnected } from 'checkout/actions/payment';
import { setHelpOpen } from 'listing/views/help/actions/help';
import { setManagementOpen } from '../actions/kiosk-management';
import { setStripeTerminal } from '@/actions/payment';
import { fetchConnectionToken, discoverAndConnectToReader } from 'checkout/views/stripe-emv/actions/stripeEmv';
import { getKioskOptions, getCmsMode, isDevMode } from 'checkout/selectors/ui';
import { logout, getTerminalsFetch } from '../actions/kiosk-management';

const ACCESS_DIV_ID = "kiosk__management";

const JQUERY_URL = "https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js";
const STRIPE_TERMINAL_URL = 'https://js.stripe.com/terminal/v1/';

class KioskManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            terminals: [],
        }
    }

    componentDidMount() {
        let listingConfig = localStorage.getItem(this.props.listingSlug);
        if(listingConfig) {
            listingConfig = JSON.parse(listingConfig);
        }

        // if required settings are missing
        let userSet = false;
        let teminalSet = false;

        if(listingConfig && listingConfig.purchaseUser && Array.isArray(this.props.kioskOptions.users) && this.props.kioskOptions.users.map(userId => {return +userId}).indexOf(+listingConfig.purchaseUser) !== -1) {
            this.props.setPurchaseUser(listingConfig.purchaseUser);
            userSet = true;
        }

        if(listingConfig && listingConfig.terminalId) {
            this.props.setTerminal(listingConfig.terminalId);
            teminalSet = true;
        }

        if((!userSet || !teminalSet) && !this.props.cmsMode && !isDevMode()) {
            this.props.setManagementOpen(true);
        }

        if(this.props.cmsMode){
            this.props.setManagementOpen(false);
        }

        this.getTerminals();
    }

    render() {
        return(
            <Fragment>
                {ReactDOM.createPortal(
                    <Fragment>
                        <AccessDiv onTriggered={this.triggerManagementOverlay}/>
                        {this.props.managementOpen && this.props.apiToken &&
                            <ManagementPane
                                apiUrl={this.props.apiUrl}
                                onClose={this.closeManagementPane}
                                sellerId={this.props.sellerId}
                                purchaseUser={this.props.purchaseUser}
                                terminalId={this.props.terminalId}
                                listingSlug={this.props.listingSlug}
                                onUserSelect={this.onUserSelect}
                                adminUrl={this.props.adminUrl}
                                onAuthenticated={this.onAuthenticated}
                                onTerminalSelect={this.onTerminalSelect}
                                onLoadTerminals={this.onLoadTerminals}
                                terminals={this.state.terminals}
                                kioskOptions={this.props.kioskOptions}
                                cmsMode={this.props.cmsMode}
                                onLogout={this.onLogout}
                                apiToken={this.props.apiToken}
                            />
                        }
                    </Fragment>,
                    document.getElementById(ACCESS_DIV_ID)
                )}
            </Fragment>
        )
    }

    onAuthenticated = () => {
        this.props.setHelpOpen(false);
    }

    onUserSelect = (id) => {
        let listingConfig = localStorage.getItem(this.props.listingSlug);

        if(!listingConfig) {
            listingConfig = {};
        } else {
            listingConfig = JSON.parse(listingConfig);
        }

        listingConfig.purchaseUser = id;
        localStorage.setItem(this.props.listingSlug, JSON.stringify(listingConfig));
        this.props.setPurchaseUser(id);
    };

    onTerminalSelect = (id) => {
        let listingConfig = localStorage.getItem(this.props.listingSlug);

        if(!listingConfig) {
            listingConfig = {};
        } else {
            listingConfig = JSON.parse(listingConfig);
        }

        listingConfig.terminalId = id;
        localStorage.setItem(this.props.listingSlug, JSON.stringify(listingConfig));
        this.props.setTerminal(id);

        if(+id === 0) {
            this.props.setIsReaderConnected(false);
        } else {
            this.props.discoverAndConnectToReader(this.props.fetchConnectionToken);
        }
    };

    getTerminals = () => {
        let terminalsList = {};
        return this.props.getTerminalsFetch().then((res) => {
            if(res.status === 200) {
                return res.json();
            }
        }).then((res) => {
            res.terminals.forEach(terminal => {
                terminalsList[terminal.value] = terminal.serialNumber;
            });
            this.props.setTerminals(terminalsList);
            this.setState({terminals: res.terminals});
            this.loadStripeTerminalLibrary(terminalsList);
        }).catch((e) => {});
    }

    closeManagementPane = () => {
        this.props.setManagementOpen(false);
    }

    triggerManagementOverlay = () => {
        this.props.setManagementOpen(true);
    }

    loadStripeTerminalLibrary = (terminalsList) => {
        var jqScript = document.createElement('script');
        jqScript.src = JQUERY_URL;

        jqScript.addEventListener('load', () => {
            var stripeTerminalScript = document.createElement('script');
            stripeTerminalScript.src = STRIPE_TERMINAL_URL;

            stripeTerminalScript.addEventListener('load', () => {
                this.props.setStripeTerminal(StripeTerminal); //eslint-disable-line
                this.props.discoverAndConnectToReader(this.props.fetchConnectionToken);
            });
            document.body.appendChild(stripeTerminalScript);
        });
        document.body.appendChild(jqScript);
    };

    onLogout = () => {
        this.props.logout();
    }
}

const mapStateToProps = (state, props) => {
    return {
        purchaseUser: state.reservation.purchaseUser,
        sellerId: state.event.seller_id,
        terminalId: state.payment.terminalId,
        terminals: state.payment.terminals,
        apiUrl: state.ui.apiUrl,
        listingSlug: state.ui.listingSlug,
        managementOpen: state.ui.managementOpen,
        adminUrl: state.ui.uiConfig.adminUrl,
        kioskOptions: getKioskOptions(state),
        cmsMode: getCmsMode(state),
        apiToken: state.kioskManagement.token,
    }
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setPurchaseUser,
        setTerminal,
        setTerminals,
        setHelpOpen,
        setManagementOpen,
        fetchConnectionToken,
        discoverAndConnectToReader,
        setStripeTerminal,
        setIsReaderConnected,
        logout,
        getTerminalsFetch,
    }, dispatch);
};

KioskManagement.propTypes = {
};

KioskManagement.defaultProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(KioskManagement);
