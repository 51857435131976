import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { triggerTransition } from '@uirouter/redux';
import { getTotalSelectedProducts, isDependentProductSelected } from '../../product-picker/selectors/products';
import { createReservation, updateQuantities } from '../../../actions/reservation';
import { createLoadingSelector } from '../../../selectors/helpers';
import { getTotalSelectedItems, getProductErrors, getBundleErrors } from '../selectors/selected-items';
import Button from '@s/Button';
import { setDisplayConfigurationError } from '../actions/ticket-picker';
import { reservationActive } from '../../../selectors/reservation';
import { isKioskMode } from 'checkout/selectors/ui';
import {
  showBestAvailablePicker,
  numberSelectedBestAvailableSeats,
  showPyosPicker,
  getSectionPriceLevelsWithUnmetMinimums
} from 'checkout/selectors/reserved-seating';
import { getPriceLevelsWithUnmetMinimums } from '../selectors/tickets';
import { addAlert } from '../../../actions/ui.js';
import Translation from '@s/Translation';
import { getReserveCouponUsage } from '../../../selectors/reserved-seating';
import { isMultiEmbed } from '../../../selectors/ui.js';

class CheckoutButton extends Component {
  buttonText = () => {
    const { showPyosPicker, showBestAvailablePicker, isMultiEmbed, currentRouteName } = this.props;

    const showAddToCartText =
      isMultiEmbed &&
      currentRouteName !== 'tickets-reserved' &&
      (currentRouteName === 'picker-with-details' || showPyosPicker || showBestAvailablePicker);

    if (this.props.isKioskMode) {
      return <Translation>{'checkout__checkout'}</Translation>;
    }
    if (this.props.showBestAvailablePicker && !this.props.hasReserveCoupon) {
      return <Translation>{'checkout__find_seats'}</Translation>;
    }
    if (showAddToCartText) {
      return <Translation>{'checkout__add_to_cart'}</Translation>
    }
    return <Translation>{'checkout__proceed_to_checkout'}</Translation>;
  };

  disabled = () => {
    if (this.props.maintenanceMode) {
      return true;
    }
    if (this.props.currentRouteName === 'tickets-reserved') {
      return false;
    }
    if (this.props.showPyosPicker && this.props.pyosLoading) {
      return true;
    }
    if (
      !this.props.showBestAvailablePicker &&
      !this.props.showPyosPicker &&
      !this.props.selectedItems &&
      !this.props.selectedProducts
    ) {
      return true;
    }
    if (!!this.props.showBestAvailablePicker && this.props.numberSelectedBestAvailableSeats < 1) {
      return true;
    }
    if (this.props.showPyosPicker && (!this.props.ticketCount || +this.props.ticketCount < 1)) {
      return true;
    }
    if (!this.props.showBestAvailablePicker && this.dependentProductError()) {
      return true;
    }
    return false;
  };

  render() {
    const buttonClass = this.props.isKioskMode
      ? 'button--primary button--large kiosk-footer__button--primary'
      : 'button--primary button--large add-to-cart__button';
    return (
      <div className="add-to-cart">
        <Button
          onClick={this.requestInventory}
          className={buttonClass}
          disabled={this.disabled()}
          loading={this.props.pyosLoading || this.props.loading || this.props.taggableCouponLoading}
        >
          {this.buttonText()}
        </Button>
      </div>
    );
  }

  dependentProductError = () => {
    return (
      (this.props.ticketCount < 1 || this.props.ticketCount === undefined) &&
      (this.props.bundleCount < 1 || this.props.bundleCount === undefined) &&
      (!this.props.selectedItems && this.props.selectedDependentProducts)
    );
  };

  alertUnmetMin = (name, min) => {
    this.props.addAlert('checkout__must_purchase_n_tickets_to_pay_price', 'error', true, { name, min });
  };

  requestInventory = () => {
    const {
      currentRouteName,
      token,
      isMultiEmbed,
      showPyosPicker,
      showBestAvailablePicker,
      triggerTransition
    } = this.props;

    if (currentRouteName === 'tickets-reserved') {
      triggerTransition('checkout', { token });
      return false;
    }

    let productsValid = Object.keys(this.props.productsWithErrors).length === 0;
    let bundlesValid = Object.keys(this.props.bundleProductsWithErrors).length === 0;

    // only checking this if pyos because other ticket pickers should only allow selecting valid quantities
    if (this.props.showPyosPicker) {
      if (this.props.sectionPriceLevelsWithUnmetMinimums.length) {
        this.props.sectionPriceLevelsWithUnmetMinimums.forEach(secLevel => {
          this.alertUnmetMin(secLevel.name, secLevel.minimum_purchase_limit);
        });
        return false;
      }
      if (this.props.priceLevelsWithUnmetMinimums.length) {
        this.props.priceLevelsWithUnmetMinimums.forEach(lvl => {
          this.alertUnmetMin(lvl.name, lvl.minimum_purchase_limit);
        });
        return false;
      }
    }

    if (!productsValid || !bundlesValid) {
      this.props.setDisplayConfigurationError(true);
      return false;
    } else {
      this.props.setDisplayConfigurationError(false);
    }

    const shouldGoToTicketReservedStep =
      isMultiEmbed &&
      (currentRouteName === 'picker-with-details' || showPyosPicker || showBestAvailablePicker);

    const to = shouldGoToTicketReservedStep ? 'tickets-reserved' : 'checkout';

    if (!this.props.reservationActive) {
      this.props.createReservation(true, to);
    } else {
      this.props.updateQuantities(null, null, null, null, to);
    }
  };
}

const getReservationLoading = createLoadingSelector(['RESERVATION_CREATE', 'RESERVATION_FETCH']);
const mapStateToProps = (state, props) => {
  return {
    isMultiEmbed: isMultiEmbed(state),
    hasReserveCoupon: !!getReserveCouponUsage(state)?.attributes?.coupon_link_id,
    ticketCount: state.reservation.ticket_count,
    bundleCount: state.reservation.bundle_count,
    selectedItems: getTotalSelectedItems(state),
    selectedDependentProducts: isDependentProductSelected(state),
    selectedProducts: getTotalSelectedProducts(state),
    loading: getReservationLoading(state),
    productsWithErrors: getProductErrors(state),
    bundleProductsWithErrors: getBundleErrors(state),
    reservationActive: reservationActive(state),
    isKioskMode: isKioskMode(state),
    maintenanceMode: state.ui.maintenanceMode,
    showBestAvailablePicker: showBestAvailablePicker(state),
    showPyosPicker: showPyosPicker(state),
    pyosLoading: state.reservedSeating.pyosLoading,
    numberSelectedBestAvailableSeats: numberSelectedBestAvailableSeats(state),
    sectionPriceLevelsWithUnmetMinimums: getSectionPriceLevelsWithUnmetMinimums(state),
    priceLevelsWithUnmetMinimums: getPriceLevelsWithUnmetMinimums(state),
    taggableCouponLoading: state.couponTag.taggableCouponLoading,
    currentRouteName: state.ui.currentRouteName,
    token: state.reservation.token
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createReservation,
      updateQuantities,
      setDisplayConfigurationError,
      addAlert,
      triggerTransition
    },
    dispatch
  );
};

CheckoutButton.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutButton);
