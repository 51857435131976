/**
 * Given two arrays of tickets, products, or bundles, return
 * which items have been added to or removed from the original array
 * @param  array oldItems array of objects
 * @param  array newItems array of objects
 * @return object
 */
const getInventoryItemsAddedAndRemoved = (oldItems, newItems) => {
    const result = {added: [], removed: []};
    if (Array.isArray(oldItems) && Array.isArray(newItems)) {
        oldItems.forEach(oldItem => {
            if (oldItem?.id) {
                if (!inventoryItemInArrayOfInventoryItems(oldItem, newItems)) {
                    result.removed.push(oldItem);
                }
            }
        });
        newItems.forEach(newItem => {
            if (newItem?.id) {
                if (!inventoryItemInArrayOfInventoryItems(newItem, oldItems)) {
                    result.added.push(newItem);
                }
            }
        });
    }
    return result;
}

/**
 * Check if inventory object is in array of inventory objects
 */
const inventoryItemInArrayOfInventoryItems = (object, arrayOfObjects) => {
    let found = false;
    arrayOfObjects.forEach(testObject => {
        if (testObject?.id && +object.id === +testObject.id) {
            found = true;
        }
    });
    return found;
}

/**
 * Format an inventory item object as returned by the server for use in checkout
 */
export const formatInventoryItemFromServer = item => {
    return {id: item.id, ...item.attributes};
}

/**
 * Dispatch action to set tickets, product sales, or bundle sales in state
 * Optionally dispatch cart update hook actions
 * @param  array     newItems array of inventory items from server
 * @param  array     oldItems array of inventory items currently in state
 * @param  function  setAction action to be dispatched to set the items in state
 * @param  function  addAction action to be dispatched when items added
 * @param  function  removeAction action to be dispatched when items removed
 * @param  bool      fireHooks
 */
export const setInventoryItemsFromServer = (
    newItems,
    oldItems,
    setAction,
    addAction,
    removeAction,
    fireHooks = true
) => {
    return (dispatch, getState) => {
        if (fireHooks && Array.isArray(newItems)) {
            const compareResult = getInventoryItemsAddedAndRemoved(
                oldItems,
                newItems.map(item => formatInventoryItemFromServer(item))
            );
            if (compareResult.added.length) {
                dispatch(addAction(compareResult.added));
            }
            if (compareResult.removed.length) {
                dispatch(removeAction(compareResult.removed));
            }
        }
        dispatch(setAction(newItems));
    }
}
