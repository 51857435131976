import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoadingState {
    states: Object;
}

const initialState: ILoadingState = {
    states: {},
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addDefaultCase((state, action) => {
            let matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);
            if(matches) {
                let newState = {...state};
                newState.states = {...newState.states};

                newState.states[matches[1]] = matches[2];

                return newState;
            } else {
                return state;
            }
        });
    },
});
