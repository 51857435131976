const JQUERY_URL   = "https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js";
const POLYFILL_URL = "/js/jquery.browser-polyfill.js";

function loadScript(url) {
    return new Promise((resolve, reject) => {
        var scr = document.createElement('script');
        scr.src = url;
        scr.addEventListener('load', resolve);
        document.body.appendChild(scr);
    });
}

async function getSidekick(adminUrl) {
    if(typeof Sidekick === "undefined") { // eslint-disable-line
        await loadScript(JQUERY_URL);
        await loadScript(adminUrl+POLYFILL_URL);
        await loadScript(adminUrl+'/js/sidekick.js');
    }
    return Sidekick;
}

export default getSidekick;
