import React, { createContext, useContext } from 'react';
import { CheckoutProcessContext } from '../../CheckoutProcess/contexts/CheckoutProcessContext';

export const PaymentContext = createContext({});

export const usePaymentContenxt = () => {
  const ctx = useContext(PaymentContext);

  if (!ctx) {
    throw new Error('Payment context provider not found!');
  }

  return ctx;
};

export const PaymentContextProvider = ({ children, value }) => {
  const { TranslationContext, LocalizedAddress, ...rest } = value;
  
  return (
    <CheckoutProcessContext.Provider value={{ TranslationContext, LocalizedAddress }}>
      <PaymentContext.Provider value={{ TranslationContext, LocalizedAddress, ...rest }}>
        {children}
      </PaymentContext.Provider>
    </CheckoutProcessContext.Provider>
  );
};
