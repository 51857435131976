function shouldDefineAutofillHandler() {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  const isInAppBrowser = ua.includes('FBAN') || ua.includes('FBAV') || ua.includes('Instagram');
  const isSafariOrWebView = /iPhone|iPod|iPad/.test(ua) && ua.includes('AppleWebKit');
  return isInAppBrowser || isSafariOrWebView;
}

if (shouldDefineAutofillHandler()) {
  window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};
}
