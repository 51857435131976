import { setLoading } from '../../../actions/loading';
import { createLoadingSelector } from '../../../selectors/helpers';
import { getCurrentMonth, getNumberOfMonths, getVisibleCalendarDays } from '../selectors/eventSeries';
import { setPriceLevel } from '../../ticket-picker/actions/ticket-picker';
import { setBundle, setBundleProductConfig } from '../../bundle-picker/actions/bundle-picker';
import { addAlert, clearAlerts } from '../../../actions/ui';
import { wrappedFetch } from '../../../helpers/fetch';

// used to throttle the call to the inventory service so we don't spam it
// as someone's making their selection
const INVENTORY_SERVICE_TIMEOUT = 250; // ms
let inventoryThrottle = null;

const queryInventory = () => {
    return (dispatch, getState) => {
        let state = getState();
        let url = state.ui.apiUrl;
        let pls = state.inventoryFilter.priceLevelQuantities;
        let bundles = state.inventoryFilter.bundleQuantities;
        if (!Object.keys(pls).length && !Object.keys(bundles).length) {
            dispatch(clearFilterResults());
            return;
        }
        let payload = {};
        if (Object.keys(pls).length) payload.price_levels = pls;
        if (Object.keys(bundles).length) payload.bundles = bundles;
        let dates = getVisibleCalendarDays(getCurrentMonth(state), getNumberOfMonths(state));
        if (dates.length) {
            let startDateParts = dates[0].split('-');
            let endDateParts = dates[dates.length - 1].split('-');
            payload.start_date = new Date(startDateParts[0], +startDateParts[1] - 1, startDateParts[2]).toISOString();
            payload.end_date = new Date(endDateParts[0], +endDateParts[1] - 1, endDateParts[2]).toISOString();
        }

        if (createLoadingSelector("INVENTORY_FILTER")(state)) {
            return Promise.resolve();
        }

        dispatch(setLoading("INVENTORY_FILTER_REQUEST"));
        return wrappedFetch(url + '/inventory?filter=' + JSON.stringify(payload), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if(response.status === 200) {
                return response.json();
            } else {
                dispatch(setLoading("INVENTORY_FILTER_FAILURE"));
            }
        }).then(result => {
            dispatch(setLoading("INVENTORY_FILTER_SUCCESS"));
            dispatch(setFilterResults(result));
        }).catch(err => {
            dispatch(setLoading("INVENTORY_FILTER_FAILURE"));
            console.error(err);
        });
    }
}

const clearFilterResults = () => {
    return setFilterResults(null);
}

const setFilterResults = value => {
    return {type: "SET_FILTER_RESULTS", value};
}

export function incrementPriceLevelFilterQuantity(id) {
    return (dispatch, getState) => {
        let state = getState();
        let quantityArray = state.inventoryFilter.priceLevelQuantityArrays[id];
        let currentQuantity = state.inventoryFilter.priceLevelQuantities[id];
        if (Array.isArray(quantityArray) && quantityArray.length) {
            if (!currentQuantity) {
                dispatch(setPriceLevelFilterQuantity(id, quantityArray[0]));
            } else {
                let currentIndex = quantityArray.indexOf(currentQuantity);
                if (currentIndex !== -1 && quantityArray.length > (currentIndex + 1)) {
                    dispatch(setPriceLevelFilterQuantity(id, quantityArray[currentIndex + 1]));
                }
            }
        }
    }
}

export function decrementPriceLevelFilterQuantity(id) {
    return (dispatch, getState) => {
        let state = getState();
        let quantityArray = state.inventoryFilter.priceLevelQuantityArrays[id];
        let currentQuantity = state.inventoryFilter.priceLevelQuantities[id];
        if (Array.isArray(quantityArray) && quantityArray.length && currentQuantity) {
            let currentIndex = quantityArray.indexOf(currentQuantity);
            if (currentIndex !== -1) {
                if (currentIndex === 0) {
                    dispatch(setPriceLevelFilterQuantity(id, undefined));
                } else {
                    dispatch(setPriceLevelFilterQuantity(id, quantityArray[currentIndex - 1]));
                }
            }
        }
    }
}

export function setPriceLevelFilterQuantity(id, value) {
    return (dispatch, getState) => {
        let state = getState();
        let quantityArray = state.inventoryFilter.priceLevelQuantityArrays[id];
        if (Array.isArray(quantityArray) && quantityArray.length) {
            if (quantityArray.indexOf(value) !== -1 || value === undefined) {
                dispatch({type: "SET_PRICE_LEVEL_FILTER_QUANTITY", id, value});
                dispatch(throttleQueryInventory);
            }
        }
    }
}

export function incrementBundleFilterQuantity(id) {
    return (dispatch, getState) => {
        let state = getState();
        let quantityArray = state.inventoryFilter.bundleQuantityArrays[id];
        let currentQuantity = state.inventoryFilter.bundleQuantities[id];
        if (Array.isArray(quantityArray) && quantityArray.length) {
            if (!currentQuantity) {
                dispatch(setBundleFilterQuantity(id, quantityArray[0]));
            } else {
                let currentIndex = quantityArray.indexOf(currentQuantity);
                if (currentIndex !== -1 && quantityArray.length > (currentIndex + 1)) {
                    dispatch(setBundleFilterQuantity(id, quantityArray[currentIndex + 1]));
                }
            }
        }
    }
}

export function decrementBundleFilterQuantity(id) {
    return (dispatch, getState) => {
        let state = getState();
        let quantityArray = state.inventoryFilter.bundleQuantityArrays[id];
        let currentQuantity = state.inventoryFilter.bundleQuantities[id];
        if (Array.isArray(quantityArray) && quantityArray.length && currentQuantity) {
            let currentIndex = quantityArray.indexOf(currentQuantity);
            if (currentIndex !== -1) {
                if (currentIndex === 0) {
                    dispatch(setBundleFilterQuantity(id, undefined));
                } else {
                    dispatch(setBundleFilterQuantity(id, quantityArray[currentIndex - 1]));
                }
            }
        }
    }
}

export function setBundleFilterQuantity(id, value) {
    return (dispatch, getState) => {
        let state = getState();
        let quantityArray = state.inventoryFilter.bundleQuantityArrays[id];
        if (Array.isArray(quantityArray) && quantityArray.length) {
            if (quantityArray.indexOf(value) !== -1 || value === undefined) {
                dispatch({type: "SET_BUNDLE_FILTER_QUANTITY", id, value});
                dispatch(throttleQueryInventory);
            }
        }
    }
}

export function throttleQueryInventory(dispatch) {
    if(inventoryThrottle) {
        clearTimeout(inventoryThrottle);
    }

    inventoryThrottle = setTimeout(() => {
        dispatch(queryInventory());
    }, INVENTORY_SERVICE_TIMEOUT);
}

/**
 * Attempt to set the ticketpicker to the filtered items
 *
 * If any of the requested quantities are no longer available, it sets the item to the
 * first available quantity less than the requested amount + sets an error message
 */
export function applyFiltersToTicketPicker() {
    return (dispatch, getState) => {
        dispatch(clearAlerts());
        let state = getState();
        let errorFound = false; //set error message if quantity requested is no longer available
        let plFilters = state.inventoryFilter.priceLevelQuantities;
        let blFilters = state.inventoryFilter.bundleQuantities;
        if (Object.keys(plFilters).length) {
            Object.keys(plFilters).forEach(parentId => {
                let requestedValue = plFilters[parentId];
                let childId = null;
                Object.values(state.priceLevel.levels).forEach(level => {
                    if (+parentId === +level.parent_id) {
                        childId = level.price_level_id;
                    }
                });
                if (childId && Object.keys(state.ticketPicker.quantityArrays).indexOf(childId) !== -1 && Array.isArray(state.ticketPicker.quantityArrays[childId])) {
                    if (state.ticketPicker.quantityArrays[childId].indexOf(requestedValue) !== -1) {
                        dispatch(setPriceLevel(childId, requestedValue));
                    } else {
                        errorFound = true;
                        if (state.ticketPicker.quantityArrays[childId].length) {
                            let reversed = [...state.ticketPicker.quantityArrays[childId]].reverse();
                            for (let i = 0; i < reversed.length; i++) {
                                if (reversed[i] < requestedValue) {
                                    dispatch(setPriceLevel(childId, reversed[i]));
                                    break;
                                }
                            }
                        }
                    }
                }
            });
        }
        // if (Object.keys(blFilters).length) {
        //     Object.keys(blFilters).map(parentId => {
        //         let requestedValue = blFilters[parentId];
        //         let childId = null;
        //         let childBundle = null;
        //         Object.values(state.bundles.bundles).map(bundle => {
        //             if (+parentId === +bundle.parent_id) {
        //                 childId = bundle.id;
        //                 childBundle = bundle;
        //             }
        //         });
        //         if (childId && Object.keys(state.bundlePicker.bundleQuantityArrays).indexOf(childId) !== -1 && Array.isArray(state.bundlePicker.bundleQuantityArrays[childId])) {
        //             if (state.bundlePicker.bundleQuantityArrays[childId].indexOf(requestedValue) !== -1) {
        //                 dispatch(setBundle(childId, requestedValue));
        //                 Object.values(childBundle.contents.products).map(product => {
        //                     if (product.single_configuration) {
        //                         dispatch(setBundleProductConfig({
        //                             bundleId: childBundle.id,
        //                             index: 0,
        //                             productId: product.product_id,
        //                             configurationId: product.configurations[0].product_configuration_id,
        //                         }));
        //                     }
        //                 });
        //             } else {
        //                 errorFound = true;
        //                 if (state.bundlePicker.bundleQuantityArrays[childId].length) {
        //                     let reversed = [...state.bundlePicker.bundleQuantityArrays[childId]].reverse();
        //                     for (let i = 0; i < reversed.length; i++) {
        //                         if (reversed[i] < requestedValue) {
        //                             dispatch(setBundle(childId, reversed[i]));
        //                             Object.values(childBundle.contents.products).map(product => {
        //                                 if (product.single_configuration) {
        //                                     dispatch(setBundleProductConfig({
        //                                         bundleId: childBundle.id,
        //                                         index: 0,
        //                                         productId: product.product_id,
        //                                         configurationId: product.configurations[0].product_configuration_id,
        //                                     }));
        //                                 }
        //                             });
        //                             break;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     });
        // }
        if (errorFound) {
            dispatch(addAlert("checkout__filter_quantities_different_from_inventory_quantities", "warning", true));
        }

        return !errorFound;
    }
}
