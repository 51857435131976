import React, { Component } from 'react';

/**
 * Simple pattern for handling components that render differently
 * in online vs kiosk checkout
 */
class KioskableComponent extends Component {

    isKiosk = () => {
        return !!this.props.isKiosk;
    }

    render() {
        return this.isKiosk() ? this.renderKiosk() : this.renderOnline();
    }

    renderOnline() {
        return null;
    }

    renderKiosk = () => {
        return this.renderOnline();
    }
};

export default KioskableComponent;
