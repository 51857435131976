import React, { Component } from 'react';
import { connect } from 'react-redux'
import { inLastStep } from '../../../selectors/ui';
import QuestionOptIn from '../components/QuestionOptIn';

class QuestionOptInContainer extends Component {
  render() {

    if (!this.props.isInLastStep || !this.props.opt_in_questions) {
      return null;
    }

    return (
      <section className="checkout-form__section">
      {this.props.opt_in_questions.map((opt_in_question, i) => {
        return <QuestionOptIn opt_in_question={opt_in_question} key={opt_in_question.question_id}/>;
      })}
      </section>
    );

  }

}

const mapStateToProps = state => {
  return {
    opt_in_questions: state.questions.opt_in_questions,
    isInLastStep: inLastStep(state),
  }
}

QuestionOptInContainer.propTypes = {
}

export default connect(mapStateToProps)(QuestionOptInContainer);
