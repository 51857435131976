import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import KioskSelectLanguageButtonContainer from '../../checkout/views/kiosk/containers/KioskSelectLanguageButtonContainer';
import KioskSelectLanguageContainer from '../../checkout/views/kiosk/containers/KioskSelectLanguageContainer';
import KioskOverlay from './KioskOverlay';

class KioskListing extends Component {
    render() {
        return(
            <div className="kiosk__listing">
                <div className="kiosk__listing__select-language">
                    <KioskSelectLanguageButtonContainer />
                    {this.props.languageModal && (
                        <KioskOverlay includeHeader={false} includeFooter={false}>
                            <KioskSelectLanguageContainer />
                        </KioskOverlay>
                    )}
                </div>
                <div className="kiosk__content">
                    {this.props.children}
                </div>
                <div className="kiosk__background-image">
                </div>
            </div>
        )
    }
}

KioskListing.propTypes = {
}

const mapStateToProps = (state, props) => {
    return {
        languageModal: state.ui.languageModal,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KioskListing);
