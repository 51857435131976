import { getCheckoutClient } from '../config/CheckoutClient';
import { createReservationIfNotExists, fetchReservation, deleteReservation } from './reservation';
import { getReservationEventId, reservationActive } from '../selectors/reservation';
import { addServerErrors, addAlert } from './ui';
import { deleteTicketsById , setTickets } from './tickets';

export const PYOS_SELECTING_SEATS_REASON = 'Reserving...';
export const PYOS_REMOVING_SEATS_REASON = 'Removing...';
export const PYOS_UPDATE_OPTION_REASON = 'Updating option';

export function setBestAvailableQuantities(val, subReducerKey = null) {
  return { type: 'RESERVED_SEATING_SET_BEST_AVAILABLE_QUANTITIES', val, subReducerKey };
}

export function setSectionLevelQuantities(val, subReducerKey = null) {
  return { type: 'RESERVED_SEATING_SET_SECTION_LEVEL_QUANTITIES', val, subReducerKey };
}

export function setPyosLoading(val, reason) {
  return { type: 'RESERVED_SEATING_SET_PYOS_LOADING', val, reason };
}

export const cartSeats = (seatObjects, usesLabels) => {
  return async (dispatch, getState) => {
    const creatingReservation = await dispatch(createReservationIfNotExists());
    const objectIdentifier = usesLabels ? 'label' : 'uuid';

    if (reservationActive(getState())) {
      const event_id = getReservationEventId(getState());

      // separate requests that have section level
      const grouped = { noSectionLevel: [], bySectionLevel: {} };
      (Array.isArray(seatObjects) ? seatObjects : [])
        .filter((obj) => !!obj?.[objectIdentifier])
        .forEach((obj) => {
          if (!obj?.sectionLevelId) {
            grouped.noSectionLevel.push(obj[objectIdentifier]);
          } else {
            if (!grouped.bySectionLevel[obj.sectionLevelId]) {
              grouped.bySectionLevel[obj.sectionLevelId] = [];
            }
            grouped.bySectionLevel[obj.sectionLevelId].push(obj[objectIdentifier]);
          }
        });

      const payload = {
        tickets: [],
      };
      if (grouped.noSectionLevel.length) {
        payload.tickets.push({
          event_id,
          event_seat_ids: grouped.noSectionLevel,
        });
      }
      Object.keys(grouped.bySectionLevel).forEach((section_level_id) => {
        payload.tickets.push({
          event_id,
          section_level_id,
          event_seat_ids: grouped.bySectionLevel[section_level_id],
        });
      });

      const state = getState();
      return dispatch(
        sendRequest(async () => {
          return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(
            state.reservation.token,
            'tickets',
            payload,
            state.reservation.secret,
          );
        }, false),//why false? if cart seats error, delete reservation making empty UI, can's see error info.
      );
    }
  };
};

export const removeSeat = (seatObject) => {
  return async (dispatch, getState) => {
    const state = getState();
    let seatId = '';
    if (typeof seatObject?.seatId === 'string') {
      seatId = seatObject.seatId;
    }
    const ticket = state.tickets.tickets.find((ticket) => {
      if (seatId.includes(`${ticket?.seat_info?.row}-${ticket?.seat_info?.seat}`)) {
        return true;
      }
      if (!ticket?.seat_info?.row && ticket?.seat_info?.seat == seatId) {
        return true;
      }
      return false;
    });
    if (ticket) {
      await dispatch(removeTicket(ticket.id));
    }
  };
};

export const removeTicket = (ticketId, preSaleValid = false) => {
  return async (dispatch, getState) => {
    return dispatch(
      sendRequest(async () => {
        return dispatch(deleteTicketsById([ticketId]));
      }, false, !preSaleValid),
    );
  };
};

export const setGaQuantity = (pricing_level_id, quantity, seatsio_ga_area_id, usesLabels) => {
  return async (dispatch, getState) => {
    let currentTickets = [];

    if (!usesLabels) {
      currentTickets = getState().tickets.tickets.filter((ticket) => +ticket?.price_level_id === +pricing_level_id);
    } else {
      currentTickets = getState().tickets.tickets.filter(
        (ticket) => ticket?.seat_info?.seatsio_ga_id === seatsio_ga_area_id,
      );
    }

    const currentQuantity = currentTickets.length;

    if (quantity == currentQuantity) {
      return true;
    }

    const creatingReservation = await dispatch(createReservationIfNotExists());

    /**
     * If increasing quantity, POST to add tickets
     * If decreasing quantity, find tickets in this level and send PATCH to delete some of them
     */
    if (reservationActive(getState())) {
      const event_id = getReservationEventId(getState());
      if (quantity > currentQuantity) {
        const payload = {
          tickets: [
            {
              event_id,
              pricing_level_id,
              seatsio_ga_area_id,
              quantity: quantity - currentQuantity,
              force_ga: true,
            },
          ],
        };
        const state = getState();
        return dispatch(
          sendRequest(async () => {
            return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(
              state.reservation.token,
              'tickets',
              payload,
              state.reservation.secret,
            );
          }, creatingReservation),
        );
      } else {
        const priceLevel = Object.values(getState().priceLevel.levels).find(
          (level) => level?.price_level_id == pricing_level_id,
        );
        if (priceLevel) {
          return dispatch(
            sendRequest(async () => {
              return dispatch(
                deleteTicketsById(currentTickets.map((ticket) => +ticket.id).slice(0, currentQuantity - quantity)),
              );
            }, creatingReservation),
          );
        }
      }
    }
  };
};

export const changeSectionLevel = (ticket_id, section_level_id) => {
  return async (dispatch, getState) => {
    const payload = {
      tickets: [
        {
          ticket_id,
          section_level_id,
        },
      ],
    };

    const state = getState();
    return dispatch(
      sendRequest(async () => {
        return getCheckoutClient(state.ui.apiUrl).reservationUpdateRequest(
          state.reservation.token,
          'tickets/update-section-levels',
          payload,
          state.reservation.secret,
        );
      }, false),
    );
  };
};

export const submitReserveCoupon = (code) => {
  return async (dispatch, getState) => {
    const creatingReservation = await dispatch(createReservationIfNotExists());
    const deleteIfNoTickets = false;

    if (reservationActive(getState())) {
      const event_id = +getReservationEventId(getState());

      const payload = {
        event_id,
        code,
      };

      const state = getState();
      return dispatch(
        sendRequest(
          async () => {
            return getCheckoutClient(state.ui.apiUrl).reservationCreateRequest(
              state.reservation.token,
              'reserve-coupon-usage',
              payload,
              state.reservation.secret,
            );
          },
          creatingReservation,
          deleteIfNoTickets,
        ),
      );
    }
  };
};

const sendRequest = (sendReqFunction, creatingReservation, deleteIfNoTickets = true) => {
  return async (dispatch, getState) => {
    let response = null;
    try {
      response = await sendReqFunction();
      if (!response?.ok) {
        throw new Error('Bad response');
      }
    } catch (err) {
      if (err.response && err.response.body) {
        dispatch(addServerErrors(err.response.body.errors));
      } else {
        dispatch(addAlert(err));
      }
      if (creatingReservation) {
        dispatch(deleteReservation(false));
      }
    }
    if (response?.ok) {
      await dispatch(fetchReservation(getState().reservation.token, getState().reservation.secret));
      const state = getState();
      if (!+state.reservation?.ticket_count && !deleteIfNoTickets) {
        dispatch(setTickets([]));
      }
      if (!+state.reservation?.ticket_count && deleteIfNoTickets) {
        /**
         * if this action resulted in the cart being empty, destroy this reservation.
         * Only reset the UI if the user made it to checkout
         */
        const routesTransitionedTo = Array.isArray(state.ui.routesTransitionedTo) ? state.ui.routesTransitionedTo : [];
        const resetUi = routesTransitionedTo.includes('checkout');
        const clearChildEvent = resetUi;
        dispatch(deleteReservation(resetUi, clearChildEvent));
      }
    }
    return response;
  };
};
