import React, { Component } from 'react';
import PropTypes from 'prop-types';
import localizations from '@showclix-shared-util/localizations';
import ConnectedAddress from './ConnectedAddress';
import _ from 'lodash';

const LocalizedAddress = (WrappedComponent) => {
    return class LocalizedAddress extends Component {
        getSettings(country_code, localizations) {
            return _.defaultsDeep(_.get(localizations, country_code), localizations.defaults);
        };

        render() {
            let childProps = {...this.props};
            if(childProps.country === "") {
                childProps.country = "";
            }

            let settings = {
                local_settings: this.getSettings(childProps.country, localizations)
            };

            return(
                <WrappedComponent {...childProps} {...settings} />
            )
        }
    }
}

LocalizedAddress.defaultProps = {
    country: 'US'
}

export default LocalizedAddress(ConnectedAddress);
