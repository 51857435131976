import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

export default class Page extends PureComponent {
    /**
     * Classes that extend Page require a map property.  The map property is an
     * object that contains strings that correspond to React component classes.
     * Page uses this to map strings in the settings object to actual React components
     * that it should render.
     *
     * Example:
     *
     * map = {
     *     componentString: ClassThatExtendsReactComponent,
     * };
     */
    render() {
        if (!this.map || typeof this.map !== 'object') {
            console.warn("Invalid map property specified for component that extends Page: " + this.constructor.name);
            return null;
        }
        return (
            <Fragment>
                {this.getComponents()}
            </Fragment>
        );
    }

    getComponent = (component, i = undefined) => {
        let props = component.props ? component.props : {};

        if(i !== undefined) {
            props.key = i;
        } else {
            props.key = component.name;
        }

        let children = [];

        if(component.children) {
            for(let i = 0; i < component.children.length; i++) {
                children.push(this.getComponent(component.children[i], i));
            }
        }

        return React.createElement(this.getComponentTypeFromMap(component.name), props, children);
    }

    getComponentTypeFromMap = (componentName) => {
        return this.map[componentName];
    }

    isValidComponent = (component) => {
        if(!this.map[component.name]) {
            this.warnInvalidComponent(component);
            return false;
        }

        if(component.children) {
            for(let i = 0; i < component.children.length; i++) {
                if(!this.isValidComponent(component.children[i])) {
                    return false;
                }
            }
        }

        return true;
    }

    getComponents = () => {
        const components = [];

        const list = this.props.settings.components || [];

        for(let i = 0; i < list.length; i++) {
            const comp = list[i];

            if(!this.isValidComponent(comp)) {
                continue;
            }

            components.push(this.getComponent(comp));
        }

        return components;
    }

    warnInvalidComponent = (component) => {
        console.warn("Component is not valid for " + this.constructor.name + ": " + JSON.stringify(component));
    }
}


Page.propTypes = {
}
