import { getCheckoutClient } from "../../../config/CheckoutClient";
import { sendBulkRequest } from '../../../actions/reservation';
import { getDonationsPayload } from '../../../selectors/request';

export function setHasDonations(hasDonations) {
    return {type: "SET_HAS_DONATIONS", hasDonations};
}

export function setDonationTitle(donationTitle) {
    return {type: "SET_DONATION_TITLE", donationTitle};
}

export function setDonationOrganizations(donationOrganizations) {
    return {type: "SET_DONATION_ORGANIZATIONS", donationOrganizations};
}

export function setUserDonation(eventId, value) {
    return {type: "SET_USER_DONATION", eventId, value};
}

export function setTotalReservationDonation(value) {
    return {type: "SET_TOTAL_RESERVATION_DONATION", value};
}

export function fetchDonations(token, secret) {
    return (dispatch, getState) => {
        let state = getState();
        return getCheckoutClient(state.ui.apiUrl).reservationGetRequest(token, 'donations', secret).then((result) => {
            dispatch(setHasDonations(result.body.data.attributes.has_donations));
            dispatch(setDonationOrganizations(result.body.data.attributes.donation_organizations));
            dispatch(setDonationTitle(result.body.data.attributes.donation_title));
            if(result && result.body && result.body.data && result.body.data.attributes && result.body.data.attributes.donationsByEvent) {
                setUserDonationsByOrg(result.body.data.attributes.donationsByEvent, result.body.data.attributes.donation_organizations, dispatch);
            }
            dispatch(setTotalReservationDonation(result.body.data.attributes.donation));
            return Promise.resolve();
        });
    }
}

export function updateDonations() {
    return (dispatch, getState) => {
        const state = getState();
        const payload = [{type:'donation', payload: {donations: getDonationsPayload(state)}}];
        return sendBulkRequest(state.ui.apiUrl, state.reservation.token, state.reservation.secret, payload).then(res => {
            dispatch(setDonationOrganizations(res.donation.attributes.donation_organizations));
            if (res && res.body && res.body.data && res.body.data.attributes && res.body.data.attributes.donationsByEvent) {
                setUserDonationsByOrg(res.body.data.attributes.donationsByEvent, res.donation.attributes.donation_organizations, dispatch);
            }
            dispatch(setTotalReservationDonation(res.donation.attributes.donation));
        });
    }
}

/**
 * Set the user donation amount for each org as returned by the server
 */
export const setUserDonationsByOrg = (donationsByEvent, donationOrgs, dispatch) => {
    if (typeof donationsByEvent === 'object' && donationsByEvent !== null) {
         Object.keys(donationsByEvent).forEach((eventId) => {
            let donationAmount = +donationsByEvent[eventId];
            if (Array.isArray(donationOrgs)) {
                // check if donation is per ticket and adjust accordingly
                donationOrgs.forEach(donationOrg => {
                    if (+donationOrg?.event_id === +eventId && !!donationOrg.per_tik && +donationOrg.tix > 0) {
                        donationAmount = donationAmount / +donationOrg.tix;
                    }
                });
            }
            dispatch(setUserDonation(eventId, donationAmount));
        });
    }
}
