import { getCheckoutClient } from '../config/CheckoutClient';
import { fetchReservation, deleteReservation } from './reservation';
import { setLoading } from 'checkout/actions/loading';
import { addServerErrors } from './ui';
import { setBundlesQuantities } from '../views/bundle-picker/actions/bundle-picker';
import { isReservationEmpty } from 'checkout/selectors/reservation';
import { updateDonations } from '../views/donations/actions/donations';
import { setInventoryItemsFromServer } from '../helpers/inventoryItems';

export const setBundleSales = (bundles, fireHooks = true) => {
  return (dispatch, getState) => {
    dispatch(
      setInventoryItemsFromServer(
        bundles,
        getState().bundleSales?.bundles ?? [],
        (bundles) => {
          return { type: 'SET_BUNDLE_SALES', bundles };
        },
        (bundles) => {
          return { type: 'BUNDLES_ADDED_TO_CART', bundles };
        },
        (bundles) => {
          return { type: 'BUNDLES_REMOVED_FROM_CART', bundles };
        },
        fireHooks,
      ),
    );
  };
};

export const setBundleSaleName = (id, value) => {
  return { type: 'SET_BUNDLE_SALE_NAME', id, value };
};

export const setRawBundleSalesState = (state) => ({
  type: 'SET_RAW_BUNDLES_SALES_STATE',
  state,
});

export function fetchBundles(token, secret, fireHooks = true) {
  return (dispatch, getState) => {
    let state = getState();
    getCheckoutClient(state.ui.apiUrl)
      .reservationGetRequest(token, 'bundlesale', secret)
      .then((result) => {
        dispatch(setBundleSales(result.body.data, fireHooks));
        let soldBundles = getSoldOrHeldBundles(result.body.data);
        // updated quantities
        let bundlesQuantities = buildBundlesQuantities(state.bundlePicker, soldBundles);
        dispatch(setBundlesQuantities(bundlesQuantities));
      });
  };
}

export function updateBundleQuantities(bundlePayload, token, secret, reFetch = true, reSubmitDonations) {
  return (dispatch, getState) => {
    let state = getState();

    let bundleId = bundlePayload.bundles[0].bundleId;
    dispatch(setLoading('BUNDLE_QUANTITY_' + bundleId + '_REQUEST'));

    return getCheckoutClient(state.ui.apiUrl)
      .reservationUpdateRequest(token, 'bundlesale', bundlePayload, secret)
      .then((result) => {
        if (reFetch) {
          const doRefetch = () => {
            return Promise.all([
              fetchReservation(token, secret)(dispatch, getState),
              fetchBundles(token, secret)(dispatch, getState),
            ])
              .then(() => {
                dispatch(setLoading('BUNDLE_QUANTITY_' + bundleId + '_SUCCESS'));
                if (isReservationEmpty(getState())) {
                  dispatch(deleteReservation());
                }
              })
              .catch((e) => {
                dispatch(setLoading('BUNDLE_QUANTITY_' + bundleId + '_FAILURE'));
              });
          };

          //if updating donations, needs to happen before refetching so that
          //the reservation data reflects the updated donation amount(s)
          if (reSubmitDonations) {
            dispatch(updateDonations())
              .catch((e) => {
                //do nothing. At this point the event may have been removed from the reservation
                //as a result of removing tickets which will cause the donation request to 400.
                //Any actual errors will be reflected in the reservation when it is refetched.
              })
              .finally(() => {
                doRefetch();
              });
          } else {
            doRefetch();
          }
        } else {
          dispatch(setLoading('BUNDLE_QUANTITY_' + bundleId + '_SUCCESS'));
          return fetchBundles(token, secret)(dispatch, getState);
        }
      })
      .catch((error) => {
        dispatch(addServerErrors(error.response.body.errors));
        dispatch(setLoading('BUNDLE_QUANTITY_' + bundleId + '_FAILURE'));
      });
  };
}

export function addBundles(bundlePayload, token, secret, reFetch = false) {
  return (dispatch, getState) => {
    let state = getState();

    return getCheckoutClient(state.ui.apiUrl)
      .reservationCreateRequest(token, 'bundlesale', bundlePayload, secret)
      .then((bundles) => {
        setBundleSales(bundles.body.data);
        if (reFetch) {
          return Promise.all([
            fetchReservation(token, secret)(dispatch, getState),
            fetchBundles(token, secret)(dispatch, getState),
          ]).catch((e) => {});
        } else {
          return fetchBundles(token, secret)(dispatch, getState);
        }
      })
      .catch((error) => {
        dispatch(addServerErrors(error.response.body.errors));
      });
  };
}

function buildBundlesQuantities(bundlePicker, soldBundles) {
  let quantityArrays = { ...bundlePicker.initialQuantities };
  Object.keys(quantityArrays).forEach((bundleId) => {
    if (soldBundles[bundleId]) {
      quantityArrays[bundleId] = quantityArrays[bundleId].slice(
        0,
        quantityArrays[bundleId].length - soldBundles[bundleId],
      );
    }
  });

  return quantityArrays;
}

function getSoldOrHeldBundles(bundles) {
  let counts = {};
  for (let i = 0; i < bundles.length; i++) {
    if (counts[bundles[i].attributes.bundle_id] === undefined) {
      counts[bundles[i].attributes.bundle_id] = 1;
    } else {
      counts[bundles[i].attributes.bundle_id] += 1;
    }
  }

  return counts;
}
