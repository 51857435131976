import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Translation from '@s/Translation';
import { MessagePageBanner, MessagePageBannerVariant } from '../../../../listing/components/MessagePageBanner';

const TaggableCouponBannerContainer = props => {
    const taggableCouponLoading         = useSelector(state => !!state.couponTag.taggableCouponLoading);
    const taggableCodeError             = useSelector(state => !!state.couponTag.taggableCodeError);
    const appliedTaggableAccessCode     = useSelector(state => !!state.couponTag.appliedTaggableAccessCode);
    const appliedTaggableReserveCode    = useSelector(state => !!state.couponTag.appliedTaggableReserveCode);
    const appliedTaggableInvitationCode = useSelector(state => !!state.couponTag.appliedTaggableInvitationCode);
    const appliedTaggablePresaleCode    = useSelector(state => !!state.couponTag.appliedTaggablePresaleCode);
    const appliedTaggableDiscountCode   = useSelector(state => !!state.couponTag.appliedTaggableDiscountCode);

    const banner = (phrase, dismissable = true, variant = MessagePageBannerVariant.success) => (
        <MessagePageBanner
            variant={variant}
            dismissable={dismissable}
        >
            <Translation>{phrase}</Translation>
        </MessagePageBanner>
    );

    if (taggableCouponLoading) {
        return banner('checkout__applying_coupon_code', false);
    }

    return(
        <Fragment>
            {!!taggableCodeError && banner('checkout__invalid_coupon_for_event', true, MessagePageBannerVariant.error)}
            {!!appliedTaggableAccessCode && banner('checkout__access_code_has_been_applied')}
            {!!appliedTaggableReserveCode && banner('checkout__reserve_code_applied')}
            {!!appliedTaggableInvitationCode && banner('checkout__invitation_code_applied')}
            {!!appliedTaggablePresaleCode && banner('checkout__presale_code_has_been_applied')}
            {!!appliedTaggableDiscountCode && banner('checkout__discount_will_be_applied_to_your_order')}
        </Fragment>
    )
}

export default TaggableCouponBannerContainer;
