import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IContactInfoState {
    loading: boolean;
}

const initialState: IContactInfoState = {
    loading: true,
};

export const contactInfoSlice = createSlice({
    name: 'contactInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase('FETCH_EVENT', (state, action) => {
            // @ts-ignore
            return {...state, loading: false, ...action.data.attributes.contactInfo};
        })
        .addDefaultCase((state, action) => {
            return state;
        });
    },
});
