import React, { Component } from 'react';
import {CurrencyContext} from '@c/CurrencyContext';
import { getJSFormattedCurrency } from '@h/currency';

class FormattedCurrency extends Component {
    render() {
        return(
            <CurrencyContext.Consumer>
                {config => {
                    return (
                        <span className="formatted-currency">{getJSFormattedCurrency(this.props.children, config)}</span>
                    )
                }}
            </CurrencyContext.Consumer>
        )
    }
}

export default FormattedCurrency;
