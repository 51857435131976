// @flow
export type SetDataAction = {type: "RESERVATION_DATA_SET", secret: string, reservation: Object, forward: boolean};
export type CreateAction = {type: "RESERVATION_CREATED"};
export type TickAction = {type: "TICK"};
export type TickSetAction = {type: "TICK_SET", interval: number};
export type TickClearAction = {type: "TICK_CLEAR"};
export type ClearAction = {type: "RESERVATION_CLEARED", forward: boolean};
export type AddListingAction = {type: "ADD_LISTING", listing: Object}
export type SetDeliveryMethod = {type: "DELIVERY_METHOD_SET", selectedDelivery: Object}
export type CompletedAction = {type: "RESERVATION_COMPLETED"}
export type LoadingAction = {type: "RESERVATION_LOADING", val: boolean}

export type Action = SetDataAction | TickAction | TickSetAction | ClearAction | TickClearAction | AddListingAction | SetDeliveryMethod | CompletedAction | LoadingAction;

import { getTopWindow } from '@h/window';

type SetLeapOptInAction = {+type: "SET_LEAP_OPT_IN", +opt_in: boolean};

type State = {
    +secret: string,
    +token: string,
    time_remaining: number,
    +leapOptIn: boolean,
    tickInterval: number,
    listings: Array<Object>,
    purchaseUser: number, // admin user to record purchases as
};

let initialState = {
    secret: "",
    token: "",
    time_remaining: 0,
    tickInterval: 0,
    listings: [],
    purchaseUser: 0,
    leapOptIn: false,
};

/** Reducer for reservation actions */
export function reservation(state: State = initialState, action:Action): State {
    if(action.type === "RESERVATION_DATA_SET") {
        let newState = {...state, secret: action.secret, token: action.reservation.id, ...action.reservation.attributes};
        // card types can come across as an empty array, but it should always be an object
        if(Array.isArray(newState.cardTypes)) {
            newState.cardTypes = {};
        }

        return newState;
    } else if(action.type === "TICK") {
        let newState = {...state, time_remaining: state.time_remaining - 1};
        return newState;
    } else if(action.type === "TICK_SET") {
        let newState = {...state, tickInterval: action.interval};
        return newState;
    } else if(action.type === "TICK_CLEAR") {
        getTopWindow().clearInterval(state.tickInterval);
        let newState = {...state, tickInterval: 0};
        return newState;
    } else if(action.type === "RESERVATION_CLEARED") {
        getTopWindow().clearInterval(state.tickInterval);
        let user = state.purchaseUser;
        return {...initialState, purchaseUser: user};
    } else if(action.type === "RESERVATION_COMPLETED") {
        getTopWindow().clearInterval(state.tickInterval);
        let user = state.purchaseUser;
        return {...initialState, purchaseUser: user};
    } else if (action.type == "SET_LEAP_OPT_IN") {
        let newState = {...state};
        newState.leapOptIn = action.opt_in;
        return newState;
    } else if(action.type === "ADD_LISTING") {
        let newState = {...state};
        let found = false;
        for(let i = 0; i < newState.listings.length; i++) {
            if(newState.listings[i].event.event_id === action.listing.event.event_id) {
                found = true;
            }
        }

        if(!found) {
            newState.listings.push(action.listing);
        }

        return newState;
    } else if(action.type === "DELIVERY_METHOD_SET") {
        let newState = {...state};
        newState.selected_delivery_option = action.selectedDelivery.id;
        return newState;
    } else if(action.type === "SET_PURCHASE_USER") {
        let newState = {...state};
        newState.purchaseUser = action.id;
        return newState;
    } else if (action.type === "SET_TOTAL_RESERVATION_DONATION") {
        return { ...state, total_donation: action.value };
    } else {
        return state;
    }
}
