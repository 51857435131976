/* @flow */
import type {FetchAction} from './event';

type State = {+loading: boolean};

type Action = FetchAction;

let initialState = { loading: true };
export function partner(state: State = initialState, action:Action): State {
    if(action.type === "FETCH_EVENT") {
        return {...state, loading: false, ...action.data.attributes.partner};
    } else {
        return state;
    }
}
