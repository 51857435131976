import { getEmbedToken } from './embedToken';

export const wrappedFetch = (url, data = {}) => {
    const embedToken = getEmbedToken();

    let headers = data.headers || {};
    if (embedToken) {
        headers['x-embed-token'] = embedToken;
    }
    data.headers = headers;

    return fetch(url, data);
}

export const wrappedGet = url => {
    return wrappedFetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
    });
}
