export function setKeyboardInput(value) {
    return {type: "SET_KEYBOARD_INPUT", value};
}

export function updateValue(value) {
    return (dispatch, getState) => {
        let state = getState();
        const updateFunc = state.keyboard.updateFunc;
        if(!updateFunc) {
            return;
        }
        let args = [value];
        if(state.keyboard.updateArgs.length > 0) {
            args = [...state.keyboard.updateArgs, value];
        }
        dispatch(updateFunc(...args));
        dispatch(setKeyboardValue(value));
    }
}

function setKeyboardValue(value) {
    return {type: "SET_KEYBOARD_VALUE", value};
}

export function setKeyboardType(value) {
    return {type: "SET_KEYBOARD_TYPE", value}
}

export function setKeyboardLabel(value) {
    return {type: "SET_KEYBOARD_LABEL", value}
}

export function setKeyboardUpdateFunction(func, updateArgs = []) {
    return {type: "SET_KEYBOARD_UPDATE_FUNCTION", func, updateArgs}
}

export function initKeyboard(input, label, updateFunc, type = "text", updateArgs = []) {
    return (dispatch, getState) => {
        dispatch(setKeyboardInput(input));
        dispatch(setKeyboardValue(input.value));
        dispatch(setKeyboardLabel(label));
        dispatch(setKeyboardType(type));
        dispatch(setKeyboardUpdateFunction(updateFunc, updateArgs));
    }
}

export function clearKeyboard() {
    return (dispatch, getState) => {
        dispatch(setKeyboardValue(''));
        dispatch(setKeyboardInput(null));
        dispatch(setKeyboardLabel(''));
        dispatch(setKeyboardUpdateFunction(null));
    }
}
