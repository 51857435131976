/* @flow */
import type {FetchAction} from './event';
import { getReducerState, returnReducerState } from './subReducerHelpers.js';

type State = {+loading: boolean, +bundles: Object}

export type Action = FetchAction;

const defaultState = { 
    loading: true,
    bundles: {}
};
let initialState = defaultState;

export function bundles(state: State = initialState, action:Action): State {

    let newState = getReducerState(state, defaultState, action);

    if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {
        let bundles = {};

        for (const eventId in action.data.attributes.events) {
            let eventBundles = action.data.attributes.events[eventId].bundles;
            for(let i = 0; i < eventBundles.length; i++) {
                bundles[eventBundles[i].id] = eventBundles[i];
            }
        }

        newState.loading = false;
        newState.bundles = bundles;
    } else if(action.type === "SET_BUNDLES") {
        let bundles = {};

        for(let i = 0; i < action.data.length; i++) {
            bundles[action.data[i].id] = action.data[i];
        }

        newState.loading = false;
        newState.bundles = bundles;
    }  else {
        return state;
    }

    return returnReducerState(state, newState, action);
}
