const initialState = {
    // upsell content returned when fetching reservation
    upsells: [],

    // is the upsell popup showing
    showPopup: false,

    // has the upsell popup been shown to the user
    hasPoppedUp: false,

    // is this listing configured for upsells
    shouldFetchUpsells: false,
};

export const UPSELL_TYPE_SINGLE_EVENT = 1;
export const UPSELL_TYPE_SERIES = 2;
export const UPSELL_TYPE_PRODUCT = 3;
export const POSITION_POPUP_BEFORE_REGISTRATION = 'popup_before_registration';
export const POSITION_EMBEDDED_IN_REGISTRATION = 'embedded_in_registration';
export const DISPLAY_TYPE_LIST = 'display_type_list';
export const DISPLAY_TYPE_GRID = 'display_type_grid';
export const ITEM_TYPE_PRICE_LEVEL = 1;
export const ITEM_TYPE_BUNDLE = 2;
export const ITEM_TYPE_PRODUCT = 3;

const UPSELL_REDUCER_KEY_PREFIX = 'UPSELL_';

/**
 * Generate the key for working with upsell data in sub reducers
 */
export const genSubReducerKey = (identifier, type) => {
    return `UPSELL_${type}_${identifier}`;
}

export const isUpsellSubReducerKey = key => {
    return typeof key === 'string' && key.substr(0, UPSELL_REDUCER_KEY_PREFIX.length) === UPSELL_REDUCER_KEY_PREFIX;
}

export function upsells(state = initialState, action) {
    let newState = {...state};
    if (action.type === "SET_UPSELL_CONTENT") {
        newState.upsells = Array.isArray(action.val) ? [...action.val] : [];
        return newState;
    } else if (action.type === "SET_UPSELL_SHOW_POPUP") {
        newState.showPopup = !!action.val;
        if (!!action.val) {
            newState.hasPoppedUp = true;
        }
        return newState;
    } else if (action.type === "SET_SHOULD_FETCH_UPSELLS") {
        newState.shouldFetchUpsells = !!action.val;
        return newState;
    } else if (action.type === "RESERVATION_CLEARED") {
        return {
            ...initialState,
            shouldFetchUpsells: newState.shouldFetchUpsells,
        };
    } else {
        return state;
    }
}
