export function secondsTohhmmss(seconds) {
  if (seconds > 0) {
      var minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
      // var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return minutes + ":" + pad(seconds);
  }
  return '00:00';
}

function pad (num) {
  return ("0" + num).slice(-2);
}

export function ISOStringToDate(string) {
    var parts = string.split(/[^0-9]/);
    return new Date (parts[0], parts[1]-1, parts[2], parts[3], parts[4], parts[5]);
}

export function isDateObject(dateObj) {
    return dateObj && typeof dateObj === 'object' && Object.prototype.toString.call(dateObj) === '[object Date]';
}

export function isValidDateObject(dateObj) {
    return isDateObject(dateObj) && !isNaN(dateObj.getTime());
}

export function dateToYMD(dateObj) {
    if (!isValidDateObject(dateObj)) {
        return null;
    }
    return dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1).toString().padStart(2, "0") + '-' + dateObj.getDate().toString().padStart(2, "0");
}

export function getAllDatesInMonth(year, month) {
    let dates = [];
    let dateCursor = new Date(year, month, 1);
    while(dateCursor.getMonth() === month) {
        let ymd = dateToYMD(dateCursor);
        if (dates.indexOf(ymd) === -1) {
            dates.push(ymd);
        }
        dateCursor.setDate(dateCursor.getDate() + 1);
    }
    return dates;
}

// Convert date string or unix timestamp into Date object
export const dateOrUnixToDate = (date) => {
  const number = Number(date);

  return new Date(isNaN(number) ? date : number);
}
