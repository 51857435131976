// @flow

export type IncrementAction = {type: "INCREMENT_PRICE_LEVEL", id: number};
export type DecrementAction = {type: "DECREMENT_PRICE_LEVEL", id: number};
export type SetAction = {type: "SET_PRICE_LEVEL", id: number, value: number};
export type SetQuantitiesAction = {type: "SET_NEW_QUANTITIES", quantities: Object};
export type FetchAction = {type: "FETCH_EVENT", data: Object};
export type SetDisplayConfigurationErrorAction = {type: "SET_DISPLAY_CONFIGURATION_ERROR", value: Boolean};

export type Action = IncrementAction | DecrementAction | SetAction | FetchAction | SetQuantitiesAction | SetDisplayConfigurationError;

type State = { +quantities: Object, +indexes: Object, +quantityArrays: Object, +availableQuantities: Object, +initialQuantities: Object, +displayConfigurationError: Boolean };

import { getReducerState, returnReducerState, applyToParentAndAllSubReducers } from '../../../reducers/subReducerHelpers';

const defaultState = {
    quantities: {},
    indexes: {},
    quantityArrays: {},
    availableQuantities: {},
    initialQuantities: {} ,
    displayConfigurationError: false,
    pwywPrices: {},
};
let initialState = defaultState;

/** Reducer for ticket picker actions */
export function ticketPicker(state: State = initialState, action:Action): State {

    let newState = getReducerState(state, defaultState, action);

    if (action.type === "PRICE_LEVEL_SET_PWYW_PRICE") {
        let pwywPrices = {...newState.pwywPrices};
        pwywPrices[action.id] = action.price;
        newState.pwywPrices = pwywPrices;
    } else if (action.type === "INCREMENT_PRICE_LEVEL") {
        let id = action.id;
        let quantityArray = newState.quantityArrays[action.id];
        newState.indexes = {...newState.indexes};
        newState.quantities = {...newState.quantities};
        if(newState.indexes[id] === undefined) {
            newState.indexes[id] = 0;
        } else {
            newState.indexes[id] += 1;
        }

        newState.quantities[id] = quantityArray[newState.indexes[id]];
    } else if(action.type === "DECREMENT_PRICE_LEVEL") {
        let id = action.id;
        let quantityArray = newState.quantityArrays[action.id];
        newState.indexes = {...newState.indexes};
        newState.quantities = {...newState.quantities};
        if(newState.indexes[id] === 0) {
            newState.indexes[id] = undefined;
            newState.quantities[id] = undefined;
        } else {
            newState.indexes[id] -= 1;
            newState.quantities[id] = quantityArray[newState.indexes[id]];
        }
    }  else if(action.type === "SET_NEW_QUANTITIES") {
        newState.availableQuantities = typeof action.quantities === 'object' ? {...action.quantities} : action.quantities;
    } else if(action.type === "SET_PRICE_LEVEL") {
        newState.indexes = {...newState.indexes};
        newState.quantities = {...newState.quantities};

        let quantityArray = newState.quantityArrays[action.id];
        const index = quantityArray.indexOf(action.value);
        // only set index and quantity if valid quantity
        if (index > -1) {
            newState.indexes[action.id] = index;
            newState.quantities[action.id] = quantityArray[newState.indexes[action.id]];   
        }
        
    } else if(action.type === "FETCH_EVENT" || action.type === "REFRESH_EVENT") {
        for (const eventId in action.data.attributes.events) {
            let levels = action.data.attributes.events[eventId].price_levels;
            if (levels) {
                for(let i = 0; i < levels.length; i++) {
                    let level = levels[i];
                    newState.quantityArrays[level.price_level_id] = buildPriceLevelQuantityArray(level.minimum_purchase_limit, level.transaction_limit, level.increment_by);
                    newState.initialQuantities[level.price_level_id] = buildPriceLevelQuantityArray(level.minimum_purchase_limit, level.transaction_limit, level.increment_by);
                }
            }
        }
    } else if(action.type === "SET_PRICE_LEVELS") {
        newState.quantityArrays = {};

        for(let i = 0; i < action.data.length; i++) {
            let level = action.data[i];
            newState.quantityArrays[level.price_level_id] = buildPriceLevelQuantityArray(level.minimum_purchase_limit, level.transaction_limit, level.increment_by);
            newState.initialQuantities[level.price_level_id] = buildPriceLevelQuantityArray(level.minimum_purchase_limit, level.transaction_limit, level.increment_by);
        }
    }  else if(action.type === "UPDATE_PRICE_LEVELS") {

        for(let i = 0; i < action.data.length; i++) {
            let level = action.data[i];
            newState.quantityArrays[level.price_level_id] = buildPriceLevelQuantityArray(level.minimum_purchase_limit, level.transaction_limit, level.increment_by);
        }
    } else if(action.type === "SET_TICKETS") {
        newState = applyToParentAndAllSubReducers(newState, s => {
            newState.quantities = {};
            newState.indexes = {};
            return s;
        });
    } else if(action.type === "RESERVATION_CLEARED" || action.type === "CLEAR_SELECTED_PRICE_LEVELS") {
        if(!action.reset && action.type !== "CLEAR_SELECTED_PRICE_LEVELS") {
            return state;
        }
        newState = applyToParentAndAllSubReducers(newState, s => {
            s.quantities = {};
            s.indexes = {};
            s.availableQuantities = {};
            s.displayConfigurationError = false;
            s.pwywPrices = {};
            return s;
        });
    } else if(action.type === "SET_DISPLAY_CONFIGURATION_ERROR") {
        newState.displayConfigurationError = action.value ? true : false;
    } else if(action.type === "CHECKOUT_OVERLAY") {
        if(action.overlay === false) {
            newState.quantities = {};
            newState.indexes = {};
        }
    } else {
        return state;
    }

    return returnReducerState(state, newState, action);
}

export function buildPriceLevelQuantityArray(min = 0, max = 10, step = 1) {
    if (+min == 0) {
        min = +step;
    }

    let quantities = [];

    for(var i = +min; i <= +max; i += +step) {
        quantities.push(i);
    }

    return quantities;
}
