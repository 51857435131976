import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Props from './TermsOfServiceComponent';
import { getXssFilter } from 'checkout/helpers/helpers';
import Translation from "../../../../shared/Translation";

class TermsOfService extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        }
    }
    tosChecked = () => {
        return () => {
            this.setState({
                checked: !this.state.checked
            })
        }
    }
    getLinkStart = (type) => {
      return '<a href="' + this.props[`${type}Url`] + '" target="_blank" >'
    }

    getErrorComponent = () => {
      if (!this.props.error) return null;

      return (
        <p className="input--error__text">
            <Translation>
                {this.props.error}
            </Translation>
        </p>
      )
    }

    getTosTranslation = () => {
      return this.props.useLeapTos ? 'checkout__i_agree_to_tos_and_pp' : 'checkout__i_agree_to_tos';
    }

    getTosInterpolation = () => {
      const tosLinkStart = this.getLinkStart('tos');
      const linkEnd = '</a>';
      
      if (this.props.useLeapTos) {
        return {
          tosLinkStart,
          tosLinkEnd: linkEnd,
          ppLinkStart: this.getLinkStart('pp'),
          ppLinkEnd: linkEnd
        }
      }

      return {
        linkStart: tosLinkStart,
        linkEnd
      }
    }

    render() {
        const checkboxClass = classNames(
          'input__checkbox',
          {
            'tos__checkbox': !this.props.embedTos,
            'embed-tos__checkbox': this.props.embedTos,
            'input__checkbox--selected': this.state.checked
          }
        )

        return (
            <section className="checkout-form__section">
                <div className='tos'>
                    <div className={classNames('input', { ['input--error']: !!this.props.error })}>
                      {this.props.embedTos ? (
                        <div>
                          <div className="input__top hidden">
                              <label className="input__label" htmlFor="tos"><Translation>{'checkout__terms_of_service'}</Translation></label>
                          </div>
                          <div className="input__control tos__textarea" dangerouslySetInnerHTML={{ __html: getXssFilter().process(this.props.tos) }} />
                        </div>
                      ) : null}
                        <div className={checkboxClass}  >
                            <div className="input__checkbox-wrap" >
                                <div className="input__checkbox__input">
                                    <input type="checkbox" name="tos" id="tos" onChange={this.props.tosChanged} onClick={this.tosChecked()} />
                                </div>
                                <label htmlFor="tos" className="input__checkbox__label">
                                    <Translation interpolation={this.getTosInterpolation()}>
                                        {this.getTosTranslation()}
                                    </Translation>
                                </label>
                            </div>
                        </div>
                        {this.getErrorComponent()}
                    </div>
                </div>
            </section>
        )
    }
}

TermsOfService.defaultProps = {
    tosChecked: false,
}

TermsOfService.propTypes = {
    tosChanged: PropTypes.func.isRequired,
    tos: PropTypes.string,
    tosLink: PropTypes.string,
    embedTos: PropTypes.bool,
}

export default TermsOfService
