import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AddTickets from './AddTickets';
import PriceLevel from './PriceLevel';
import FormattedCurrency from '@s/FormattedCurrency';
import DetailsToggle from '@s/DetailsToggle';
import Translation from '@s/Translation';

class KioskPriceLevel extends PriceLevel {

    feeDisplay = () => {
        if (this.props.customFeeDisplay !== undefined) {
            return this.props.customFeeDisplay;
        }
        return (
            <Fragment>
                <div className="ticket__price"><FormattedCurrency>{ this.basePrice() }</FormattedCurrency>+ <FormattedCurrency>{ this.serviceFee() }</FormattedCurrency> Fee</div>
            </Fragment>
        )
    }

    render() {
        return(
            <div className="ticket-container">
                <div className="ticket">
                    <div className="ticket__information">
                        {this.props.level.wasHidden === true &&
                            <div className="ticket-availability"><Translation>{'checkout__access_code_applied'}</Translation></div>
                        }
                        <div className="ticket__name">{ this.props.level.name }</div>
                        <div className="ticket__pricing-group">
                            {this.feeDisplay()}
                        </div>
                        {!!this.props.level.description && <DetailsToggle onClick={this.toggleDescription}/>}
                    </div>

                    <div className="ticket__action">
                        <AddTickets
                            onIncrement={this.props.onIncrement}
                            onDecrement={this.props.onDecrement}
                            quantity={this.props.quantity}
                            quantities={this.props.quantities}
                            onQuantityInput={this.props.onQuantityInput}
                            initialQuantities={this.props.initialQuantities}
                            disabled={!!this.props.disabled}
                        />
                    </div>
                </div>
                {this.props.level.description && this.state.showDescription === true && (
                    <div className="ticket-description__more">
                        <div className="ticket__description">
                            <div className="ticket-description__content ticket-description__content-desc">
                                {this.props.level.description}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

KioskPriceLevel.propTypes = {
    level: PropTypes.object.isRequired,
    slug: PropTypes.string,
    quantity: PropTypes.number,
    quantities: PropTypes.arrayOf(PropTypes.number).isRequired,
    initialQuantities: PropTypes.arrayOf(PropTypes.number),
    onQuantityInput: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
}

export default KioskPriceLevel
