import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class InventoryConfigurationErrors extends Component {

    render = () => {
        let errorLines = [];
        const numTotalErroredItems = Object.keys(this.props.productsWithErrors).length + Object.keys(this.props.bundleProductsWithErrors).length;
        Object.values(this.props.productsWithErrors).map((product, i) => {
            if (product[0].optionName !== "") {
                errorLines.push('Please select your ' + product[0].optionName + ' for ' + product[0].productName);
            }
        });

        Object.values(this.props.bundleProductsWithErrors).map((bundleProduct, i) => {
            if (bundleProduct[0].optionName !== "") {
                errorLines.push('Please select your ' + bundleProduct[0].optionName + ' for ' + bundleProduct[0].productName);
            }
        });

        if(errorLines.length === 0 && numTotalErroredItems > 0) {
            errorLines.push('An error ocurred');
        }

        if (!errorLines.length) {
            return null;
        }

        if (this.props.isKioskMode) {
            return (
                <Fragment>
                    {errorLines.map((errorLine, key) => <div className="alert alert--error" key={key}>{errorLine}</div>)}
                </Fragment>
            );
        }

        return (
            <div className="message__container message__container_error error__content">
                {errorLines.map((errorLine, key) => <p key={key}>{errorLine}</p>)}
            </div>
        );
    };

}

InventoryConfigurationErrors.propTypes = {
    productsWithErrors: PropTypes.object,
    bundleProductsWithErrors: PropTypes.object,
    isKioskMode: PropTypes.bool,
}

InventoryConfigurationErrors.defaultProps = {
    productsWithErrors: {},
    bundleProductsWithErrors: {},
}

export default InventoryConfigurationErrors;
